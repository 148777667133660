import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Blog1 from "../assets/images/blog-img.png";
import CalendarIcon from "../assets/images/calendar-icon.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import { useTranslation } from "react-i18next";
import { GetRequest } from "../utils/apiCall";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { userData } from "../redux/favouriteDataSlice";
import Loader from "./Loader";

const Blogs = () => {
  const { t } = useTranslation();
  const userLang = useSelector(userData);
  let selectedLanguage = "de";
  selectedLanguage = userLang.lang === "en" ? "en" : "de";
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [allBlogs, setAllBlogs] = useState([]);

  const getDescription = () => {
    const dData = JSON.stringify(
      allBlogs[0]?.description_de ? allBlogs[0]?.description_de : 0
    );
    const descriptions = dData.split("<img");
    setDescription(descriptions[0]);
  };

  const getBlogsData = () => {
    setLoading(true);
    GetRequest("api/blogs").then((res) => {
      setLoading(false);
      setAllBlogs(res?.data?.data?.blogs.slice(0, 3));
    });
  };
  const handleBlogView = (blog) => {
    window.location.href = `/blog/${blog?.slug}`;
  };
  const navigateBLogs = () => {
    window.location.href = `/finanzrechner`;
  };
  useEffect(() => {
    getDescription();
    getBlogsData();
  }, []);

  return (
    <section className="blog-sec pt-4 pb-5">
      {loading && <Loader />}
      {!loading && (
        <div className="container">
          <span className="text-green text-md-center d-block fw-500">
            {t("Latest_News")}
          </span>
          <h3 className="fw-500 fs-32 text-md-center">
            {t("Check_back_here")}
          </h3>
          <div className="row pt-5">
            {allBlogs?.map((blog, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleBlogView(blog)}
                  className="col-12 col-md-6 col-lg-4 mb-3"
                >
                  <Card className="border-0 shadow-sm card-bg">
                    {
                      blog?.image && <Card.Img
                      variant="top"
                      src={blog.image ? blog.image : Blog1}
                    />
                    }
                    <img src="" alt="" />
                    <Card.Body className="px-4 py-4 ">
                      <Card.Title className="fs-4 fw-bold text-truncate text-truncate--2">
                        {selectedLanguage === "de" &&
                          (blog.name_de ? blog.name_de : "name here")}
                        {selectedLanguage === "en" &&
                          (blog.name_en ? blog.name_en : "name here")}
                      </Card.Title>
                      <div className="border-bottom pb-5 mb-5">
                        <div className="row justify-content-between py-4">
                          <div className="col-12 d-flex align-items-center">
                            <img src={CalendarIcon} alt="icon" />

                            <span className="ms-2">{blog?.created_date}</span>
                          </div>

                          <p>
                            {t("lastUpdate")} {blog?.updated_date}
                          </p>
                        </div>
                        {/* {t("card_Text")} */}
                        <Card.Text className="text-truncate text-truncate--2">
                          {selectedLanguage === "de" &&
                            (blog.summary_de ? blog.summary_de : "summary")}
                          {selectedLanguage === "en" &&
                            (blog.summary_en ? blog.summary_en : "summary")}
                          <div
                            dangerouslySetInnerHTML={{ __html: description }}
                          ></div>
                        </Card.Text>
                      </div>
                      <Button className="bg-green px-3 py-2 text-white fw-bold border-0">
                        {t("Read_more")}{" "}
                        <img className="ms-2" src={RightArrow} alt="icon" />
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <Button
          className="bg-green px-3 py-2 mt-5 text-white fw-bold border-0"
          onClick={() => navigateBLogs()}
        >
          {t("view_All")}
        </Button>
      </div>
    </section>
  );
};

export default Blogs;
