import React, { useEffect, useState } from "react";
import { Button, Form, NavLink } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { FiShare2 } from "react-icons/fi";

import cardImage1 from "../assets/images/saving-and-investment.svg";
import FinancialGoal from "../components/FinancialGoal";
import StayInformed from "../components/StayInformed";
import Cookies from "../components/Cookies";
import { useTranslation } from "react-i18next";
import { GetRequest } from "../utils/apiCall";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { userData } from "../redux/favouriteDataSlice";
import Loader from "../components/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../assets/images/banner-img.png";
import img2 from "../assets/images/blog-img.png";
import img3 from "../assets/images/blog-img2.png";
import img4 from "../assets/images/blog-view-img3.png";
import { Helmet } from "react-helmet";

const BlogDetails = (props) => {
  const { slug } = useParams();
  const images = [img1, img2, img3, img4];

  const userLang = useSelector(userData);
  let selectedLanguage = "de";
  selectedLanguage = userLang.lang === "en" ? "en" : "de";
  const { t } = useTranslation();
  const [blogView, setBlogView] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  // console.log("selected blog : ", selectedBlog);
  const [loading, setLoading] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getBlogsData = (slug) => {
    setLoading(true);

    const type = getQueryVariable("type");
    if (type === false) {
      GetRequest(`api/blog/${slug}`).then((res) => {
        setLoading(false);
        setSelectedBlog(res?.data?.data?.blog);
        // console.log("blog details: ", res?.data?.data?.blog);
      });
    } else {
      GetRequest(`api/blog/${slug}` + "?type=viewMode").then((res) => {
        setLoading(false);
        setSelectedBlog(res?.data?.data?.blog);
      });
    }
  };

  const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };
  useEffect(() => {
    getBlogsData(slug);
  }, []);

  const canonicalUrl = window.location.href;
  return (
    <>
    <Helmet>
    <link rel="canonical" href={canonicalUrl}/>
    <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
    </Helmet>
      <section className="banner-sec ">
        <div className="container">
          {!blogView && (
            <h1 className="text-white text-center">{t("counsellor")}</h1>
          )}
          {blogView && (
            <h1 className="text-white text-center banner-text-dot">
              {t("much_wealth")}
            </h1>
          )}

          <div className="d-flex align-items-center justify-content-center">
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("Home")}
            </NavLink>
            <IoIosArrowForward fill="#fff" className="mx-1 mt-1" />
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("counsellor")}
            </NavLink>
          </div>
          <p className="text-white text-center fw-500 p-heading">
            {t("wealth_you_have")}
          </p>
        </div>
      </section>
      <section className="counsellor-blog mb-5 pb-5">
        {loading && <Loader />}
        {!loading && (
          <div className="container ">
            <div className="position-relative  ">
              <div className="blog-detail pt-5">
                <div className="d-block d-md-flex justify-content-between align-items-center">
                  {selectedLanguage === "en" && (
                    <h2 className="fs-1 fw-600 text-black">
                      {selectedBlog?.name_en ? selectedBlog?.name_en : ""}
                    </h2>
                  )}
                  {selectedLanguage === "de" && (
                    <h2 className="fs-1 fw-600 text-black">
                      {selectedBlog?.name_de ? selectedBlog?.name_de : ""}
                    </h2>
                  )}

                  <div className="my-md-0 my-4">
                    <NavLink t0="#">
                      <FiShare2 className="share-icon" />
                      <span className="fw-500 text-black ms-2">
                        {t("share")}
                      </span>
                    </NavLink>
                  </div>
                </div>

                <div className="mr-5 pb-5 mt-5 blog-slider">
                  <Slider {...settings}>
                    {selectedBlog?.slider_images?.map((img, index) => {
                      return (
                        <div key={index}>
                          {loading && <Loader />}
                          {!loading && (
                            <div className="text-center d-flex justify-content-center rounded-0 p-0">
                              <img
                                src={img?.path ? img?.path : img}
                                alt="Image not found "
                                className="d-block"
                                style={{ height: "600px" }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="row justify-content-center">
                  {/* <img className="" src={selectedBlog?.image} alt="image not found" /> */}
                  {/* {selectedBlog?.slider_images.length !==0 && (
                    <img
                      src={selectedBlog?.slider_images[0].path}
                      alt="Image not found "
                      className="d-block"
                      style={{ height: "500px", marginBottom:"50px" }}
                    />
                  )} */}
                  <div className="col-md-9">
                    <div className="blog-user">
                      {selectedLanguage === "en" && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selectedBlog?.description_en
                              ? selectedBlog?.description_en
                              : null,
                          }}
                        ></div>
                      )}
                      {selectedLanguage === "de" && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: selectedBlog?.description_de
                              ? selectedBlog?.description_de
                              : null,
                          }}
                        ></div>
                      )}
                      <div className="contact-us-sec w-100">
                        <Form.Label className="fs-2 text-black fw-500 text-capitalize">
                          {t("comments")}
                        </Form.Label>
                        <div class="form-floating">
                          <textarea
                            rows={10}
                            class="form-control bg-gray radius-18"
                            placeholder={t("Write_Comments")}
                          ></textarea>
                        </div>

                        <div className="col-md-3 mb-5 mt-4">
                          <Button className="get-started-btn bg-green rounded-pill text-white py-2 px-md-5 border-0 py-md-3 fw-600 w-75 btn-100">
                            {t("Post")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {/* {blogView && <FinancialGoal />} */}
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default BlogDetails;
