import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import Header from "../components/Header";
import Home from "../pages/Home";
import { Route, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Counsellor from "../pages/Counsellor";
import HouseholdCalculator from "../pages/HouseholdCalculator";
import ConatctUs from "../pages/ContactUs";
import Formularcenter from "../pages/Formularcenter";
import InterestCalculator from "../pages/InterestCalculator";
import SavingCalculator from "../pages/SavingCalculator";
import FundCalculator from "../pages/FundCalculator";
import LoanCalculator from "../pages/LoanCalculator";
import WithDrawPlanCalculator from "../pages/WithDrawPlanCalculator";
import InflationCalculator from "../pages/InflationCalculator";
import Calculators from "../pages/Calculators";
import BlogDetails from "../pages/BlogDetails";
import FinancialFreedomCalculator from "../pages/FinancialFreedomCalculator";
import CompareEleAndGas from "../pages/CompareEleAndGas";
import ScrollToTop from "../components/ScrollToTop";
import { Helmet } from "react-helmet";
import InstagramPosts from "../pages/instaPosts";
import Unsubscribe from "../pages/Unsubscribe";

function RouterComponent() {
  return (
    <div className="App wrapper">
      <ScrollToTop />
      <Header />
      <Helmet>
        <title>Finanzberater | Finanzberatung | Finanzfaible </title>
        <meta
          name="description"
          content="Holen Sie sich kompetente Finanzberatung von Finanzfailble, Ihrem vertrauenswürdigen Finanzberater. Erreichen Sie Ihre finanziellen Ziele mit unserer Beratung."
        />
        <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
        <meta name="keywords" content="Finanzberater, Finanzberatung" />
      </Helmet>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/finanzrechner/:slug" element={<Counsellor />} />
        <Route path="/finanzrechner" element={<Counsellor />} />
        <Route path="/haushaltsrechner" element={<HouseholdCalculator />} />
        <Route path="/festgeldrechner" element={<InterestCalculator />} />
        <Route path="/sparrechner" element={<SavingCalculator />} />
        <Route path="/fondsrechner" element={<FundCalculator />} />
        <Route path="/lohnrechner" element={<LoanCalculator />} />
        <Route path="/inflationsrechner" element={<InflationCalculator />} />
        <Route
          path="/finanzfreiheitsrechner"
          element={<FinancialFreedomCalculator />}
        />
        <Route path="/vergleiche-ele-und-gas" element={<CompareEleAndGas />} />
        <Route path="/rechner" element={<Calculators />} />
        <Route
          path="/Auszahlungsplan-Rechner"
          element={<WithDrawPlanCalculator />}
        />
        <Route path="/kontaktiere-uns" element={<ConatctUs />} />
        <Route path="/formularcenter" element={<Formularcenter />} />
        <Route path="/instagramPosts" element={<InstagramPosts />} />
        <Route path="/blog/:slug" element={<BlogDetails />} />
        <Route path="unsubscribe" element={<Unsubscribe />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default RouterComponent;
