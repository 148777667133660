



// export const Base_url = ''
export const baseURL = "https://backend.finanzfaible.com";
export const apiEndpoints = {
    login: '/login',


    createPost : 'api/blog/create',
    unsubscribe: 'api/unsubscribe/newsletter'
}