import React from "react";
import ReactApexChart from "react-apexcharts";
// import Cross from "../assets/images/close-circle-icon.svg";
import { useTranslation } from "react-i18next";

const FinancialChart = (props) => {
  const { t } = useTranslation();
  // const { onClick, results } = props;

  const barSeries = {
    series: [
      {
        name: `${t("deposits")}`,
        color: "#4d6cdd",
        data: [44, 55, 61, 67, 72, 83, 93, 103, 115, 127],
      },
      {
        name: `${t("interestCharges")}`,
        color: "#ff9b41",
        data: [1, 8, 10, 12, 15, 17, 19, 20, 21, 22],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20px",
          borderRadius: 1,
          dataLabels: {
            total: {
              enabled: true,
             
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "number",
        categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      },
      yaxis: {
        opposite: true,
        labels: {
          formatter: function (value) {
            // Custom formatting logic for y-axis labels
            return value + " ";
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "left",
        customLegendItems: [`${t("deposits")}`, `${t("interestCharges")}`],
        offsetY: 0,
      },
      dataLabels: {
        enabled: false,
      },

      fill: {
        opacity: 1,
      },
    },
  };
  return (
    <div>
      <div>
        <ReactApexChart
          options={barSeries.options}
          series={barSeries.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default FinancialChart;
