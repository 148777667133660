import React from "react";
import ReactApexChart from "react-apexcharts";
import Cross from "../assets/images/close-circle-icon.svg";
import { useTranslation } from "react-i18next";

const SavingCalculatorChart = (props) => {
  const { t } = useTranslation();
  const { onClick, results } = props;
  if (results.balanceArray.length !== 0) {
    var finalValues = results.balanceArray.map((value,index)=> value + results.interestArray[index] + results.savingsArray[index])
  }else{
    var finalValues = []
  }

  const barSeries = {
    series: [
      {
        name: `${t("deposits")}`,
        data: results.savingsArray,
        color: "#f16880",
      },
      {
        name: `${t("taxAllowance")}`,
        data: results.taxAllowanceArray,
        color: "#03b2cb",
      },
      {
        name: `${t("taxAmount")}`,
        data: results.taxAmountArray,
        color: "#ff0041",
      },
      {
        name: `${t("finalCapital")}`,
        data: results.balanceArray,
        color: "#1a8754",
      },
      {
        name: `${t("interestCharges")}`,
        data: results.interestArray,
        color: "#2d3d30",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
            plotOptions: {
              bar: {
                columnWidth: "10px",
              },
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15px",
          borderRadius: 1,
          dataLabels: {
            total: {
              enabled: false,
              formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                if (seriesIndex === barSeries.series.length - 1) {
                  const seriesData = w.globals.series[seriesIndex];
                  const total = seriesData[dataPointIndex];
                  return total; // Format the total value as needed
                }
                return val; // Return the original value for other series data labels
              },
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "number",
        categories: (results?.savingsArray || []).map((item, index) => index + 1),
        scrollbar: {
          enabled: true,
        },
      },
      yaxis: {
        opposite: true,
        scrollbar: {
          enabled: true,
        },
        labels: {
          formatter: function (value) {
            // Custom formatting logic for y-axis labels
            return value.toFixed(3) + "";
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "left",
        markers: {
          fillColors: ["#f16880", "#03b2cb", "#198754", "#0dcbf0", "#2d3d30"],
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        offsetY: 0,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#f16880", "#03b2cb", "#198754", "#0dcbf0", "#2d3d30"],
    },
  };
  return (
    <div>
      <div id="chart" className="position-relative">
        <div className="close-icon-chart position-absolute">
          <img onClick={onClick} src={Cross} alt="icon" />
        </div>
        <ReactApexChart
          options={barSeries.options}
          series={barSeries.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default SavingCalculatorChart;
