import React, { useState } from "react";
import { Button, Card, Form, NavLink, Tab, Tabs } from "react-bootstrap";
import { IoIosArrowForward, IoMdCall } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import StayInformed from "../components/StayInformed";
import Cookies from "../components/Cookies";
import { PostRequest } from "../utils/apiCall";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

const ConatctUs = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showCookies, setShowCookies] = useState(true);

  const sendMessage = () => {
    const data = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    setLoading(true);

    name === ""
      ? toast.error("Please add name")
      : email === ""
      ? toast.error("Please add Email")
      : subject === ""
      ? toast.error("Please add subject")
      : message === ""
      ? toast.error("Please add message")
      : PostRequest(data, "api/contact-us").then((res) => {
          setLoading(false);
          toast.success(res.data.message);
          setEmail("");
          setMessage("");
          setName("");
          setSubject("");
        });
  };

  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://finanzfaible.com/kontaktiere-uns"/>
    <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
    </Helmet>
      <section className="banner-sec ">
        <div className="container">
          <h1 className="text-white text-center banner-text-dot">
            {t("contact.title")}
          </h1>
          <div className="d-flex align-items-center justify-content-center">
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("contact.home")}
            </NavLink>
            <IoIosArrowForward fill="#fff" className="mx-1 mt-1" />
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("contact.title")}
            </NavLink>
          </div>
        </div>
      </section>
      <section className="contact-us-sec py-4 py-md-5 mt-md-5 mb-md-3">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-5 col-lg-4">
              <h6 className="sub-heading fw-normal">{t("contact.info")}</h6>
              <ul class="list-unstyled ">
                <li className="text-white mb-3 d-flex">
                  <HiLocationMarker fill="#000" className="fs-4 me-2" />
                  <NavLink to="/">
                    <span className="text-black pe-md-5 d-block me-md-5 fs-14">
                      {t("contact.address")}
                    </span>
                  </NavLink>
                </li>
                {/* <li className="text-white mb-3 d-flex">
                  <IoMdCall fill="#000" className="fs-4 me-2" />
                  <NavLink to="/">
                    <span className="text-black text-decoration-underline fs-14">
                      +49 (0) 1575 2235967
                    </span>
                  </NavLink>
                </li>
                <li className="text-white mb-3  d-flex">
                  <MdEmail fill="#000" className="fs-4 me-2" />
                  <NavLink to="/">
                    <span className="text-black text-decoration-underline fs-14">
                      mo@finanzfaible.de
                    </span>
                  </NavLink>
                </li> */}
              </ul>
              {/* <h6 className="sub-heading fw-normal my-4">
                {t("contact.onMap")}
              </h6>
              <div className="map-box">
                <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      class="gmap_iframe"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src="https://maps.google.com/maps?hl=en&amp;q=Diefenbacher Str. 10, 75433 Maulbronn&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-md-7 col-lg-8 mt-md-0 mt-5">
              <h6 className="sub-heading fw-normal">{t("contact.title")}</h6>
              <Form>
                <div className="row ">
                  <div className="col-md-6 mb-3">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        {t("contact.name")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 mb-3">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        EMAIL
                      </Form.Label>
                      <Form.Control
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        {t("contact.subject")}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-4">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        {t("contact.message")}
                      </Form.Label>
                      <div class="form-floating">
                        <textarea
                          rows={10}
                          class="form-control"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </Form.Group>
                  </div>

                  <div className=" mb-5">
                    <Button
                      className="get-started-btn bg-green rounded-pill text-white px-5 border-0 py-2 fw-600"
                      onClick={() => sendMessage()}
                    >
                      {t("contact.send")}
                    </Button>
                  </div>
                </div>
              </Form>
              {loading && <Loader />}
            </div>
          </div>
        </div>
      </section>
      <StayInformed />
      <Toaster />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default ConatctUs;
