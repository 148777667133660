import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const DisableCalculatorPop = ({
  showModal,
  setShowModal,
  setShow,
  setType,
  houseTip
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setShowModal(false);
  };
  const handlePress = (type) => {
    setShowModal(false);
    setShow(true);
    setType(type);
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        className="login-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            <h2>{t("getAdd.title")}</h2>
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <>
            {houseTip?<h2>{t("houseHold.option3")} </h2>:
            <h2>{t("getAdd.title")} </h2>}
            <h2
              className="text-green fs-2 cursor-pointer"
              onClick={() => handlePress("mail")}
            >
              {t("getAdd.title1")}
            </h2>
            <h2>{t("getAdd.title2")}</h2>
            <h2
              onClick={() => handlePress("phone")}
              className="text-green fs-2 cursor-pointer"
            >
              {t("getAdd.title3")}
            </h2>
          </>
        </Modal.Body>
      </Modal>
      <Toaster />
    </>
  );
};

export default DisableCalculatorPop;
