import React, { useState } from "react";
import { Button, Form, NavLink } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import StayInformed from "../components/StayInformed";
import Cookies from "../components/Cookies";
import { PostRequest, PostRequestForms } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";

const CompareEleAndGas = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState({});
  const [showCookies, setShowCookies] = useState(true);

  const sendMessage = () => {
    let data = new FormData();

    data.append("phone_number", phoneNumber);
    data.append("email", email);
    data.append("type", type);
    data.append("attachment", fileName);

    setLoading(true);

    phoneNumber === ""
      ? toast.error("Please add Phone Number")
      : email === ""
      ? toast.error("Please add Email")
      : type === ""
      ? toast.error("Please add type")
      : PostRequestForms(data, "api/forms").then((res) => {
          if (res.status) {
            setLoading(false);
            toast.success(res?.data?.message);
            setEmail("");
            setFileName("");
            setPhoneNumber("");
            setType("");
          } else {
            setLoading(false);
            toast.error(res?.data?.message);
            setEmail("");
            setFileName("");
            setPhoneNumber("");
            setType("");
          }
        });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file);
  };
  const whatsAppClick = () => {
    const phoneNumber = "1234567890";
    const message = "Hello, how can I help you?";

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://finanzfaible.com/vergleiche-ele-und-gas"/>
    <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
    </Helmet>
      <section className="banner-sec ">
        <div className="container">
          <h1 className="text-white text-center banner-text-dot">
            {t("ele.title")}
          </h1>
          <div className="d-flex align-items-center justify-content-center">
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("ele.home")}
            </NavLink>
            <IoIosArrowForward fill="#fff" className="mx-1 mt-1" />
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("ele.title")}
            </NavLink>
          </div>
        </div>
      </section>
      <section className="contact-us-sec py-4 py-md-5 mt-md-5 mb-md-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 col-lg-10 mt-md-0 mt-5">
              <h6 className="sub-heading fw-normal">{t("ele.fewQuestion")}</h6>
              <Form>
                <div className="whatsAppBtn">
                  <Button
                    className=" bg-green rounded-pill text-white "
                    onClick={() => whatsAppClick()}
                  >
                    <img
                      src={require("../assets/images/whatsApp.png")}
                      alt="whatsApp"
                      className="whatsAppLogo"
                    />
                  </Button>
                </div>
                <div className="row ">
                  <div className="col-md-12 mb-3">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        {t("ele.email")}?
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        {t("ele.phone")}?
                      </Form.Label>
                      <Form.Control
                        required
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-3">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        {t("ele.likeContact")}?
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-12 mb-4">
                    <Form.Group md="4">
                      <Form.Label className="text-black fw-500 text-capitalize">
                        {t("ele.annualStatement")}?*
                      </Form.Label>
                      <div class="form-floating">
                        <input type="file" onChange={handleFileChange} />
                      </div>
                    </Form.Group>
                  </div>

                  <div className=" mb-5">
                    <Button
                      className="get-started-btn bg-green rounded-pill text-white px-5 border-0 py-2 fw-600"
                      onClick={() => sendMessage()}
                    >
                      {t("ele.submit")}
                    </Button>
                  </div>
                </div>
              </Form>
              {loading && <Loader />}
            </div>
          </div>
        </div>
      </section>
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}

      <Toaster />
    </>
  );
};

export default CompareEleAndGas;
