import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GetRequest, PostRequest } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateToken } from "../redux/tokenSlice";

const VerifyOtpModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { email } = props;
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const adminLogin = () => {
    dispatch(updateToken("token0001"));
    toast.success("Admin Login Successfully");
    // props.onHide()
    setLoading(false);
  };
  const signInHandler = () => {
    const data = {
      email: props?.email,
      otp_code: otp,
    };
    const url = "api/login";
    setLoading(true);

    otp === ""
      ? toast.error("Please add Otp")
      : PostRequest(data, "api/verify/otp").then((res) => {
        console.log("login data : ",res);
        if (res.status) {
          toast.success("your account is verified")
          props.onHide()

          setLoading(false);
        } else {
          toast.error("Otp error");
          // props.onHide()
          setLoading(false);
        }
          
        });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>Verify Otp</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{email}</p>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Otp code*</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="otp"
                  placeholder="*****"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <AiFillEyeInvisible className="eye-icon" />
              </div>
            </Form.Group>
          </Form>
          <Button className="button-login" onClick={() => signInHandler()}>
            Verify
          </Button>
        </Modal.Body>
        <Toaster />
      </Modal>
    </>
  );
};

export default VerifyOtpModal;
