import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AiFillEyeInvisible } from "react-icons/ai";
import { GetRequest, PostRequest } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const GetAdd = ({ show, setShow,type,cat }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const handleClose = () => setShow(false);
  const signInHandler = () => {
    const data = {
      email: email,
      phone: phone,
    };
    const url = "api/login";
    setLoading(true);
    if (type === "mail") {
      email === ""
      ? toast.error("Please add Email")
      : PostRequest(data, "api/login").then((res) => {
          toast.success("Login Successfully");
          setEmail("")
          // props.onHide()
          setLoading(false);
        });
    } else {
      phone === ""
      ? toast.error("Please add Phone Number")
      : PostRequest(data, "api/login").then((res) => {
          toast.success("Login Successfully");
          setPhone("")
          // props.onHide()
          setLoading(false);
        });
    }
  //  email === ""
  //     ? toast.error("Please add Email")
  //     : phone === ""
  //     ? toast.error("Please add Phone Number")
  //     : name === ""
  //     ? toast.error("Please add Name")
  //     : PostRequest(data, "api/login").then((res) => {F
  //         toast.success("Login Successfully");
  //         // props.onHide()
  //         setLoading(false);
  //       });
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className="login-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           {cat ? <h2>{t("servicesPopup.title")}</h2>:
            <h2>{t("getAdd.title")}</h2>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>Secure Login: Access Your Account Safely and Seamlessly.</p> */}
          <Form>
            {type === "mail" &&<Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("Email_ddress")}*</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>}
            {type === "phone" &&<Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("phoneNumber")}*</Form.Label>
              <Form.Control
                type="phone"
                placeholder={t("phoneNumber")}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>}
          </Form>
          <Button className="button-login" onClick={() => signInHandler()}>
          {t("getAdd.signUp")}
          </Button>
        </Modal.Body>
      </Modal>
      <Toaster />
    </>
    // <>
    //   <Modal centered show={show} onHide={handleClose}>
    //     <Modal.Header className="border-0" closeButton></Modal.Header>
    //     <Modal.Body>
    //       <div>
    //         <h3>Subscribe to get informed</h3>
    //       <Form.Control
    //               className="mt-3"
    //               type="text"
    //               placeholder={t("getReminder.placeholder")}
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //             />
    //             <Form.Control
    //               className="mt-3"
    //               type="text"
    //               placeholder="WhatsApp-Number"
    //               value={phone}
    //               onChange={(e) => setPhone(e.target.value)}
    //             />
    //       </div>
    //       <Button
    //         onClick={() => setShow(true)}
    //         className="w-100 bg-black text-white rounded-3 border-0 py-3 mt-4"
    //       >
    //         Subscribe
    //       </Button>
    //     </Modal.Body>
    //   </Modal>
    // </>
  );
};

export default GetAdd;
