import React, { useEffect, useState } from "react";
import BannerImage from "../assets/images/interest-calculator-banner.svg";
import Household from "../assets/images/household-img.svg";
import Calendar from "../assets/images/calendar-img.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";

import { Button, Dropdown, Form } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";
import BudgetReminder from "../components/BudgetReminder";
import Chart from "../components/Chart";
import { useTranslation } from "react-i18next";
import FixDepositChart from "../components/FixChart";
import FixDepositChart2 from "../components/FixCart2";
import ReactGA from 'react-ga';

import FixedCalculator from "../assets/images/fixed-calculator.svg";
import { Helmet } from "react-helmet";
import CalculationsPopup from "../components/CalculationsPop";

const InterestCalculator = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [durationType, setDurationType] = useState(`${t("interest.monthly")}`);
  const [toggle, setToggle] = useState(false);
  const [count, setCount] = useState(0);
  const [chartShow, setChartShow] = useState(false);
  const [chartShow2, setChartShow2] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [toFind, setToFind] = useState({
    findInitialCapital: false,
    findDuration: false,
    findInterestRate: false,
    findFinalCapital: true,
  });
  const [calculations, setCalculations] = useState({
    initialCapital: "",
    interestRate: "",
    duration: "",
    finalCapital: "",
    compoundInterest: true,
    interestCalculationDYL: true,
    texPercent: "26.375",
    annualTex: "1000",
  });
  const [results, setResults] = useState({
    finalCapital: 0,
    totalDeposit: 0,
    interestReceived: 0,
    taxRate: "",
    duration: "",
    yearlyResults: [],
  });
  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "iC") {
      setCalculations({
        ...calculations,
        initialCapital: value,
      });
    }
    if (name === "iR") {
      setCalculations({
        ...calculations,
        interestRate: value,
      });
    }
    if (name === "dur") {
      setCalculations({
        ...calculations,
        duration: value,
      });
    }
    if (name === "fC") {
      setResults({
        ...results,
        finalCapital: value,
      });
    }
    if (name === "cI") {
      value === "true"
        ? setCalculations({
            ...calculations,
            compoundInterest: true,
          })
        : setCalculations({
            ...calculations,
            compoundInterest: false,
          });
    }
    if (name === "iCDY") {
      setCalculations({
        ...calculations,
        interestCalculationDYL: value,
      });
    }
    if (name === "tP") {
      setCalculations({
        ...calculations,
        texPercent: value,
      });
    }
    if (name === "aTA") {
      setCalculations({
        ...calculations,
        annualTex: value,
      });
    }
  };

  const calculateInitialCapital = () => {
    const interestRate = Number(calculations.interestRate) / 100;

    const durationInYears =
      durationType === `${t("interest.yearly")}`
        ? Number(calculations.duration)
        : Number(calculations.duration) / 12;

    const totalAmount = Number(results.finalCapital);

    const taxAbleAmount =
      calculations.annualTex === ""
        ? totalAmount
        : totalAmount - Number(calculations.annualTex);

    const taxAmount =
      taxAbleAmount *
      (calculations.texPercent === "" ? 1 : calculations.texPercent / 100);
    const net = totalAmount + taxAmount;
    const netTotal = toggle ? net : totalAmount;
    let initialAmount = calculations.compoundInterest
      ? netTotal / Math.pow(1 + interestRate, durationInYears)
      : netTotal / (1 + interestRate * durationInYears);
    const interestAmount = totalAmount - initialAmount;
    let yearlyResults = [];
    if (durationInYears <= 1) {
      setChartShow2(true);
    } else {
      setChartShow2(false);
    }
    var startingAmount = initialAmount;
    for (let i = 1; i <= Math.ceil(durationInYears); i++) {
      const newDuration =
        durationType === `${t("interest.yearly")}`
          ? 1
          : durationInYears < 1
          ? durationInYears
          : i === 1
          ? 1
          : durationInYears % 1 === 0
          ? 1
          : durationInYears - (i - 1) < 1
          ? durationInYears % 1
          : !Number.isInteger(durationInYears - (i - 1))
          ? 1
          : 0;

      const totalAmount = calculations.compoundInterest
        ? startingAmount * Math.pow(1 + interestRate, newDuration)
        : initialAmount + initialAmount * interestRate * newDuration;

      const interestAmount = calculations.compoundInterest
        ? totalAmount - startingAmount
        : totalAmount - initialAmount;

      const taxAbleAmount =
        calculations.annualTex === ""
          ? totalAmount
          : totalAmount - Number(calculations.annualTex);

      const taxAmount =
        taxAbleAmount *
        (calculations.texPercent === "" ? 1 : calculations.texPercent / 100);

      const net = totalAmount.toFixed(3) - taxAmount.toFixed(3);
      const netTotal = toggle ? net.toFixed(3) : totalAmount.toFixed(3);

      startingAmount = parseFloat(totalAmount).toFixed(3);
      const yr = {
        netTotal: netTotal,
        interestAmount: interestAmount.toFixed(3),
        taxAmount: taxAmount.toFixed(3),
        taxAllowance: calculations.annualTex,
        initialCapital: initialAmount,
      };

      yearlyResults.push(yr); // Save the result
    }

    // setCapital(netTotal.toFixed(3));
    // setIPaymentReceived(interestAmount.toFixed(3));
    const allResults = {
      finalCapital: results.finalCapital,
      totalDeposit: initialAmount.toFixed(3),
      interestReceived: interestAmount.toFixed(3),
      taxAmount: toggle ? taxAmount.toFixed(3) : 0,
      taxAllowance: toggle ? calculations.annualTex : 0,
      yearlyResults: yearlyResults,
    };
    setResults(allResults);
  };

  const calculateDuration = () => {
    const r =
      calculations.interestRate === ""
        ? 1
        : Number(calculations.interestRate) / 100;

    const totalAmount = Number(results.finalCapital);

    const taxAbleAmount =
      calculations.annualTex === ""
        ? totalAmount
        : totalAmount - Number(calculations.annualTex);
    const taxAmount =
      taxAbleAmount *
      (calculations.texPercent === "" ? 1 : calculations.texPercent / 100);
    const net = totalAmount + taxAmount;
    const netTotal = toggle ? net : totalAmount;

    const n =
      durationType === `${t("interest.yearly")}`
        ? Math.log(netTotal / Number(calculations.initialCapital)) /
          Math.log(1 + r)
        : Math.log(netTotal / Number(calculations.initialCapital)) /
          Math.log(1 + r) /
          12;

    const interestAmount = totalAmount - Number(calculations.initialCapital);

    let yearlyResults = [];
    if (n <= 1) {
      setChartShow2(true);
    } else {
      setChartShow2(false);
    }
    var startingAmount = Number(calculations.initialCapital);
    for (let i = 1; i <= Math.ceil(n); i++) {
      const newDuration =
        durationType === `${t("interest.yearly")}`
          ? 1
          : n < 1
          ? n
          : i === 1
          ? 1
          : n % 1 === 0
          ? 1
          : n - (i - 1) < 1
          ? n % 1
          : !Number.isInteger(n - (i - 1))
          ? 1
          : 0;

      const totalAmount = calculations.compoundInterest
        ? startingAmount * Math.pow(1 + r, newDuration)
        : Number(calculations.initialCapital) +
          calculations.initialCapital * r * newDuration;

      const interestAmount = calculations.compoundInterest
        ? totalAmount - startingAmount
        : totalAmount - calculations.initialCapital;

      const taxAbleAmount =
        calculations.annualTex === ""
          ? totalAmount
          : totalAmount - Number(calculations.annualTex);
      const taxAmount =
        taxAbleAmount *
        (calculations.texPercent === "" ? 1 : calculations.texPercent / 100);
      const net = totalAmount.toFixed(3) - taxAmount.toFixed(3);
      const netTotal = toggle ? net.toFixed(3) : totalAmount.toFixed(3);
      startingAmount = parseFloat(totalAmount).toFixed(3);
      const yr = {
        netTotal: netTotal,
        interestAmount: interestAmount.toFixed(3),
        taxAmount: taxAmount.toFixed(3),
        taxAllowance: calculations.annualTex,
        initialCapital: calculations.initialCapital,
      };

      yearlyResults.push(yr); // Save the result
    }

    const allResults = {
      finalCapital: results.finalCapital,
      duration: n,
      totalDeposit: Number(calculations.initialCapital),
      interestReceived: interestAmount.toFixed(3),
      taxAmount: toggle ? taxAmount.toFixed(3) : 0,
      taxAllowance: toggle ? calculations.annualTex : 0,
      yearlyResults: yearlyResults,
    };
    setResults(allResults);
  };
  const calculateInterestRate = () => {
    setChartShow(true);
    const TR = toggle ? Number(calculations.texPercent) / 100 : 0;
    const totalAmount = results.finalCapital;
    const A =
      (totalAmount - Number(calculations.initialCapital) * TR) / (1 - TR);

    const interestAmount = totalAmount - Number(calculations.initialCapital);

    const taxAbleAmount =
      calculations.annualTex === ""
        ? interestAmount
        : interestAmount > Number(calculations.annualTex)
        ? totalAmount - Number(calculations.annualTex)
        : 0;

    const durationInYears =
      durationType === `${t("interest.yearly")}`
        ? Number(calculations.duration)
        : Number(calculations.duration) / 12;
    // const taxAmount =((Number(calculations.texPercent)/100)*n*Number(calculations.initialCapital))- (Number(calculations.annualTex)*n)
    const taxAmount =
      interestAmount > Number(calculations.annualTex) ? A - totalAmount : 0;

    const rate = calculations.compoundInterest
      ? Math.pow(
          totalAmount / Number(calculations.initialCapital),
          1 / durationInYears
        ) - 1
      : (totalAmount - Number(calculations.initialCapital)) /
        (Number(calculations.initialCapital) * durationInYears);

    let yearlyResults = [];
    if (durationInYears <= 1) {
      setChartShow2(true);
    } else {
      setChartShow2(false);
    }
    var startingAmount = Number(calculations.initialCapital);
    var taxDeducted = 0;
    var finalInterestAmount = 0;

    for (let i = 1; i <= Math.ceil(durationInYears); i++) {
      const newDuration =
        durationType === `${t("interest.yearly")}`
          ? 1
          : durationInYears < 1
          ? durationInYears
          : i === 1
          ? 1
          : durationInYears % 1 === 0
          ? 1
          : durationInYears - (i - 1) < 1
          ? durationInYears % 1
          : !Number.isInteger(durationInYears - (i - 1))
          ? 1
          : 0;

      const totalAmount = calculations.compoundInterest
        ? startingAmount * Math.pow(1 + rate, newDuration)
        : Number(calculations.initialCapital) +
          calculations.initialCapital * rate * newDuration;

      const interestAmount = calculations.compoundInterest
        ? totalAmount - startingAmount
        : totalAmount - calculations.initialCapital;

      finalInterestAmount = finalInterestAmount + Number(interestAmount);

      const taxAbleAmount =
        calculations.annualTex === ""
          ? totalAmount
          : interestAmount > Number(calculations.annualTex)
          ? interestAmount - Number(calculations.annualTex)
          : 0;

      const taxAmount =
        taxAbleAmount *
        (calculations.texPercent === "" ? 1 : calculations.texPercent / 100);
      const net = totalAmount.toFixed(3) - taxAmount.toFixed(3);
      const netTotal = toggle ? net.toFixed(3) : totalAmount.toFixed(3);
      startingAmount = parseFloat(netTotal).toFixed(3);
      taxDeducted = taxDeducted + Number(taxAmount);

      const yr = {
        netTotal: netTotal,
        interestAmount: interestAmount.toFixed(3),
        taxAmount: taxAmount.toFixed(3),
        taxAllowance: calculations.annualTex,
        isTaxIncluded: toggle,
        initialCapital: calculations.initialCapital,
      };

      yearlyResults.push(yr); // Save the result
    }
    const allResults = {
      finalCapital: results.finalCapital,
      taxRate: (rate * 100).toFixed(3),
      totalDeposit: calculations.initialCapital,
      interestReceived: interestAmount.toFixed(3),
      isTaxIncluded: toggle,
      taxAmount: toggle ? taxDeducted.toFixed(3) : 0,
      taxAllowance: toggle ? calculations.annualTex : 0,
      yearlyResults: yearlyResults,
    };
    setResults(allResults);
  };
  const calculateMaturityAmount = () => {
    if (
      calculations.duration === "" ||
      calculations.interestRate === "" ||
      calculations.initialCapital === ""
    ) {
      return;
    }
    setChartShow(true);

    let yearlyResults = [];
    const r = Number(calculations.interestRate) / 100;
    const durationInYears =
      durationType === `${t("interest.yearly")}`
        ? Number(calculations.duration)
        : Number(calculations.duration) / 12;
    if (durationInYears <= 1) {
      setChartShow2(true);
    } else {
      setChartShow2(false);
    }
    var startingAmount = Number(calculations.initialCapital);
    var taxDeducted = 0;
    var finalInterestAmount = 0;

    for (let i = 1; i <= Math.ceil(durationInYears); i++) {
      const newDuration =
        durationType === `${t("interest.yearly")}`
          ? 1
          : durationInYears < 1
          ? durationInYears
          : i === 1
          ? 1
          : durationInYears % 1 === 0
          ? 1
          : durationInYears - (i - 1) < 1
          ? durationInYears % 1
          : !Number.isInteger(durationInYears - (i - 1))
          ? 1
          : 0;

      const totalAmount = calculations.compoundInterest
        ? startingAmount * Math.pow(1 + r, newDuration)
        : Number(calculations.initialCapital) +
          calculations.initialCapital * r * newDuration;

      const interestAmount = calculations.compoundInterest
        ? totalAmount - startingAmount
        : totalAmount - calculations.initialCapital;

      finalInterestAmount = finalInterestAmount + Number(interestAmount);

      const taxAbleAmount =
        calculations.annualTex === ""
          ? totalAmount
          : interestAmount > Number(calculations.annualTex)
          ? interestAmount - Number(calculations.annualTex)
          : 0;

      const taxAmount =
        taxAbleAmount *
        (calculations.texPercent === "" ? 1 : calculations.texPercent / 100);
      const net = totalAmount.toFixed(3) - taxAmount.toFixed(3);
      const netTotal = toggle ? net.toFixed(3) : totalAmount.toFixed(3);
      startingAmount = parseFloat(netTotal).toFixed(3);
      taxDeducted = taxDeducted + Number(taxAmount);

      const yr = {
        netTotal: netTotal,
        interestAmount: interestAmount.toFixed(3),
        taxAmount: taxAmount.toFixed(3),
        taxAllowance: calculations.annualTex,
        isTaxIncluded: toggle,
        initialCapital: calculations.initialCapital,
      };

      yearlyResults.push(yr); // Save the result
    }
    const totalAmount = calculations.compoundInterest
      ? Number(calculations.initialCapital) * Math.pow(1 + r, durationInYears)
      : Number(calculations.initialCapital) +
        Number(calculations.initialCapital) * r * durationInYears;
    const interestAmount = totalAmount - Number(calculations.initialCapital);

    const taxAbleAmount =
      calculations.annualTex === ""
        ? interestAmount
        : interestAmount > calculations.annualTex
        ? interestAmount - Number(calculations.annualTex)
        : 0;
    const taxAmount =
      taxAbleAmount *
      (calculations.texPercent === "" ? 1 : calculations.texPercent / 100);
    const net = finalInterestAmount - taxDeducted;
    // const netTotal = toggle ? net : totalAmount;
    const netTotal = toggle
      ? Number(calculations.initialCapital) + net
      : totalAmount;
    const allResults = {
      finalCapital: netTotal.toFixed(3),
      totalDeposit: calculations.initialCapital,
      interestReceived: finalInterestAmount.toFixed(3),
      isTaxIncluded: toggle,
      taxAmount: toggle ? taxDeducted.toFixed(3) : 0,
      taxAllowance: toggle ? Number(calculations.annualTex) : 0,
      yearlyResults: yearlyResults,
    };
    setResults(allResults);
  };

  const onPressCalculate = () => {
    setCount(count+1)
    // setChartShow(true);
    toFind.findFinalCapital
      ? calculateMaturityAmount()
      : toFind.findInitialCapital
      ? calculateInitialCapital()
      : toFind.findInterestRate
      ? calculateInterestRate()
      : toFind.findDuration
      ? calculateDuration()
      : (() => {})();
  };

  const selectToFind = (type) => {
    if (type === "initialCapital") {
      setToFind({
        findInitialCapital: !toFind.findInitialCapital,
        findDuration: false,
        findInterestRate: false,
        findFinalCapital: false,
      });
      setCalculations({
        ...calculations,
        initialCapital: "",
      });
    }
    if (type === "interestRate") {
      setToFind({
        findInitialCapital: false,
        findDuration: false,
        findInterestRate: !toFind.findInterestRate,
        findFinalCapital: false,
      });
      setCalculations({
        ...calculations,
        interestRate: "",
      });
    }
    if (type === "duration") {
      setToFind({
        findInitialCapital: false,
        findDuration: !toFind.findDuration,
        findInterestRate: false,
        findFinalCapital: false,
      });
      setCalculations({
        ...calculations,
        duration: "",
      });
    }
    if (type === "finalCapital") {
      setToFind({
        findInitialCapital: false,
        findDuration: false,
        findInterestRate: false,
        findFinalCapital: !toFind.findFinalCapital,
      });
      setResults({
        ...results,
        finalCapital: "",
      });
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
     <Helmet>
        <title>Finanzberater & Finanzberatung: Finanzfaible </title>
        <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
        <meta
          name="description"
          content="Berechnen Sie mühelos Renditen mit unseren Festgeldrechnern und Finanzrechnern. Planen Sie Ihren
          Präzise Investitionen für eine sichere finanzielle Zukunft."
        />
        <meta name="keywords" content="Festgeldrechner," />
        
        <link rel="canonical" href="https://finanzfaible.com/festgeldrechner"/>
      </Helmet>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500">
                {t("interest.title")}
              </h1>
              <p className="text-white mt-3">{t("interest.subTitle")}</p>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={Household}
                alt="img not found"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <h2 className="fs-32 font-weight-bold text-green">
                {t("interest.howToUseHH")}
              </h2>
              <ul className="list-style mt-3">
                <li className="p-heading">{t("interest.howTo1")}</li>
                <li className="p-heading">{t("interest.howTo2")}</li>
                <li className="p-heading">{t("interest.howTo3")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <BudgetReminder />
      <section className="monthly-calculation pt-5 pb-2">
        <div className="container">
          <span className="text-green text-start text-md-center d-block fw-500">
            {t("calculator")}
          </span>
          <h3 className="body-heading text-start text-md-center">
            {t("fixed_deposit_calculator")}
          </h3>

          <div className="row bg-green py-4 px-2 radius-24 mx-lg-2 mt-4">
            <div className="col-md-6  mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 h-100 px-4 py-4 ">
                <h5 className="text-green text-left fw-bold text-capitalize mb-3">
                  {t("summary")}
                </h5>
                <Form>
                  <Form.Group className="mb-3">
                    <div className="col-md-12 d-flex justify-content-between">
                      <Form.Label>{t("initial_capital")}:</Form.Label>
                      <Form.Check
                        onClick={() => selectToFind("initialCapital")}
                        className="switch-toggle ms-3"
                        type="switch"
                        checked={toFind.findInitialCapital}
                        id="custom-switch"
                      />
                    </div>
                    {toFind.findInitialCapital ? (
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="€5,000.00"
                        name="iC"
                        value={
                          toFind.findInitialCapital
                            ? results.totalDeposit
                            : calculations.initialCapital
                        }
                        contentEditable={
                          toFind.findInitialCapital ? false : true
                        }
                        onChange={onChangeHandler}
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="€5,000.00"
                        name="iC"
                        value={calculations.initialCapital}
                        contentEditable={
                          toFind.findInitialCapital ? false : true
                        }
                        onChange={onChangeHandler}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <div className="col-md-12 d-flex justify-content-between">
                      <Form.Label>{t("interest_rate")}:</Form.Label>
                      <Form.Check
                        onClick={() => selectToFind("interestRate")}
                        className="switch-toggle ms-3"
                        type="switch"
                        checked={toFind.findInterestRate}
                        id="custom-switch"
                      />
                    </div>
                    <div className="position-relative">
                      {toFind.findInterestRate ? (
                        <Form.Control
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                          type="number"
                          placeholder="1 %"
                          name="iR"
                          value={
                            toFind.findInterestRate
                              ? results.taxRate
                              : calculations.interestRate
                          }
                          onChange={onChangeHandler}
                          readOnly
                        />
                      ) : (
                        <Form.Control
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                          type="number"
                          placeholder="10"
                          name="iR"
                          value={calculations.interestRate}
                          onChange={onChangeHandler}
                        />
                      )}
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-between">
                      <Form.Label>{t("Duration")}:</Form.Label>
                      <Form.Check
                        onClick={() => selectToFind("duration")}
                        className="switch-toggle ms-3"
                        type="switch"
                        checked={toFind.findDuration}
                        id="custom-switch"
                      />
                    </div>
                    <div className="col-md-4">
                      <Dropdown className="paymentType fixDepositDrop text-start mb-3 mb-md-0">
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="text-start"
                        >
                          <div className="interestPayment">{durationType}</div>
                          <div className="d-lg-none d-block ">
                            {/* {"Select Language"} */}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#"
                            onClick={() =>
                              setDurationType(`${t("interest.monthly")}`)
                            }
                          >
                            {t("interest.monthly")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() =>
                              setDurationType(`${t("interest.yearly")}`)
                            }
                          >
                            {t("interest.yearly")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-6">
                      <Form.Group className="mb-3">
                        <div className="position-relative">
                          {toFind.findDuration ? (
                            <Form.Control
                              className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                              type="number"
                              name="dur"
                              placeholder="7"
                              value={
                                toFind.findDuration
                                  ? results.duration
                                  : calculations.duration
                              }
                              onChange={onChangeHandler}
                              readOnly
                            />
                          ) : (
                            <Form.Control
                              className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                              type="number"
                              name="dur"
                              placeholder="7"
                              value={calculations.duration}
                              contentEditable={
                                toFind.findDuration ? false : true
                              }
                              onChange={onChangeHandler}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group className="mb-3">
                    <div className="col-md-12 d-flex justify-content-between">
                      <Form.Label>{t("finalCapital")}:</Form.Label>
                      <Form.Check
                        onClick={() => selectToFind("finalCapital")}
                        className="switch-toggle ms-3"
                        type="switch"
                        checked={toFind.findFinalCapital}
                        id="custom-switch"
                      />
                    </div>
                    {toFind.findFinalCapital ? (
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="€5,000.00"
                        onChange={onChangeHandler}
                        name="fC"
                        value={results.finalCapital}
                        readOnly
                      />
                    ) : (
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="€5,000.00"
                        onChange={onChangeHandler}
                        name="fC"
                        value={results.finalCapital}
                      />
                    )}
                  </Form.Group>
                  <Form.Label>{t("compoundInterest")}:</Form.Label>
                  <div className="d-flex calculation-radio-btn mb-3">
                    <Form.Check
                      inline
                      label={t("yesInterestAccumulation")}
                      name="cI"
                      type="radio"
                      id="1"
                      value={true}
                      onClick={onChangeHandler}
                    />
                    <Form.Check
                      inline
                      label={t("noInterestAccumulation")}
                      name="cI"
                      type="radio"
                      id="2"
                      value={false}
                      onClick={onChangeHandler}
                    />
                  </div>

                  {/* <Form.Label>{t("duringTheYear")}:</Form.Label>
                  <div className="d-flex calculation-radio-btn mb-3">
                    <Form.Check
                      inline
                      label={t("linear")}
                      name="iCDY"
                      type="radio"
                      id="1"
                      value={true}
                      onClick={onChangeHandler}
                    />
                    <Form.Check
                      inline
                      label={t("exponentially")}
                      name="iCDY"
                      type="radio"
                      id="2"
                      value={false}
                      onClick={onChangeHandler}
                    />
                  </div> */}

                  <div className="d-flex items-center mb-3">
                    <Form.Label>{t("interest.taxRate")}:</Form.Label>

                    <Form.Check
                      onClick={() => setToggle(!toggle)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {toggle && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Control
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                          type="number"
                          placeholder="26,373%"
                          name="tP"
                          value={calculations.texPercent}
                          onChange={onChangeHandler}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {" "}
                          {t("interest.annualAllowance")}:
                        </Form.Label>

                        <Form.Control
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                          type="number"
                          placeholder="€1,000.00"
                          name="aTA"
                          value={calculations.annualTex}
                          onChange={onChangeHandler}
                        />
                      </Form.Group>
                    </>
                  )}
                  <Button
                    className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                    onClick={() => onPressCalculate()}
                  >
                    {t("calculate")}
                  </Button>
                </Form>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 py-3 position-relative">
                {/* <div className="position-absolute end-0 top-0 me-2 mt-2 ">
                  <img className="cursor-pointer" src={PdfIcon} alt="icon" />
                </div> */}
                <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                  {t("summary")}
                </h5>
                {!chartShow && (
                  <img
                    className="d-block mx-auto w-100 mx-md"
                    src={FixedCalculator}
                    alt="img"
                  />
                )}
                {chartShow &&
                  (chartShow2 ? (
                    <FixDepositChart2
                      onClick={() => setChartShow(false)}
                      results={results}
                    />
                  ) : (
                    <FixDepositChart
                      onClick={() => setChartShow(false)}
                      results={results}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <BudgetReminder budgetType="fixed" />
      <Blogs />
      {/* <FinancialGoal /> */}
      <CalculationsPopup count={count} setCount={setCount} />
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default InterestCalculator;
