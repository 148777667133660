



export const roundToTwo = (num) => {
    return +(Math.round(num + "e+3") + "e-3");
}
export const getCurrentMonthSaving = (savingRate, month, savingInterval, dynamics) => {
    if (month === 0) {
        return savingRate;
    }
    const intervals = {
        'monthly': 1,
        'quarterly': 3,
        'half-yearly': 6,
        'yearly': 12,
    };
    const interval = intervals[savingInterval];
    if (interval && month % interval === 0) {
        if (month % interval == 0 && dynamics > 0 && month > 11) {
            let counter = parseInt(month / 12)
            let sum = savingRate
            for (let i = 0; i < counter; i++) {
                const dynamicIncrease = sum * (parseFloat(dynamics) / 100)
                sum = sum + dynamicIncrease
            }
            return sum
        }
        return savingRate;
    }
    return 0;
};

export const getAccSumByYear = (list) => {
    const batchSize = 12
    const finalList = [];
    let i, j;
    for (i = 0, j = list.length; i < j; i += batchSize) {
        const subList = list.slice(i, i + batchSize);
        const subListSum = subList.reduce((accumulator, currentValue) => (accumulator) + (currentValue), 0)
        finalList.push(roundToTwo(subListSum));
    }
    return finalList
}
export const getValuesByYear = (list) => {
    const finalList = [];
    for (let i = 1; i <= list.length; i++) {
        if (i % 12 === 0) {
            finalList.push(list[i - 1]);
        }
    }
    return finalList
}