import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Blog1 from "../assets/images/blog-img.png";
import CalendarIcon from "../assets/images/calendar-icon.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { userData } from "../redux/favouriteDataSlice";
import axios from "axios";
import Loader from "../components/Loader";
import Slider from "react-slick";
import { PostRequestForms, PostRequestInsta } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { apiEndpoints } from "../utils/appUris";

const InstagramPosts = () => {
  const { t } = useTranslation();
  const userLang = useSelector(userData);
  let selectedLanguage = "de";
  selectedLanguage = userLang.lang === "en" ? "en" : "de";
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);


  const fetchImagesForPost = async (blog) => {
    const accessToken =
      "IGQWRQNHdnd05QTGNCQkd0djVwZAmp1VVhMbkl1MkpkdFRZAQXpRRzd2RmxjOFhFZAF9ZAU2d3N3kxVHpqbVhhNE9XczVrOEhwNlNqZAXM2MklLVlpMbW1OcTAwZAVNuWFVxSUlZAeVlpRjA2N21tTGtrbHRiMHJjcElmWncZD";
    const getImageUrl = async (mediaId) => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/v12.0/${mediaId.id}?fields=id,media_url&access_token=${accessToken}`
        );
        const mediaData = response.data;
        const imageUrl = mediaData.media_url;
        return imageUrl;
      } catch (error) {
        console.error(
          `Error fetching image for media ID ${mediaId.id}: ${error}`
        );
        return null;
      }
    };

    // Loop through the media IDs and retrieve image URLs
    const imageUrls = await Promise.all(
      blog?.child?.map(async (mediaId) => {
        return await getImageUrl(mediaId);
      })
    );
    return imageUrls;
  };
  const syncInstagramPost = async (blog) => {
    console.log("blogs:",blog);
    setLoadingSync(true);
    setSelectedCardIndex(blog.id)
    const images = await fetchImagesForPost(blog);
    const instaImages = JSON.stringify(images);
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('en-US', options);
    const parts = blog?.caption.split('| ');
    const textAfterPipe = parts[1];
  
    // Split the text after the "| " into words
    const words = textAfterPipe.split(' ');
  
    // Extract the first 5 words
    const first5Words = words.slice(0, 5).join(' ');
    console.log("formated date :", first5Words);

    let data = new FormData();
    data.append("name", first5Words);
    data.append("author_name", "Admin");
    data.append(
      "summary",
      "Wir vergleichen uns sehr regelmäßig – und mit den unterschiedlichsten Dingen. Wenn es um die Finanzen geht, vergleichen wir im Folgenden auch. In einer schönen statistischen Erhebung des IW Köln wurden verschiedene Altersgruppen untersucht und ihr Nettofinanzvermögen verglichen."
    );
    data.append("description", blog.caption);
    data.append("images", instaImages);
    data.append("created_date", formattedDate);

    PostRequestInsta(data, apiEndpoints.createPost)
      .then((res) => {

        if (res.status) {
          toast.success(res.data.message);
        }
        setLoadingSync(false);
      })
      .catch((error) => {
        console.error("Error fetching Instagram posts:", error);
        toast.error("Error fetching Instagram posts");
        setLoadingSync(false);
      });
  };

  const onPresNext = () => {
    if (!nextPage) {
      toast.error("please fetch post from instagram first");
    } else {
      try {
        axios
          .get(nextPage)
          .then((response) => {
            console.log("response from next :", response);
            if (!response?.error) {
              if (response?.data?.paging?.next) {
                setNextPage(response?.data?.paging?.next);
                const nextLink = response?.data?.paging?.next
                localStorage.setItem("nextLink", nextLink);
              } else {
                toast.success("All posts fetched");
              }

              //to formate the posts with images fetched from the above function
              const allPosts = response?.data?.data;
              const formattedPosts = async () => {
                const postsWithImages = await Promise.all(
                  allPosts?.map(async (post) => {

                    // Convert the timestamp to a Date object
                    const dateObject = new Date(post.timestamp);

                    // Get the month name
                    const monthName = dateObject.toLocaleString("en-US", {
                      month: "long",
                    });

                    // Format the date
                    const formattedDate = `${monthName} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
                    return {
                      id: post.id,
                      caption: post.caption,
                      media_type: post.media_type,
                      images: [post.media_url],
                      permalink: post.permalink,
                      thumbnail_url: post.thumbnail_url,
                      timestamp: post.timestamp,
                      child: post.children.data,
                      created_date: formattedDate,
                    };
                  })
                );
                return postsWithImages;
              };

              formattedPosts().then((post) => {
                console.log("all post at last call :", post);
                const fetchedPosts = post || []
                const totalPosts = [...posts, ...fetchedPosts];
                console.log("total posts : ", totalPosts);
                setPosts(totalPosts);
                const jsonData = JSON.stringify(totalPosts);
                localStorage.setItem("instagramPosts", jsonData);
                setLoading(false);
              });
            } else {
              toast.error(response?.error?.message);
            }
          })
          .catch((error) => {
            console.error("Error fetching Instagram posts:", error);
            setLoading(false);
          });
      } catch (error) {
        console.log("error :", error);
      }
    }
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getPostsLocal = () => {
    const jsonData = localStorage.getItem("instagramPosts");
    const nextLink = localStorage.getItem("nextLink");
    console.log("next link from local :", nextLink);

    // Parse the JSON data back into a JavaScript object or array
    const data = JSON.parse(jsonData);

    if (data) {
      // Now 'data' contains the original array or object you saved
      setPosts(data);
      setNextPage(nextLink)
      console.log(data.length);
    } else {
      // Handle the case where the data is not available in local storage
      console.log("Data not found in local storage.");
    }
  };
  const getInstagramPosts = () => {
    setLoading(true);
    const limit = 12;
    const accessToken =
      "IGQWRQNHdnd05QTGNCQkd0djVwZAmp1VVhMbkl1MkpkdFRZAQXpRRzd2RmxjOFhFZAF9ZAU2d3N3kxVHpqbVhhNE9XczVrOEhwNlNqZAXM2MklLVlpMbW1OcTAwZAVNuWFVxSUlZAeVlpRjA2N21tTGtrbHRiMHJjcElmWncZD";
    const apiUrl = `https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,children&access_token=${accessToken}&limit=${limit}`;

    try {
      axios
        .get(apiUrl)
        .then((response) => {
          const allPosts = response.data.data;
          setNextPage(response?.data?.paging?.next);

          const nextLink = response?.data?.paging?.next
          localStorage.setItem("nextLink", nextLink);
          const formattedPosts = async () => {
            const postsWithImages = await Promise.all(
              allPosts.map(async (post) => {
                console.log("posts : ", post);
                // const images = await fetchImagesForPost(post);

                // Convert the timestamp to a Date object
                const dateObject = new Date(post.timestamp);

                // Get the month name
                const monthName = dateObject.toLocaleString("en-US", {
                  month: "long",
                });

                // Format the date
                const formattedDate = `${monthName} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
                return {
                  id: post.id,
                  caption: post.caption,
                  media_type: post.media_type,
                  images: [post.media_url],
                  permalink: post.permalink,
                  thumbnail_url: post.thumbnail_url,
                  timestamp: post.timestamp,
                  child: post.children.data,
                  created_date: formattedDate,
                };
              })
            );
            return postsWithImages;
          };

          formattedPosts().then((posts) => {
            setPosts(posts);
            const jsonData = JSON.stringify(posts);
            localStorage.setItem("instagramPosts", jsonData);
            setLoading(false);
          });
        })
        .catch((error) => {
          console.error("Error fetching Instagram posts:", error);
          setLoading(false);
        });
    } catch (error) {
      console.log("Error :", error);
    }
  };

  useEffect(() => {
    getPostsLocal();
  }, []);

  return (
    <section className="blog-sec pt-4 pb-5">
      {loading && <Loader />}
      {!loading && (
        <div className="container py-5">
          <div className="d-flex w-100 justify-content-end align-items-center">
            <Row className="mt-5 w-50">
              <Col>
                <Button
                  variant="primary"
                  className="w-75  bg-green "
                  onClick={() => getInstagramPosts()}
                >
                  Fetch Posts from Instagram
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  className="w-75  bg-green"
                  onClick={() => getPostsLocal()}
                >
                  Fetch posts from Local
                </Button>
              </Col>
            </Row>
          </div>

          <span className="text-green text-md-center d-block fw-500 mt-5">
            instagram Post
          </span>
          <h3 className="fw-500 fs-32 text-md-center">
            Check your instagram Post here
          </h3>
          <div className="row pt-5">
            {posts?.map((blog, index) => {
              return (
                <div
                  key={index}
                  className="col-12 col-md-6 col-lg-4 mb-3"
                >
                  <Card className="border-0 shadow-sm card-bg">
                   
                    {blog?.images.length > 1 ? (
                      <div className=" blog-slider">
                        <Slider {...settings}>
                          {blog?.images?.map((img, index) => {
                            return (
                              <div key={index}>
                                {loading && <Loader />}
                                {!loading && (
                                  <div className="rounded-1 p-0 m-0">
                                    <img
                                      src={img?.path ? img?.path : img}
                                      alt="Image not found "
                                      className="d-block w-100"
                                      style={{ height: "400px" }}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    ) : (
                      <Card.Img
                        variant="top"
                        src={blog.images ? blog.images[0] : Blog1}
                      />
                    )}
                    <Card.Body className="px-4 py-4 ">
                      <Card.Text className="border-bottom pb-5 mb-5">
                        <div className="row justify-content-between py-4">
                          <div className="col-6 d-flex align-items-center">
                            <img src={CalendarIcon} alt="icon" />

                            <span className="ms-2">{blog?.created_date}</span>
                          </div>
                          <div className="col-6 d-flex align-items-center"></div>
                        </div>
                        {selectedLanguage === "de" &&
                          (
                            <p  className="text-truncate text-truncate--10">{blog.caption ? blog.caption : "caption"}</p>
                          )}
                        {selectedLanguage === "en" &&
                          ( <p  className="text-truncate text-truncate--10">{blog.caption ? blog.caption : "caption"}</p>)}
                      </Card.Text>
                      {blog?.id === selectedCardIndex && loadingSync && <Loader />}
                      { !loadingSync && (
                        <Button
                          className="bg-green px-3 py-2 text-white fw-bold border-0"
                          onClick={() => syncInstagramPost(blog)}
                        >
                          sync data
                          <img className="ms-2" src={RightArrow} alt="icon" />
                        </Button>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-end">
            <Button
              className="bg-green px-3 py-2 text-white fw-bold border-0 "
              onClick={() => onPresNext(nextPage)}
            >
              Next
              <img className="ms-2" src={RightArrow} alt="icon" />
            </Button>
          </div>
        </div>
      )}

      <Toaster />
    </section>
  );
};

export default InstagramPosts;
