import React, { useEffect, useState } from "react";
import { Button, Card, Form, NavLink, Tab, Tabs } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import Blog1 from "../assets/images/blog-img.png";
import CalendarIcon from "../assets/images/calendar-icon.svg";
import RightArrow from "../assets/images/right-arrow.svg";
import StayInformed from "../components/StayInformed";
import Cookies from "../components/Cookies";
import { useTranslation } from "react-i18next";
import { GetRequest } from "../utils/apiCall";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import { userData } from "../redux/favouriteDataSlice";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Counsellor = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const userLang = useSelector(userData);
  let selectedLanguage = userLang.lang === "en" ? "en" : "de";
  const [isActive, setIsActive] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const [tabBlogs, setTabBlogs] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [activeTab, setActiveTab] = useState("blogs");

  const getBlogsData = (url) => {
    setLoading(true);
    GetRequest(
      !url ? "api/blogs" :url === "blogs" ? "api/blogs" : `api/blogs-by-category/${url}`
    ).then((res) => {
      setLoading(false);
      setAllBlogs(res?.data?.data?.blogs || []);
    });
  };
  const getAllCategories = (url) => {
    setLoading(true);
    GetRequest("api/categories").then((res) => {
      setLoading(false);
      setAllCategories(res?.data?.data?.categories);
      getBlogsData(!url?"blogs":url);
    });
  };

  const handleBlogView = (blog) => {
    window.location.href = `/blog/${blog?.slug}`;
  };
  const handleBlogViewHide = (tab) => {
    console.log("selected tab :", tab);
  };

  const tabPressHandler = (slug) => {
    let blogData = allBlogs?.categories?.filter((item) => item.slug === slug);
    !slug? getBlogsData("blogs"):slug === "blogs" ? getBlogsData(slug)  : setTabBlogs(blogData);
    getBlogsData(slug);
    setActiveTab(slug);
  };

  useEffect(() => {
    // getBlogsData("blogs");
    getAllCategories();
    const storedData = localStorage.getItem("myData");
    if (slug !== "") {
      const data = JSON.parse(storedData);
      setActiveTab(slug);
      // localStorage.removeItem("myData"); // Clean up the stored data
    }
  }, []);
  const canonicalUrl = window.location.href;

  return (
    <>
    <Helmet>
      <title>Finanzberater & Finanzberatung: Finanzfaible </title>
      <meta
          name="description"
          content="Berechnen Sie mühelos Renditen mit unseren finanzberater und finanzberatung. Planen Sie Ihren Präzise Investitionen für eine sichere finanzielle Zukunft."
        />
      <link rel="canonical" href={canonicalUrl}/>
      <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
    </Helmet>
      <section className="banner-sec ">
        <div className="container">
          <h1 className="text-white text-center">{t("counsellor")}</h1>

          <div className="d-flex align-items-center justify-content-center">
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("Home")}
            </NavLink>
            <IoIosArrowForward fill="#fff" className="mx-1 mt-1" />
            <NavLink className="fs-5 text-white fw-600" to="#">
              {t("counsellor")}
            </NavLink>
          </div>
        </div>
      </section>
      <section className="counsellor-blog mb-5 pb-5">
        <div className="container ">
          <div className="position-relative  ">
            <Tabs
              activeKey={activeTab}
              onClick={handleBlogViewHide}
              onSelect={(key) => tabPressHandler(key)}
              defaultActiveKey="blogs"
              id="uncontrolled-tab-example"
              className="mb-3 blogTabs"
            >
              <Tab eventKey="blogs" title={selectedLanguage === "en"?"All Posts":"Alle Beiträge"} className="border-0">
                <div className="row pt-5">
                  {allBlogs?.map((blog) => {
                    return (
                      <div
                        onClick={() => handleBlogView(blog)}
                        className="col-12 col-md-6 col-lg-4 mb-3"
                      >
                        <Card className="border-0 shadow-sm card-bg">
                          <Card.Img
                            variant="top"
                            src={blog.image ? blog.image : ""}
                          />
                          <Card.Body className="px-4 py-4 ">
                            <Card.Title className="fs-4 fw-bold blog-heading text-truncate text-truncate--2">
                              {selectedLanguage === "de" &&
                                (blog.name_de ? blog.name_de : "name ")}
                              {selectedLanguage === "en" &&
                                (blog.name_en ? blog.name_en : "name ")}
                            </Card.Title>
                            <Card.Text className="border-bottom pb-5 mb-5">
                              <div className="row justify-content-between py-4">
                                <div className="col-12 d-flex align-items-center">
                                  <img src={CalendarIcon} alt="icon" />
                                  <span className="ms-2">
                                    {blog?.created_date}
                                  </span>
                                  
                                </div>
                              <p>{t("lastUpdate")}  {blog?.updated_date}</p>
                                {/* <div className="col-6 d-flex align-items-center"></div> */}
                              </div>
                              {selectedLanguage === "de" && (
                                <p className="text-truncate text-truncate--2">
                                  {blog.summary_de
                                    ? blog.summary_de
                                    : "summary"}
                                </p>
                              )}
                              {selectedLanguage === "en" && (
                                <p className="text-truncate text-truncate--2">
                                  {blog.summary_en
                                    ? blog.summary_en
                                    : "summary"}
                                </p>
                              )}
                            </Card.Text>
                            <Button className="bg-green px-3 py-2 text-white fw-bold border-0">
                              {t("Read_more")}{" "}
                              <img
                                className="ms-2"
                                src={RightArrow}
                                alt="icon"
                              />
                            </Button>
                          </Card.Body>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              </Tab>
              {allCategories?.map((cat) => (
                <Tab
                  eventKey={cat.slug}
                  title={selectedLanguage === "en"? cat.name_en:cat.name_de}
                  className="border-0"
                  onClick={() => tabPressHandler(cat.slug)}
                >
                  <div className="row pt-5">
                    {allBlogs.map((blog, index) => {
                      return (
                        <div
                          onClick={() => handleBlogView(blog)}
                          className="col-12 col-md-6 col-lg-4 mb-3"
                          key={index}
                        >
                          <Card className="border-0 shadow-sm card-bg">
                           {blog?.image &&  <Card.Img
                              variant="top"
                              src={blog.image ? blog.image : ""}
                            />}
                            <Card.Body className="px-4 py-4 ">
                              <Card.Title className="fs-4 fw-bold">
                                {/* {t("cardTitle")} */}
                                {selectedLanguage === "de" &&
                                (blog.name_de ? blog.name_de : "name ")}
                              {selectedLanguage === "en" &&
                                (blog.name_en ? blog.name_en : "name ")}
                              </Card.Title>
                              <Card.Text className="border-bottom pb-5 mb-5">
                                <div className="row justify-content-between py-4">
                                  <div className="col-12 d-flex align-items-center">
                                    <img src={CalendarIcon} alt="icon" />
                                    <span className="ms-2">
                                      {blog?.created_date}
                                    </span>
                                  </div>
                                  {/* <div className="col-6 d-flex align-items-center"></div> */}
                                </div>
                                {selectedLanguage === "de" && (
                                <p className="text-truncate text-truncate--2">
                                  {blog.summary_de
                                    ? blog.summary_de
                                    : "summary"}
                                </p>
                              )}
                              {selectedLanguage === "en" && (
                                <p className="text-truncate text-truncate--2">
                                  {blog.summary_en
                                    ? blog.summary_en
                                    : "summary"}
                                </p>
                              )}
                              </Card.Text>
                              <Button className="bg-green px-3 py-2 text-white fw-bold border-0">
                                {t("Read_more")}{" "}
                                <img
                                  className="ms-2"
                                  src={RightArrow}
                                  alt="icon"
                                />
                              </Button>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })}
                  </div>
                </Tab>
              ))}
            </Tabs>
            {loading && <Loader />}
            <div className="search-box position-absolute top-0 end-0 ">
              <form className="d-flex">
                <div className="position-relative">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={t("search")}
                    aria-label="Search"
                  />
                  <div className=" search-icon position-absolute">
                    <CiSearch />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="money-saving bg-light-green my-5 py-5 w-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-6">
              <span className="text-green text-start  text-md-center d-block fw-500">
                {t("savings")}
              </span>
              <h3 className="body-heading text-start text-md-center">
                {t("houseHold.savingTipHeading")}
              </h3>
              <ul className="list-style mt-4">
                <li className="p-heading">{t("houseHold.tip1")}</li>
                <li className="p-heading">{t("houseHold.tip2")}</li>
                <li className="p-heading">{t("houseHold.tip1")}</li>
                <li className="p-heading">{t("houseHold.tip1")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default Counsellor;
