import React from "react";
import ReactApexChart from "react-apexcharts";
import Cross from "../assets/images/close-circle-icon.svg";
import { useTranslation } from "react-i18next";

const FundChart = (props) => {
  const { t } = useTranslation();
  const { onClick, results } = props;
  // console.log("results : ", results);
  let totalCharts = (results.totalDepositArray || []).map((item, index) => (index + 1));
  const barSeries = {
    series: [
      {
        name: `${t("fundCalculator.totalDeposits")}`,
        data: results.totalDepositArray,
        color: "#f16880",
      },

      {
        name: `${t("fundCalculator.finalValue")}`,
        data: results.finalValueArray,
        color: "#198754",
      },
      {
        name: `${t("fundCalculator.profit")}`,
        data: results.profitArray,
        color: "#2d3d30",
      },
      {
        name: `${t("fundCalculator.totalIncrease")}`,
        data: results.increaseValueArray,
        color: "#63b9a1",
      },
      {
        name: `${t("fundCalculator.issueFee")}`,
        data: results.totalFeeArray,
        color: "#0dcbf0",
      }, 
      // {
      //   name: `${t("fundCalculator.administrationFee")}`,
      //   data: results.adminFeeArray,
      //   color: "#06a4c3",
      // },
      {
        name: `${t("fundCalculator.totalTaxes")}`,
        data: results.taxArray,
        color: "#FF0000",
      },
      {
        name: `${t("fundCalculator.totalIncome")}`,
        data: results.incomeArray,
        color: "#163547",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
            plotOptions: {
              bar: {
                columnWidth: "10px",
              },
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15px",
          borderRadius: 1,
          dataLabels: {
            total: {
              enabled: false,
              formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                if (seriesIndex === barSeries.series.length - 1) {
                  const seriesData = w.globals.series[seriesIndex];
                  const total = seriesData[dataPointIndex];
                  return total.toFixed(3); // Format the total value as needed
                }
                return val; // Return the original value for other series data labels
              },
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "number",
        categories: totalCharts,
        scrollbar: {
          enabled: true,
        },
      },
      yaxis: {
        opposite: true,
        labels: {
          formatter: function (value) {
            // Custom formatting logic for y-axis labels
            return (value.toFixed(3) + "");
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "left",
        markers: {
          fillColors: ["#f16880", "#198754", "#2d3d30", "#63b9a1", "#0dcbf0", "#FF0000"],
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        offsetY: 0,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#f16880", "#198754", "#2d3d30", "#63b9a1", "#0dcbf0", "#FF0000"],
    },
  };
  return (
    <div>
      <div id="chart" className="position-relative">
        <div className="close-icon-chart position-absolute">
          <img onClick={onClick} src={Cross} alt="icon" />
        </div>
        <ReactApexChart
          options={barSeries.options}
          series={barSeries.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default FundChart;
