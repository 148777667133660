import React from "react";
import Cross from "../assets/images/close-circle-icon.svg";
import { Button } from "react-bootstrap";

function MiniJobs({ miniIncome, calculations, setCalculations }) {
  const onChangeHandler = (e) => {
    const value = e.target.value;
    setCalculations({
      ...calculations,
      miniJobIncome: value,
    });
  };
  return (
    <div>
      <h4 className="d-flex py-1 bg-white d-block income-box fw-bold">
        <Button
          className=" d-flex justify-content-center bg-green text-white border-sm  border-0"
          onClick={() => miniIncome("minus")}
        >
          <h3>-</h3>
        </Button>
        <input
          name="otherSourceIncome"
            value={calculations.miniJobIncome}
          style={{ border: "none" }}
            onChange={onChangeHandler}
          className="text-center w-100"
          placeholder="0"
        />
        <Button
          className="d-flex justify-content-center bg-green text-white border-sm fw-bold border-0 "
          onClick={() => miniIncome("plus")}
        >
          <h3>+</h3>
        </Button>
      </h4>
    </div>
  );
}

export default MiniJobs;
