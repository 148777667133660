import React, { useEffect, useState } from "react";
import BannerImage from "../assets/images/inflation-banner.svg";
import Household from "../assets/images/household-img.svg";
import Calendar from "../assets/images/calendar-img.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";
import { Button, Form } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";

const InflationCalculator = () => {
  const [isActive, setIsActive] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [calculations, setCalculations] = useState({
    originalAmount: "",
    inflationRate: "",
    period: "",
  });
  const [results, setResults] = useState({
    futurePrice: "",
    futurePP: "",
    absolutePrinceIncrease: "",
    relativePrice: "",
    priceIncreasePA: "",
    absoluteLoss: "",
    relativeLoss: "",
    lossOFPurchasingPower: "",
  });

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "originalAmount") {
      setCalculations({
        ...calculations,
        originalAmount: value,
      });
    }
    if (name === "inflationRate") {
      setCalculations({
        ...calculations,
        inflationRate: value,
      });
    }
    if (name === "period") {
      setCalculations({
        ...calculations,
        period: value,
      });
    }
  };

  const resultCalculator = () => {
    const r = calculations.inflationRate / 100;
    const totalFutureAmount =
      calculations.originalAmount * Math.pow(1 + r, calculations.period);

    const interestAmount = totalFutureAmount - calculations.originalAmount;
    const totalFuturePower =
      calculations.originalAmount / Math.pow(1 + r, calculations.period);

    const absoluteIncrease = totalFutureAmount - calculations.originalAmount;
    const relativePrice =
      (absoluteIncrease / calculations.originalAmount) * 100;
    const newPrice =
      calculations.originalAmount + calculations.originalAmount * r;
    const relativePA =
      ((newPrice - calculations.originalAmount) / calculations.originalAmount) *
      100;
    const absoluteLoss = calculations.originalAmount - absoluteIncrease;
    const relativeLossPower =
      (absoluteLoss / calculations.originalAmount) * 100;
    const lossPa =
      ((totalFutureAmount - calculations.originalAmount) /
        calculations.originalAmount) *
      100;
    setResults({
      futurePrice: totalFutureAmount.toFixed(3),
      futurePP: totalFuturePower.toFixed(3),
      absolutePrinceIncrease: absoluteIncrease.toFixed(3),
      relativePrice: relativePrice.toFixed(3),
      priceIncreasePA: relativePA.toFixed(3),
      absoluteLoss: -absoluteLoss.toFixed(3),
      relativeLoss: -relativeLossPower.toFixed(3),
      lossOFPurchasingPower: -lossPa.toFixed(3),
    });
  };
  const handleClick = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    resultCalculator();
  }, []);

  return (
    <>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500 text-capitalize">
                inflation calculator
              </h1>
              <p className="text-white mt-3">
                What is the value of your money in a few years? The inflation
                calculator helps to calculate price increases, currency
                depreciation and loss of purchasing power.
              </p>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={Household}
                alt="img not found"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <h2 className="heading-main text-green">
                How to use our Interest calculator
              </h2>
              <ul className="list-style mt-3">
                <li className="p-heading">
                  Enter the approximate amount in all fields that apply to your
                  household
                </li>
                <li className="p-heading">
                  Try rounding up to the nearest dollar amount, even though this
                  calculator accepts decimals (using either , or .)
                </li>
                <li className="p-heading">
                  You do not need to fill out all fields
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="budget-reminder bg-light-green py-4">
        <div className="container">
          <div className="row align-items-center justify-content-center py-5">
            <div className="col-12 col-lg-10">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-6 col-lg-5">
                  <h2 className="sub-heading text-green">
                    <span className="text-dark-green d-block">Get monthly</span>
                    budget reminder
                  </h2>

                  <Form.Control
                    className="mt-3"
                    type="text"
                    placeholder="email address/phone number"
                  />
                  <Button className="mt-4 bg-green px-3 py-2 text-white rounded-pill border-0 fw-bold">
                    Get Reminder
                  </Button>
                </div>
                <div className="col-md-6 col-lg-6 mt-md-0 mt-5">
                  <img
                    className="d-block mx-auto w-100 mx-md"
                    src={Calendar}
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="monthly-calculation pt-5 pb-2">
        <div className="container">
          <span className="text-green text-start text-md-center d-block fw-500">
            Calculator
          </span>
          <h3 className="body-heading text-start text-md-center">
            Interest calculator for one-time investments
          </h3>

          <div className="row bg-green py-4 px-2 radius-24 mx-lg-5 mt-4">
            <div className="col-md-6  mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 h-100 px-4 py-4">
                <h5 className="text-green text-left fw-bold text-capitalize mb-3">
                  Summary
                </h5>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Original amount:</Form.Label>
                    <Form.Control
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                      name="originalAmount"
                      type="number"
                      placeholder="€0"
                      value={calculations.originalAmount}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>

                  <div className="d-block calculation-radio-btn mb-3">
                    <Form.Label>Calculation basis:</Form.Label>

                    <Form.Check
                      label="assumed inflation rate"
                      name="group1"
                      type="radio"
                      id="1"
                    />
                    <Form.Check
                      label="historical inflation rate (Germany, base year 2010)"
                      name="group1"
                      type="radio"
                      id="2"
                    />
                    <Form.Check
                      label="historical inflation rate (Germany, base year 2015)"
                      name="group1"
                      type="radio"
                      id="3"
                    />
                  </div>

                  {/* <Form.Group className="mb-3">
                    <Form.Label>capital withdrawal:</Form.Label>
                    <div className="position-relative mb-2">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                    </div>
                  </Form.Group> */}

                  <Form.Group className="mb-3">
                    <Form.Label>Inflation rate:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        name="inflationRate"
                        type="number"
                        placeholder="€0"
                        value={calculations.inflationRate}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Period:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        name="period"
                        type="number"
                        placeholder="€0"
                        value={calculations.period}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Future price:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                        value={results.futurePrice}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Future purchasing power:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                        value={results.futurePP}
                      />
                    </div>
                  </Form.Group>

                  <Button
                    className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                    onClick={() => resultCalculator()}
                  >
                    Calculate
                  </Button>
                </Form>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 py-3 position-relative">
                <div className="position-absolute end-0 top-0 me-2 mt-2 ">
                  <img className="cursor-pointer" src={PdfIcon} alt="icon" />
                </div>
                <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                  Summary
                </h5>
                <div className="row justify-content-between align-items-center pt-3 pb-4">
                  <ResultCard
                    title="The future price is :"
                    result={results.futurePrice}
                  />
                  <ResultCard
                    title="The future purchasing power is :"
                    result={results.futurePP}
                  />
                  <ResultCard
                    title="Absolute price increase :"
                    result={results.absolutePrinceIncrease}
                  />
                  <ResultCard
                    title="Relative price increase :"
                    result={results.relativePrice}
                  />
                  <ResultCard
                    title="Price increase  relative pa :"
                    result={results.priceIncreasePA}
                  />
                  <ResultCard
                    title="Absolute loss of purchasing power :"
                    result={results.absoluteLoss}
                  />
                  <ResultCard
                    title="Relative loss of purchasing power :"
                    result={results.relativeLoss}
                  />
                  <ResultCard
                    title="Loss of purchasing power relative pa  :"
                    result={results.lossOFPurchasingPower}
                  />
                </div>

                <div className="calculation-detail">
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    This is the mobile view of the calculator. The default view
                    (desktop view) may also contain a history table. This is
                    also included in the PDF document. A permanent link created
                    in mobile view can also be opened on a PC in standard view
                    and vice versa.
                  </p>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    The interest calculator uses the input data to determine
                    either the required initial capital, the interest rate, the
                    term or the resulting final capital including interest.
                    Based on the output table, the interest calculator also
                    shows the course of the credit development and gives the
                    capital invested at the beginning of the year, the interest
                    credited at the end of the year, the previous sum of the
                    interest credited and the total capital at the end of the
                    respective year. The interest calculator allows you to
                    calculate with or without compound interest.
                  </p>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    If you calculate without compound interest, the capital
                    invested corresponds to the initial capital each year, since
                    the interest is not reinvested. If compound interest is
                    taken into account, on the other hand, the interest credited
                    is added to the invested capital every year and from then on
                    also bears interest. In the case of maturities that are not
                    a whole number of years, mixed interest is calculated if
                    linear interest is calculated over the course of the year.
                    This means that compound interest is initially used for the
                    whole year, but the last fraction of the year is compounded
                    with simple interest, as is normal bank practice for savings
                    deposits. In addition, a tax rate with an annual tax
                    exemption can be specified in the interest calculator to
                    take the new withholding tax into account.
                  </p>
                  <ul>
                    <li className="text-green fs-6 fw-medium">
                      quick start Guide
                    </li>
                    <li className="text-green fs-6 fw-medium">
                      quick start Guide
                    </li>
                    <li className="text-green fs-6 fw-medium">
                      {" "}
                      quick start Guide
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="money-saving bg-light-green my-5 py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-6">
              <span className="text-green text-start  text-md-center d-block fw-500">
                Saving
              </span>
              <h3 className="body-heading text-start text-md-center">
                Simple Ways to Save Money
              </h3>
              <ul className="list-style mt-4">
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue,
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar .
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue, eu mollis sapien condimentum sit
                  amet.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Blogs />
      {/* <FinancialGoal /> */}
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default InflationCalculator;

const ResultCard = ({ title, result }) => {
  return (
    <div className="col-11 col-md-9 rounded-pills w-75 w-md-100 d-flex justify-content-center bg-white radius-24 mx-auto pt-3 pb-2 mb-3">
      <div className="d-flex align-items-center justify-content-between  w-100 ">
        <h6 className="text-green  text-capitalize fw-normal mb-0 mr-2">
          {title}
        </h6>
        <h2 className=" text-black fs-6 text-center  d-flex align-items-center mt-2 ">
          {result} €
        </h2>
      </div>
    </div>
  );
};
