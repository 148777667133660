import React, { useEffect, useState } from "react";
import BannerImage from "../assets/images/withdraw-plan-banner.svg";
import Household from "../assets/images/household-img.svg";
import Calendar from "../assets/images/calendar-img.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";

import { Button, Form } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";
import Chart from "../components/Chart";
import { useTranslation } from "react-i18next";

const WithDrawPlanCalculator = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [beginningMonth, setBeginningMonth] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [chartShow, setChartShow] = useState(false);
  const [calculations, setCalculations] = useState({
    capitalAssets:"",
    withdrawal:"",
    withdrawalInterval:"",
    dynamics:"",
    interestRate:"",
    interestPeriod:"",
    waitingPeriod:"",
    pensionPeriod:"",
    remainingCapital:"",
    taxRate:"",
    taxAllowance:"",   
  });
  const [results, setResults] = useState({
    regularWithdrawal: 0,
    totalWithdrawal: 0,
    totalInterest: 0,
    totalTaxes:0,
  });

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setCalculations({
      ...calculations,
      [name]: value,
    });
  };

  const calculator = () => {
  
  
  };
  const onPressCalculate = () => {
    setChartShow(true);
  };
  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500">
                {t("withDrawal.title1")}
              </h1>
              <p className="text-white mt-3">{t("withDrawal.des")}</p>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={Household}
                alt="img not found"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <h2 className="fs-32  text-green">
                {t("withDrawal.howToUse")}
              </h2>
              <ul className="list-style mt-3">
                <li className="p-heading">{t("withDrawal.li1")}</li>
                <li className="p-heading">{t("withDrawal.li2")}</li>
                <li className="p-heading">{t("withDrawal.li3")}</li>
                <li className="p-heading">{t("withDrawal.li4")}</li>
                <li className="p-heading">{t("withDrawal.li5")}</li>
                <li className="p-heading">{t("withDrawal.li6")}</li>
                <li className="p-heading">{t("withDrawal.li7")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="budget-reminder bg-light-green py-4">
        <div className="container">
          <div className="row align-items-center justify-content-center py-5">
            <div className="col-12 col-lg-10">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-6 col-lg-5">
                  <h2 className="sub-heading text-green">
                    <span className="text-dark-green d-block">
                      {t("withDrawal.title2")}
                    </span>
                    {t("withDrawal.title3")}
                  </h2>

                  <Form.Control
                    className="mt-3"
                    type="text"
                    placeholder="email address/phone number"
                  />
                  <Button className="mt-4 bg-green px-3 py-2 text-white rounded-pill border-0 fw-bold">
                    {t("withDrawal.button")}
                  </Button>
                </div>
                <div className="col-md-6 col-lg-6 mt-md-0 mt-5">
                  <img
                    className="d-block mx-auto w-100 mx-md"
                    src={Calendar}
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="monthly-calculation pt-5 pb-2">
        <div className="container">
          <span className="text-green text-start text-md-center d-block fw-500">
            {t("calculator")}
          </span>
          <h3 className="body-heading text-start text-md-center">
            {t("withDrawal.title")}
          </h3>

          <div className="row bg-green py-4 px-2 radius-24 mx-lg-5 mt-4">
            <div className="col-md-6  mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 h-100 px-4 py-4">
                <h5 className="text-green text-left fw-bold text-capitalize mb-3">
                  {t("Summary")}
                </h5>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("withDrawal.savedUpFunds")}:</Form.Label>
                    <Form.Control
                      name="capitalAssets"
                      value={calculations.capitalAssets}
                      onChange={(e) => onChangeHandler(e)}
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                      type="number"
                      placeholder="€5,000.00"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("withDrawal.withdrawal")}:</Form.Label>
                    <div className="position-relative mb-2">
                      <Form.Control
                        name="withdrawal"
                        value={calculations.withdrawal}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                        disabled
                      />
                    </div>

                    <Form.Label>
                      {t("withDrawal.withdrawalInterval")}:
                    </Form.Label>
                    <Form.Select
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                      aria-label="Default select example"
                    >
                      <option>{t("withDrawal.monthly")} </option>
                      <option value="1">
                        {t("withDrawal.onceInThreeMonths")}
                      </option>
                      <option value="2">
                        {t("withDrawal.onceEveryMonths")}
                      </option>
                      <option value="3">{t("withDrawal.onceYear")}</option>
                    </Form.Select>
                  </Form.Group>
                  {/* <Form.Label>{t("withDrawal.savedUpFunds")}Withdrawal type:</Form.Label>
                  <Form.Select
                    className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                    aria-label="Default select example"
                  >
                    <option>{t("withDrawal.savedUpFunds")}Select Year</option>z
                    <option value="1">{t("withDrawal.savedUpFunds")}One</option>
                    <option value="2">{t("withDrawal.savedUpFunds")}Two</option>
                    <option value="3">{t("withDrawal.savedUpFunds")}hree</option>
                  </Form.Select> */}

                  <Form.Group className="mb-3">
                    <Form.Label>{t("withDrawal.dynamics")}:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="dynamics"
                        value={calculations.dynamics}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("withDrawal.interestRate")}:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="interestRate"
                        value={calculations.interestRate}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>

                  <Form.Label>{t("withDrawal.interestPeriod")} :</Form.Label>
                  <Form.Select
                    name="interestPeriod"
                    value={calculations.interestPeriod}
                    onChange={(e) => onChangeHandler(e)}
                    className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                    aria-label="Default select example"
                  >
                    <option value="12">{t("withDrawal.monthly")} </option>
                    <option value="4">
                      {t("withDrawal.onceInThreeMonths")}
                    </option>
                    <option value="2">{t("withDrawal.onceEveryMonths")}</option>
                    <option value="1">{t("withDrawal.onceYear")}</option>
                  </Form.Select>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("withDrawal.waitingPeriod")}:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="waitingPeriod"
                        value={calculations.waitingPeriod}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="10"
                      />
                    </div>
                  </Form.Group>

                  <div className="d-block calculation-radio-btn mb-3">
                    <Form.Label>
                      {" "}
                      {t("withDrawal.interestGracePeriod")}:
                    </Form.Label>

                    <Form.Check
                      label="Perpetual annuity (annuity without capital consumption)"
                      name="group1"
                      type="radio"
                      id="1"
                    />
                    <Form.Check
                      label="Limited pension period (pension with capital consumption)"
                      name="group1"
                      type="radio"
                      id="2"
                    />
                  </div>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("withDrawal.pensionPeriod")} :</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="pensionPeriod"
                        value={calculations.pensionPeriod}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("withDrawal.remainingCapital")}:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="remainingCapital"
                        value={calculations.remainingCapital}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                    </div>
                  </Form.Group>

                  <div className="d-flex items-center mb-3">
                    <Form.Label> {t("withDrawal.taxRate1")}:</Form.Label>

                    <Form.Check
                      onClick={() => setToggle(!toggle)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {toggle && (
                    <>
                      {/* <Form.Select
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                        aria-label="Default select example"
                      >
                        <option>{t("withDrawal.monthly")} </option>
                        <option value="1">
                          {t("withDrawal.onceInThreeMonths")}
                        </option>
                        <option value="2">
                          {t("withDrawal.onceEveryMonths")}
                        </option>
                        <option value="3">{t("withDrawal.onceYear")}</option>
                      </Form.Select> */}
                       <Form.Label>
                          {" "}
                          {t("withDrawal.taxRate")}:
                        </Form.Label>
                       <div className="position-relative">
                      <Form.Control
                        name="taxRate"
                        value={calculations.taxRate}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {" "}
                          {t("withDrawal.annualAllowance")}:
                        </Form.Label>

                        <Form.Control
                          name="taxAllowance"
                          value={calculations.taxAllowance}
                          onChange={(e) => onChangeHandler(e)}
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                          type="number"
                          placeholder="€1,000.00"
                        />
                      </Form.Group>
                      {/* <div className="d-block calculation-radio-btn mb-3">
                        <Form.Label>
                          {t("withDrawal.interestGracePeriod")}:
                        </Form.Label>

                        <Form.Check
                          label="Calendar year subtotals"
                          name="group3"
                          type="radio"
                          id="1"
                        />
                      </div> */}
                    </>
                  )}

                  {/* <div className="d-flex items-center mb-3">
                    <Form.Label> {t("withDrawal.gracePeriod")}:</Form.Label>

                    <Form.Check
                      onClick={() => setBeginningMonth(!beginningMonth)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {beginningMonth && (
                    <>
                      <Form.Group className="mb-3">
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="1,000"
                          />
                        </div>
                      </Form.Group>
                      <Form.Select
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                        aria-label="Default select example"
                      >
                        <option>{t("withDrawal.monthly")} </option>
                        <option value="1">
                          {t("withDrawal.onceInThreeMonths")}
                        </option>
                        <option value="2">
                          {t("withDrawal.onceEveryMonths")}
                        </option>
                        <option value="3">{t("withDrawal.onceYear")}</option>
                      </Form.Select>
                    </>
                  )} */}

                  <Button
                    className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                    onClick={() => onPressCalculate()}
                  >
                    {t("calculate")}
                  </Button>
                </Form>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 py-3 h-100 position-relative">
                <div className="position-absolute end-0 top-0 me-2 mt-2 ">
                  <img className="cursor-pointer" src={PdfIcon} alt="icon" />
                </div>
                <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                  {t("summary")}
                </h5>
                <div className="row align-items-center pt-3 pb-4">
                  <div className="col-md-6 mt-2">
                    <div
                      style={{ backgroundColor: "#f16880" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.regularWithdrawal}€
                      </h6>
                      <span className="text-light m-0">{t("withDrawal.regularWithdrawal")}</span>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div
                      style={{ backgroundColor: "#198754" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.totalWithdrawal}€
                      </h6>
                      <span className="text-light m-0">{t("withDrawal.totalWithdrawal")}</span>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <div
                      style={{ backgroundColor: "#2d3d30" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.totalInterest}€
                      </h6>
                      <span className="text-light m-0">{t("withDrawal.totalInterest")}</span>
                    </div>
                  </div>
                  {toggle && <>
                    <div className="col-md-6 mt-2">
                      <div
                        style={{ backgroundColor: "#FF0000" }}
                        className=" py-2 rounded-pill text-center"
                      >
                        <h6 className="text-light m-0 fw-bold">
                          {results.totalTaxes}€
                        </h6>
                        <span className="text-light m-0">{t("withDrawal.totalTaxes")}</span>
                      </div>
                    </div>
                  </>}
                </div>
                {chartShow && (
                  <Chart
                    onClick={() => setChartShow(false)}
                    results={results}
                  />
                )}
                {/* <h5 className="text-green text-center px-3 fw-bold text-capitalize mb-3">
                  About this calculation
                </h5>
                <div>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    This is the mobile view of the calculator. The default view
                    (desktop view) may also contain a history table. This is
                    also included in the PDF document. A permanent link created
                    in mobile view can also be opened on a PC in standard view
                    and vice versa.
                  </p>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    The interest calculator uses the input data to determine
                    either the required initial capital, the interest rate, the
                    term or the resulting final capital including interest.
                    Based on the output table, the interest calculator also
                    shows the course of the credit development and gives the
                    capital invested at the beginning of the year, the interest
                    credited at the end of the year, the previous sum of the
                    interest credited and the total capital at the end of the
                    respective year. The interest calculator allows you to
                    calculate with or without compound interest.
                  </p>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    If you calculate without compound interest, the capital
                    invested corresponds to the initial capital each year, since
                    the interest is not reinvested. If compound interest is
                    taken into account, on the other hand, the interest credited
                    is added to the invested capital every year and from then on
                    also bears interest. In the case of maturities that are not
                    a whole number of years, mixed interest is calculated if
                    linear interest is calculated over the course of the year.
                    This means that compound interest is initially used for the
                    whole year, but the last fraction of the year is compounded
                    with simple interest, as is normal bank practice for savings
                    deposits. In addition, a tax rate with an annual tax
                    exemption can be specified in the interest calculator to
                    take the new withholding tax into account.
                  </p>
                  <ul>
                    <li className="text-green fs-6 fw-medium">
                      quick start Guide
                    </li>
                    <li className="text-green fs-6 fw-medium">
                      quick start Guide
                    </li>
                    <li className="text-green fs-6 fw-medium">
                      {" "}
                      quick start Guide
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="money-saving bg-light-green my-5 py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-6">
              <span className="text-green text-start  text-md-center d-block fw-500">
                {t("savings")}
              </span>
              <h3 className="body-heading text-start text-md-center">
                {t("simpleWay")}
              </h3>
              <ul className="list-style mt-4">
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue,
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar .
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue, eu mollis sapien condimentum sit
                  amet.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <Blogs />
      {/* <FinancialGoal /> */}
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default WithDrawPlanCalculator;
