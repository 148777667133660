import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import RouterComponent from "./navigation/routerCompnent";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import ReactGA from 'react-ga';
ReactGA.initialize('G-G153E22RV4');

let persistor = persistStore(store);

const App = () => {
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterComponent />
      </PersistGate>
    </Provider>
  );
};

export default App;
