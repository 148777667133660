import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GetRequest, PostRequest } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AdviceModal = (props) => {
  const {
    calculations,
    results,
    fieldSets,
    inputEduValues,
    inputPersonValues,
    inputSavingValues,
    inputSubscriptions,
    inputTransValues,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const signInHandler = () => {
    const data = {
      email: email,
      phone: phone,
    };
    const url = "api/login";
    setLoading(true);

    email === ""
      ? toast.error("Please add Email")
      : phone === ""
      ? toast.error("Please add Phone Number")
      : name === ""
      ? toast.error("Please add Name")
      : PostRequest(data, "api/login").then((res) => {
          toast.success("Login Successfully");
          // props.onHide()
          setLoading(false);
        });
  };
  const getTips = () => {
    // const fixCostObjects = fieldSets.map((obj=>obj))
    const fixCostObjects = {};

    fieldSets.map(({ title, value }) => {
      fixCostObjects[title] = value;
    });
    const fixTransObjects = {};

    inputTransValues.map(({ title, value }) => {
      fixTransObjects[title] = value;
    });
    const fixEduObjects = {};

    inputEduValues.map(({ title, value }) => {
      fixEduObjects[title] = value;
    });
    const fixPersonObjects = {};

    inputPersonValues.map(({ title, value }) => {
      fixPersonObjects[title] = value;
    });
    const fixSubObjects = {};

    inputSubscriptions.map(({ title, value }) => {
      fixSubObjects[title] = value;
    });
    const fixSavingObjects = {};

    inputSavingValues.map(({ title, value }) => {
      fixSavingObjects[title] = value;
    });
    let sendData = JSON.stringify({
      householdIncome: calculations.householdIncome,
      otherSourceIncome: calculations.otherSourceIncome,
      childSupportIncome: calculations.childSupportIncome,
      miniJobIncome: calculations.miniJobIncome,
      livingCost: {
        ...calculations.livingCost,
        ...fixCostObjects,
      },
      transportationExpense: {
        ...calculations.livingCost,
        ...fixTransObjects,
      },
      educationExpense: {
        ...calculations.livingCost,
        ...fixEduObjects,
      },
      personalExpense: {
        ...calculations.personalExpense,
        ...fixSubObjects,
        ...fixPersonObjects,
      },
      savings: {
        ...calculations.savings,
        ...fixSavingObjects,
      },
      ...results,
    });
    let houseHoldData = {
      email: email,
      phone: phone,
      name: name,
      type: "savings",
      data: sendData,
      //   user:1
    };

    email === ""
      ? toast.error("Please add Email")
      : phone === ""
      ? toast.error("Please add Phone Number")
      : name === ""
      ? toast.error("Please add Name")
      : PostRequest(houseHoldData, "api/saveCalculationData").then((res) => {
          if (res.status) {
            if (res.data.success) {
              toast.success(res.data.message);
              props.onHide();
              setEmail("");
              setName("");
              setPhone("");
            } else {
              toast.error(res.data.message);
            }
          }
        });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {/* <h2>Log in to Your Account</h2> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>Secure Login: Access Your Account Safely and Seamlessly.</p> */}
          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("phoneNumber")}*</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="number"
                  placeholder="12345567"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <AiFillEyeInvisible className="eye-icon" />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name*</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="text"
                  placeholder="User Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </Form.Group>
          </Form>
          <Button className="button-login" onClick={() => getTips()}>
            {t("Save")}
          </Button>
        </Modal.Body>
      </Modal>
      <Toaster />
    </>
  );
};

export default AdviceModal;
