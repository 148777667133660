import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GetRequest, PostRequest } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateToken } from "../redux/tokenSlice";
import { user } from "../redux/userDetailSlice";

const LoginModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showSignup } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const adminLogin = (res) => {

    dispatch(user(res?.data?.data))
    if (res?.data?.data?.user?.email === "myadmin1@gmail.com") {
    dispatch(updateToken(res?.data?.data.token));
    toast.success("Admin Login Successfully");

    }
    else{
    toast.success("Login Successfully");

    }
    props.onHide()
    setLoading(false);
  };
  const signInHandler = () => {
    const data = {
      email: email,
      password: password,
    };
    const url = "api/login";
    setLoading(true);

    email === ""
      ? toast.error("Please add Email")
      : password === ""
      ? toast.error("Please add Password")
      : PostRequest(data, "api/login").then((res) => {
        console.log("login data : ",res);
        if (res.status) {
          if (res.data.success) {
            adminLogin(res)
          }
        } else {
          toast.error("try again later")
        }
        // if (email === "admin@gmail.com") {
        //   adminLogin()
        // } else {
        //   toast.success("Login Successfully");
        //   // props.onHide()
        //   setLoading(false);
        // }
          
        });
  };

  const signupHandler = () => {
    showSignup(true);
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{t("loginModal.title")}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("loginModal.title2")}</p>
          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("loginModal.userName")}*</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("loginModal.Password")}*</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="password"
                  placeholder="*****"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <AiFillEyeInvisible className="eye-icon" />
              </div>
            </Form.Group>
          </Form>
          <Button className="button-login" onClick={() => signInHandler()}>
            {t("login")}
          </Button>
          <span className="fw-medium text-black text-center d-block">
            {t("loginModal.noAccount")}{" "}
            <Link
              className="fw-semibold text-black"
              to="#"
              onClick={() => signupHandler()}
            >
              {" "}
              {t("loginModal.signUp")}?
            </Link>
          </span>
        </Modal.Body>
        <Toaster />
      </Modal>
    </>
  );
};

export default LoginModal;
