import PlusIcon from "../assets/images/plus.png";
import MinusIcon from "../assets/images/minus.jpeg";
import { useTranslation } from "react-i18next";
import { AiFillMinusCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useState } from "react";

export const InputFields = ({ inputTitle, fieldSets, setFieldSets }) => {
  const { t } = useTranslation();
  const [editInput, setEditInput] = useState(false);

  const addFieldSet = () => {
    setFieldSets((prevFieldSets) => [
      ...prevFieldSets,
      { title: "", value: "" },
    ]);
  };

  const handleValueChange = (event, index) => {
    const updatedFieldSets = [...fieldSets];
    updatedFieldSets[index].value = event.target.value;
    setFieldSets(updatedFieldSets);
  };

  const handleTitleChange = (event, index) => {
    const updatedFieldSets = [...fieldSets];
    updatedFieldSets[index].title = event.target.value;
    setFieldSets(updatedFieldSets);
  };

  const removeFieldSet = (index) => {
    const updatedFieldSets = [...fieldSets];
    updatedFieldSets.splice(index, 1);
    setFieldSets(updatedFieldSets);
  };

  return (
    <div>
      {fieldSets.map((fieldSet, index) => (
        <div key={index}>
          {!editInput && (
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <AiFillMinusCircle
                  fill="#FF7474"
                  size={30}
                  className="me-2"
                  onClick={() => removeFieldSet()}
                />

                <h6 className="text-start fw-500 w-40 mb-0 d-flex align-items-center">
                  <input
                    name="living"
                    type="text"
                    value={fieldSet.title}
                    onChange={(event) => handleTitleChange(event, index)}
                    style={{ border: "none" }}
                    className="bg-transparent w-100 changeableInput text-black"
                    placeholder={t("houseHold.addCosts")}
                  />
                  <MdOutlineModeEditOutline
                    size={20}
                    fill="#3E3E3E"
                    onClick={() => setEditInput(true)}
                  />
                </h6>
              </div>

              <div>
                <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                  <input
                    name="living"
                    type="text"
                    value={fieldSet.value}
                    onChange={(event) => handleValueChange(event, index)}
                    style={{ border: "none" }}
                    className="text-center"
                    placeholder="0"
                  />
                </h4>
              </div>
            </div>
          )}
          {editInput && (
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center">
                <AiFillMinusCircle
                  fill="#FF7474"
                  size={30}
                  className="me-2"
                  onClick={() => removeFieldSet()}
                />

                <h6 className="text-start fw-500 w-40 mb-0 d-flex align-items-center px-2 py-2 rounded-2 bg-white">
                  <input
                    name="living"
                    type="text"
                    value={fieldSet.title}
                    onChange={(event) => handleTitleChange(event, index)}
                    style={{ border: "none" }}
                    className="bg-white w-100 changeableInput text-black "
                    placeholder={t("houseHold.addCosts")}
                  />
                  <AiOutlineCheckCircle
                    size={25}
                    fill="#12AA22"
                    onClick={() => setEditInput(false)}
                  />
                </h6>
              </div>

              <div>
                <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                  <input
                    name="living"
                    type="text"
                    value={fieldSet.value}
                    onChange={(event) => handleValueChange(event, index)}
                    style={{ border: "none" }}
                    className="text-center"
                    placeholder="0"
                  />
                </h4>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="mb-3">
        <button onClick={addFieldSet} className="add-new-tab">
          {t("addNew")}
        </button>

        {/* <img
          onClick={addFieldSet}
          src={PlusIcon}
          alt="icon"
          className="plusIcon"
        /> */}
      </div>
    </div>
  );
};
