import HomeBannerImage from "../assets/images/home-banner.svg";
import cardImage1 from "../assets/images/saving-and-investment.svg";
import calculator from "../assets/images/calculator.png";
import CalculatorImage from "../assets/images/calculator-img.svg";
import CalculatorImage2 from "../assets/images/calculator-img2.svg";
import FinanceGoal from "../assets/images/finance-and-goal.svg";
import FixedDepositCalculator from "../components/FixedDepositCalculator";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "react-bootstrap";
import Blogs from "../components/Blogs";
import StayInformed from "../components/StayInformed";
import Cookies from "../components/Cookies";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { GetRequest } from "../utils/apiCall";
import { useSelector } from "react-redux";
import { userData } from "../redux/favouriteDataSlice";
import ServicesPopup from "../components/servicesPopup";
import GetAdd from "../components/GetAddPopup";
import Loader from "../components/Loader";
import axios from 'axios';
import { Helmet } from "react-helmet";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Home = () => {
  const userLang = useSelector(userData);
  let selectedLanguage = "de";
  selectedLanguage = userLang.lang === "en" ? "en" : "de";

  const { t, i18n } = useTranslation();
  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState(null);

  const getAllCategories = () => {
    setLoading(true);
    GetRequest("api/categories").then((res) => {
      setLoading(false);
      setAllCategories(res?.data?.data?.categories || []);
    });
  };
  const navigateTo = (ref, slug) => {
    // if (ref === "counsellor") {
    //   setShowModal(true);
    // } else {
    const data = { slug: `${slug}` };
    if (slug !== "") {
      localStorage.setItem("myData", JSON.stringify(data));
    }
    // window.location.href = `/${ref}/${slug}`;
    if (!slug) {
      window.location.href = `/${ref}`;
    } else {
      window.location.href = `/${ref}/${slug}`;
    }
    // }
  };
  const scrollToSection = () => {
    const targetSection = document.getElementById("useCalculator");
    targetSection.scrollIntoView({ behavior: "smooth" });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: allCategories.length < 3 ? allCategories.length : 3,
    slidesToScroll: 1,
    spaceBetween: 20,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    getAllCategories();
    i18n.changeLanguage(userLang?.lang !== "" ? userLang?.lang : "de");
  }, []);
  const canonicalUrl = window.location.href;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
      </Helmet>
      <section className="banner-sec home-banner">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6 mb-5 mb-md-0">
              {/* <h1 className="text-white fw-500 text-capitalize">
                {t("main_subheading")} <br className="d-md-block d-none"></br>{" "}
              </h1> */}
              <p className="text-white mt-3 home-heading">
                {t("main_subheading")} <br className="d-md-block d-none"></br>{" "}
              </p>
              <div className="d-block d-md-flex mt-4">
                <Button
                  className="btn-100 get-started-btn bg-white text-green px-3 px-lg-5 py-2 py-lg-3 border-0 me-3 fw-bold mb-md-0 mb-3"
                  onClick={scrollToSection}
                >
                  {t("get_start")}
                </Button>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 d-flex justify-content-end">
              <div className="banner-tab">
                <Link to="/fondsrechner">
                  <div className="cursor-pointer tab-box d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="tab-img-custom">
                        <img
                          src={calculator}
                          alt="icon"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div>
                        <h5>{t("whatsNewHeading1")}</h5>
                        <p >{t("whatsNewSubHeading1")}</p>
                      </div>
                    </div>
                    <IoIosArrowForward size={25} />

                  </div>
                </Link>
                <Link to="/sparrechner">
                  <div className="cursor-pointer tab-box d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="tab-img-custom">
                        <img
                          src={calculator}
                          alt="icon"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div>
                        <h5>{t("whatsNewHeading2")}</h5>
                        <p >{t("whatsNewSubHeading2")}</p>
                      </div>
                    </div>
                    <IoIosArrowForward size={25} />

                  </div>
                </Link>
                <Link to="/haushaltsrechner">
                  <div className="cursor-pointer tab-box d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      <div className="tab-img-custom">
                        <img
                          src={calculator}
                          alt="icon"
                          className="img-fluid rounded-circle"
                        />
                      </div>
                      <div>
                        <h5>{t("whatsNewHeading3")}</h5>
                        <p >{t("whatsNewSubHeading3")}</p>
                      </div>
                    </div>
                    <IoIosArrowForward size={25} />

                  </div>
                </Link>
              </div>


              {/* <img
                className="d-block mx-auto w-100 mx-md"
                src={HomeBannerImage}
                alt="bannerImage"
              /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="cards-sec" id="categoriesSlider">
        <div className="container">
          <div className="row pb-5">
            <Slider {...settings}>
              {allCategories?.map((category, index) => {
                return (
                  <div key={index}>
                    {loading && <Loader />}
                    {!loading && (
                      <div className="card-one text-center">
                        <div className="card-img-custom">
                          <img
                            src={category.image ? category.image : cardImage1}
                            alt="icon"
                            className="img-fluid rounded-circle"
                          />
                        </div>
                        <h4 className="text-green fw-600 mt-3 mb-4">
                          {/* {t("Saving&investing")} */}
                          {category["name_" + selectedLanguage]}
                        </h4>
                        <p className="m-0 mx-2 mx-lg-5 pb-3">
                          {category["description_" + selectedLanguage]}
                        </p>
                        <Button
                          className="get-started-btn bg-transparent border-0 text-green fw-bold pt-3"
                          onClick={() =>
                            navigateTo("finanzrechner", category.slug)
                          }
                        >
                          {t("Read_more")}
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
          <GetAdd show={show} setShow={setShow} type={type} cat={true} />
          <ServicesPopup
            showModal={showModal}
            setShow={setShow}
            setShowModal={setShowModal}
            setType={setType}
          />
        </div>
      </section>

      <FixedDepositCalculator />
      <section className="household-sec" id="useCalculator">
        <div className="container">
          <div className="row align-items-center py-2 py-md-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={CalculatorImage}
                alt="img not found"
              />
            </div>
            <div className="col-md-5 mb-md-0 mb-5">
              <h2 className="fs-1 text-green  mb-4">
                {t("How_to_use_calculators")}
              </h2>
              <p className="p-heading fw-600  pe-5">
                {t("use_calculator_description")}
              </p>
              <Button
                className="bg-green text-white rounded-pill px-4 padding-y-3 border-0 mt-3 fw-600"
                onClick={() => navigateTo("rechner")}
              >
                {t("Check_financial_calculators")}
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center  py-5 justify-content-between">
            <div className="col-md-5 text-start text-md-end mb-md-0 mb-5">
              <h2 className="fs-1 text-green  mb-4 text-start text-md-end">
                {t("Electricity&Gas")}
              </h2>
              <p className="p-heading fw-600  pe-5 pe-md-0 ps-md-5 text-start text-md-end">
                {t("Electricity&Gas_description")}
              </p>
              <Button
                className=" bg-green text-white rounded-pill px-3 px-md-2 px-lg-4 padding-y-3 border-0 mt-3 fw-600"
                onClick={() => navigateTo("vergleiche-ele-und-gas")}
              >
                {t("Compare_electricity_or_gas_contract")}
              </Button>
            </div>
            <div className="col-md-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={CalculatorImage2}
                alt="img not found"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={FinanceGoal}
                alt="img not found"
              />
            </div>
            <div className="col-md-5 mb-md-0 mb-5">
              <h2 className="fs-1 text-green  mb-4">
                {t("Plan_Your_Own_Finances&Goals")}
              </h2>
              <p className="p-heading fw-600  pe-5">
                {t("In_order_to_get_a_concrete_overview")}
              </p>
              <Button
                className="bg-green text-white rounded-pill px-4 padding-y-3 border-0 mt-3 fw-600"
                onClick={() => navigateTo("rechner")}
              >
                {t("Plane_your_goal")}
              </Button>
            </div>
          </div>
        </div>
      </section>

      <Blogs />

      <StayInformed />

      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default Home;
