import React from "react";
import { Button } from "react-bootstrap";

function ChildSupport({ childSupportIncome, calculations, setCalculations }) {
  const onChangeHandler = (e) => {
    const value = e.target.value;
    setCalculations({
      ...calculations,
      childSupportIncome: value,
    });
  };
  return (
    <div>
      <h4 className="d-flex py-1 bg-white d-block income-box fw-bold">
        <Button
          className=" d-flex justify-content-center bg-green text-white border-sm  border-0"
          onClick={() => childSupportIncome("minus")}
        >
          <h3>-</h3>
        </Button>
        <input
          name="otherSourceIncome"
          value={calculations.childSupportIncome}
          style={{ border: "none" }}
          onChange={onChangeHandler}
          className="text-center w-100"
          placeholder="0"
        />
        <Button
          className="d-flex justify-content-center bg-green text-white border-sm fw-bold border-0 "
          onClick={() => childSupportIncome("plus")}
        >
          <h3>+</h3>
        </Button>
      </h4>
    </div>
  );
}

export default ChildSupport;
