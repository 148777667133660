import axios from "axios";
import { baseURL } from "./appUris";
// const baseURL = "https://finanzfaible.devsleagues.com";
// const baseURL = "https://backend.finanzfaible.com";

export const GetRequest = async (url) => {
  try {
    const res = await axios({
      method: "get",
      url: url,
      baseURL: baseURL,
      headers: {},
    });

    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};
export const DownLoadFormRequest = async (url) => {
  try {
    const res = await axios({
      method: "get",
      url: url,
      headers: {},
    });

    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};
export const RegisterPostRequest = async (data, url) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
  };
  try {
    const res = await axios({
      method: "post",
      url: url,
      baseURL: baseURL,
      data: data,
      headers: headers,
    });
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};
export const PostRequest = async (data, url) => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  try {
    const res = await axios({
      method: "post",
      url: url,
      baseURL: baseURL,
      data: data,
      headers: headers,
    });
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};
export const PostRequestForms = async (data, url) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const res = await axios({
      method: "post",
      url: url,
      baseURL: baseURL,
      data: data,
      headers: headers,
    });
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};
export const PostRequestInsta = async (data, url) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const res = await axios({
      method: "post",
      url: url,
      baseURL: baseURL,
      data: data,
      headers: headers,
    });
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};
