import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RxCross1 } from "react-icons/rx";
import Cookiess from "js-cookie";

const Cookies = ({ showCookies }) => {
  const { t } = useTranslation();

  const [userChoice, setUserChoice] = useState(null);

  useEffect(() => {
    const storedChoice = Cookiess.get("cookieChoice");
    if (storedChoice) {
      setUserChoice(storedChoice);
      showCookies(false)
    }
  }, []);

  const handleAccept = () => {
    Cookiess.set("cookieChoice", "accepted", { expires: 7 });
    setUserChoice("accepted");
    showCookies(false)
  };

  const handleReject = () => {
    Cookiess.set("cookieChoice", "rejected", { expires: 7 });
    setUserChoice("rejected");
    showCookies(false)
  };

  return (
    <div className={`mx-md-0 mx-2 rounded-2 rounder-md-0 fixed-box bg-black py-3 position-fixed bottom-0 start-0 end-0 zindex-9 ${userChoice ? 'd-none' : ''}`}>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <p className="text-white fs-12 m-0 d-none d-md-block ">
            {t("cookies_p")}
          </p>
          <p className="text-white fs-12 m-0 d-block d-md-none">
            {t("cookies")}
          </p>
          <div className="d-flex align-items-center">
            <Button
              className="rounded-0 bg-transparent border border-white text-white fw-500 px-1 px-md-3 py-1 py-md-2 ms-2 me-3"
              onClick={handleReject}
            >
              {t("Reject_all")}
            </Button>
            <Button
              className="rounded-0 bg-white text-green border border-white fw-500 px-1 px-md-3 py-1 py-md-2 get-started-btn"
              onClick={handleAccept}
            >
              {t("Agree")}
            </Button>
            <RxCross1
              className="cross-icon d-md-block d-none"
              onClick={() => showCookies(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
