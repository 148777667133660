import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { PostRequest } from "../utils/apiCall";
import { apiEndpoints } from "../utils/appUris";
import { Toaster, toast } from "react-hot-toast";
import Loader from "../components/Loader";

const Unsubscribe = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false)

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleUnsubscribe = (e) => {
    setLoading(true)
    const data = {
      email: email,
    };
    PostRequest(data, apiEndpoints.unsubscribe).then((res)=>{
      console.log("unsbscribe:",res);
      if (res.status) {
        if (res.data.success) {
          toast.success(res?.data?.message)
        }else{

          toast.error(res?.data?.message)

        setLoading(false)
        }
      }

      setLoading(false)
    })
  };

  return (
    <Container
      className="my-5"
      style={{ paddingTop: "10%", paddingBottom: "5%" }}
    >
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Header as="h5" className="bg-green text-white text-center">
              Do you want to unsubscribe?
            </Card.Header>
            <Card.Body>
              <p style={{ color: "gray" }}>
                If you unsubscribe, you will stop receiving our weekly
                newsletter.
              </p>
              <Form >
                <Form.Group controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </Form.Group>
                {!loading && <Button className="mt-4" variant="danger"   onClick={()=>handleUnsubscribe()}>
                  Unsubscribe
                </Button>}
                {loading && <Loader />}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Toaster />
    </Container>
  );
};

export default Unsubscribe;
