import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Toaster, toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { PostRequest } from "../utils/apiCall";

const CalculationsPopup = ({ count, setCount }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const handleClose = () => {
    setShowModal(false);
    setCount(0);
  };
  const signInHandler = () => {
    const data = {
      email: email,
    };
    const url = "api/login";
    setLoading(true);

    email === ""
      ? toast.error("Please add Email")
      : PostRequest(data, "api/login").then((res) => {
          toast.success("Login Successfully");
          setEmail("");
          // props.onHide()
          setLoading(false);
        });
  };
  useEffect(() => {
    if (count > 1) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [count]);

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        className="login-modal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            <h2>{t("getAdd.title")}</h2>
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <>
            <h2>{t("calculationsTextPop")} </h2>
            {/* <h2
              className="text-green fs-2 cursor-pointer"
              onClick={() => handlePress("mail")}
            >
              {t("servicesPopup.title1")}
            </h2> */}
            <Form>
              <Form.Group
                className="mb-4"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>{t("Email_ddress")}*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Form>

            <Button className="button-login" onClick={() => signInHandler()}>
              {t("getAdd.signUp")}
            </Button>
          </>
        </Modal.Body>
      </Modal>
      <Toaster />
    </>
  );
};

export default CalculationsPopup;
