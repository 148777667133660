import React, { useEffect, useState } from "react";
import { NavLink } from "react-bootstrap";
import {
  AiFillInstagram,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import FooterLogo from "../assets/images/footer-logo.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { GetRequest } from "../utils/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { categoriesData, categoriesDetails } from "../redux/categoriesSlice";

function Footer() {
  const selectedLanguage = i18next.language;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const myCategoriesData = useSelector(categoriesData);
  console.log("categories redux data : ", myCategoriesData);

  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllCategories = () => {
    setLoading(true);
    if (myCategoriesData.length === 0) {
      GetRequest("api/categories").then((res) => {
        setLoading(false);
        setAllCategories(res?.data?.data?.categories || []);
        dispatch(categoriesDetails(res?.data?.data?.categories || []));
      });
    } else {
      setAllCategories(myCategoriesData || []);
    }
  };
  const navigateTo = (ref, slug) => {
    const data = { slug: `${slug}` };
    localStorage.setItem("myData", JSON.stringify(data));
    window.location.href = `/${ref}/${slug}`;
  };
  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <footer className="bg-green pt-5 mb-0">
      <div className="container">
        <div className="row justify-content-between pb-5">
          <div className="col-12 col-md-12 col-lg-4 mb-4 mb-lg-0">
            <h5 className="text-white fw-bold text-capitalize mb-4">
              {t("footer_about")}
            </h5>
            <p className="p-heading text-white">{t("footer_about_p")}</p>
          </div>
          <div className="col-6 col-md-6 col-lg-2 mb-4 mb-lg-0">
            <h5 className="text-white fw-bold text-capitalize mb-4">
              {t("Quick_Links")}
            </h5>
            <ul className="list-unstyled">
              <li className="text-white mb-2">
                <NavLink href="/finanzrechner">{t("counsellor")}</NavLink>
              </li>
              <li className="text-white mb-2">
                <NavLink href="/rechner">{t("calculator")}</NavLink>
              </li>
              <li className="text-white mb-2">
                <NavLink href="/formularcenter">
                  {t("Financial_Services")}
                </NavLink>
              </li>
              {/* <li className="text-white mb-2">
                <NavLink to="/">{t("News&Press")}</NavLink>
              </li> */}
              <li className="text-white mb-2">
                <NavLink href="/kontaktiere-uns">{t("Contact")}</NavLink>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-6 col-lg-2 mb-4 mb-lg-0">
            <h5 className="text-white fw-bold text-capitalize mb-4">
              {t("Services")}
            </h5>
            <ul className="list-unstyled">
              {allCategories?.map((category, index) => {
                return (
                  <li key={index} className="text-white mb-2">
                    <NavLink
                      onClick={() => navigateTo("finanzrechner", category.slug)}
                    >
                      {category["name_" + selectedLanguage]}
                    </NavLink>
                  </li>
                );
              })}
              {/* <li className="text-white mb-2">
                <NavLink to="/">{t("Saving & investing")}</NavLink>
              </li>
              <li className="text-white mb-2">
                <NavLink to="/">{t("Account and Cards")}</NavLink>
              </li>
              <li className="text-white mb-2">
                <NavLink to="/">{t("Credit & Financing")}</NavLink>
              </li>
              <li className="text-white mb-2">
                <NavLink to="/">{t("Insurance")}</NavLink>
              </li>
              <li className="text-white mb-2">
                <NavLink to="/">{t("Real Estate & Property")}</NavLink>
              </li> */}
            </ul>
          </div>
          <div className="col-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
            <h5 className="text-white fw-bold text-capitalize mb-4">
              {t("Find_us")}
            </h5>
            {/* <ul className="list-unstyled footer-list">
              <li className="text-white mb-3 d-flex">
                <HiLocationMarker />
                <NavLink to="/">
                  <span> {t("address")}</span>
                </NavLink>
              </li>
              <li className="text-white mb-3 text-decoration-underline d-flex">
                <IoMdCall />
                <NavLink to="/">+49 (0) 1575 2235967</NavLink>
              </li>
              <li className="text-white mb-3 text-decoration-underline d-flex">
                <MdEmail />
                <NavLink to="/">mailto:mo@finanzfaible.de</NavLink>
              </li>
            </ul> */}
            <div className="social-icon mt-4 d-flex">
              <NavLink
                target="_blank"
                href=" https://wa.me/message/WWGQRYSRIXNWK1"
              >
                <AiOutlineWhatsApp />
              </NavLink>
              <NavLink
                target="_blank"
                href="https://instagram.com/finanzfaible?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D"
              >
                <AiFillInstagram />
              </NavLink>
              <NavLink
                target="_blank"
                href="https://www.facebook.com/finanzfaible"
              >
                <FaFacebookF />
              </NavLink>
              <NavLink target="_blank" href="https://twitter.com/finanzfaible">
                <AiOutlineTwitter />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="bottom-footer d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <img src={FooterLogo} alt="logo" />
          </div>
          <div>
            <p className="mb-0">@ Copyright Finanzfaible 2023</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
