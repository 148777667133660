import {createSlice} from '@reduxjs/toolkit';

export const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    data:{
      lang:'de'
    }
  },
  reducers: {
    userDetails: (state, action) => {
      return action.payload;
    }
  },
});

export const {userDetails} = userDataSlice.actions;
export const userData = state => state.userData;

export default userDataSlice.reducer;
