import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../assets/images/logo.svg";
import LanguageIcon from "../assets/images/language-icon.svg";

import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "../redux/favouriteDataSlice";
import { useTranslation } from "react-i18next";
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import axios from "axios";
import { token, updateToken } from "../redux/tokenSlice";
import { Toaster, toast } from "react-hot-toast";
import VerifyOtpModal from "./verifyOtpModal";
import { userDetail } from "../redux/userDetailSlice";

function Header() {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowSignup, setModalShowSignup] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [email, setEmail] = useState("")
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userToken = useSelector(token);
  const userData = useSelector(userDetail);
  console.log("userDetails :",userData);

  const setLang = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(userDetails({ lang: lang }));
  };
  const navigateTo = () => {
    const data = { slug: "blogs" };
    localStorage.setItem("myData", JSON.stringify(data));
  };
  const logoutHandle = ()=>{
    dispatch(updateToken(""));
    toast.success("Admin Logout Successfully");
    window.location.href = `/`;
  }

  useEffect(() => {
    // i18n.changeLanguage(lang);
  }, []);

  return (
    <header>
      <div className="container">
        <Navbar className="bg-white mt-4" expand="lg">
          <Container fluid>
            <div className="d-flex justify-content-between w-100">
              <div>
                <Navbar.Brand href="/">
                  <img src={Logo} alt="logo" />
                </Navbar.Brand>
              </div>
              <div>
                {" "}
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  <Nav
                    className="me-auto align-items-lg-center my-2 my-lg-0"
                    // style={{ maxHeight: "100px" }}
                    navbarScroll
                  >
                    <Nav.Link href="/">{t("home")}</Nav.Link>
                    <Nav.Link href="/finanzrechner" onClick={() => navigateTo()}>
                      {t("counsellor")}
                    </Nav.Link>
                    <Nav.Link href="/rechner">{t("calculator")}</Nav.Link>
                    <Nav.Link href="/formularcenter">
                      {t("Financial_Services")}
                    </Nav.Link>
                    {/* <Nav.Link href="#action2">{t("News&Press")}</Nav.Link> */}
                    <Nav.Link href="/kontaktiere-uns">{t("Contact")}</Nav.Link>
                    { userToken.token !== '' && <Nav.Link href="/instagramPosts">Fetch Posts</Nav.Link>}
                    <Dropdown className="header-dropdown mobile-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        {t("account")}
                        <div className="d-lg-none d-block"></div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {userToken.token === '' && <> <Dropdown.Item
                          href="#"
                          onClick={() => setModalShow(true)}
                        >
                          {t("login")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setModalShowSignup(true)}
                        >
                          {t("Register")}
                        </Dropdown.Item>
                        
                        </>}
                        {userToken.token !== '' && <> 
                        <Dropdown.Item
                          href="#"
                          // onClick={() => logoutHandle()}
                        >
                          {userData?.user?.first_name}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => logoutHandle()}
                        >
                          Logout
                        </Dropdown.Item>
                        </>}
                      </Dropdown.Menu>
                    </Dropdown>

                    <LoginModal
                      show={modalShow}
                      showSignup={setModalShowSignup}
                      onHide={() => setModalShow(false)}
                    />
                    <SignupModal
                      show={modalShowSignup}
                      showSignIn={setModalShow}
                      setOtpModal={setOtpModal}
                      setEmail={setEmail}
                      onHide={() => setModalShowSignup(false)}
                    />
                    <VerifyOtpModal
                      show={otpModal}
                      email={email}
                      onHide={() => setOtpModal(false)}
                    />
                  </Nav>
                  <Dropdown className="header-dropdown ">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img className="me-1 " src={LanguageIcon} alt="icon" />
                      <div className="d-lg-none d-block ">
                        {t("selectLang")}
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={() => setLang("de")}
                      >
                        Deutsch
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={() => setLang("en")}
                      >
                        English
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Navbar.Collapse>
              </div>
            </div>
          </Container>
        </Navbar>
      </div>

      <Toaster />
    </header>
  );
}

export default Header;
