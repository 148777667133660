import React, { useState } from "react";
import { Button, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PostRequest } from "../utils/apiCall";
import Calendar from "../assets/images/calendar-img.svg";
import calendarIcon from "../assets/images/calendar-icon.svg";
import close from "../assets/images/close.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster, toast } from "react-hot-toast";

const BudgetReminder = ({budgetType}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [selectedItem, setSelectedItem] = useState(t("getReminder.weekly"));
  const [showDate, setShowDate] = useState(false);

  const subscribeHandler = () => {
    const data = {
      email: email,
      type:"reminder",
      remind_at:selectedItem,
      remind_date: expiryDate,
      phone_number:phone,
    };

    email === ""
      ? toast.error("please add Email")
      :selectedItem === ""?toast.error("please add remind at")
      :expiryDate === ""?toast.error("please add expiry date")
      : PostRequest(data, "api/join/newsletter").then((res) => {
        toast.success(res.data.message);
          setEmail("");
        });
  };
  const handleDateChange = (date) => {
    setExpiryDate(date);
  };
  const selectedItemHandle = (item) => {
    if (item === "anyDate") {
      setShowDate(true);
    } else {
      setShowDate(false);
      setSelectedItem(item);
    }
  };

  return (
    <section className="budget-reminder bg-light-green py-4">
      <div className="container">
        <div className="row align-items-center justify-content-center py-5">
          <div className="col-12 col-lg-10">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-5">
                <h2 className=" text-green">
                  <span className="text-dark-green  d-block">
                    {t("getReminder.title1")}
                  </span>
                  {t("getReminder.title2")}
                </h2>

                <Form.Control
                  className="mt-3"
                  type="text"
                  placeholder={t("getReminder.placeholder")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Control
                  className="mt-3"
                  type="text"
                  placeholder={t("getReminder.placeholder1")}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {budgetType === "fixed" ? (
                  <h4 className=" text-green pt-3">{t("getReminder.text1")}</h4>
                ) : (
                  <h4 className=" text-green pt-3">{t("getReminder.text")}</h4>
                )}
                {showDate && (
                  <div className="input-group-btn d-flex justify-center align-item-center ">   
                  <Row className="w-100  ">
                   <DatePicker
                      className="form-control w-80"
                      placeholderText={t("getReminder.date")}
                      selected={expiryDate}
                      onChange={handleDateChange}
                    />
                      </Row>
                    <Button
                    style={{zIndex:1}}
                      className="btn-clear border-0 bg-white  w-20"
                      onClick={() =>
                        selectedItemHandle(t("getReminder.weekly"))
                      }
                    >
                      <img src={close} alt="image not found" style={{width:"10px",height:"10px"}} />
                    </Button>
                    </div>
                )}
                {!showDate && (
                  <div className="w-100 ">
                    <Dropdown className="paymentType fixDepositDrop">
                      <Dropdown.Toggle
                        variant="transparent"
                        id="dropdown-basic"
                        
                      >
                        <div className="interestPayment">{selectedItem}</div>
                        
                        <div className="d-lg-none d-block "></div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-100 ">
                        <Dropdown.Item
                        className="w-100 "
                          href="#"
                          onClick={() =>
                            selectedItemHandle(t("getReminder.weekly"))
                          }
                        >
                          {t("getReminder.weekly")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() =>
                            selectedItemHandle(t("getReminder.monthly"))
                          }
                        >
                          {t("getReminder.monthly")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() =>
                            selectedItemHandle(t("getReminder.quarterly"))
                          }
                        >
                          {t("getReminder.quarterly")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-4"
                          onClick={() =>
                            selectedItemHandle(t("getReminder.everySixMonths"))
                          }
                        >
                          {t("getReminder.everySixMonths")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-5"
                          onClick={() =>
                            selectedItemHandle(t("getReminder.yearly"))
                          }
                        >
                          {t("getReminder.yearly")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-6"
                          onClick={() => selectedItemHandle("anyDate")}
                        >
                          {t("getReminder.anyDate")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                <Button
                  className="mt-4 bg-green px-3 py-2 text-white rounded-pill border-0 fw-bold"
                  onClick={() => subscribeHandler()}
                >
                  {t("getReminder.button")}
                </Button>
              </div>
              <div className="col-md-6 col-lg-6 mt-md-0 mt-5">
                <img
                  className="d-block mx-auto w-100 mx-md"
                  src={Calendar}
                  alt="img not found"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </section>
  );
};

export default BudgetReminder;
