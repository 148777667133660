import React, { useEffect, useState } from "react";
import BannerImage from "../assets/images/saving-calculator-banner.svg";
import Household from "../assets/images/household-img.svg";
import Calendar from "../assets/images/calendar-img.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";

import { Button, Form } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";
import Chart from "../components/Chart";
import { useTranslation } from "react-i18next";
import CalculationsPopup from "../components/CalculationsPop";
import FixedCalculator from "../assets/images/fixed-calculator.svg";
import SavingCalculatorChart from "../components/SavingCalculatorChart";

const SavingCalculator = () => {
	const { t } = useTranslation();
	const [isActive, setIsActive] = useState(false);
	const [toggleTex, setToggleTex] = useState(false);
	const [dynamicValue, setDynamicValue] = useState(false);
	const [inflationValue, setInflationValue] = useState(false);
	const [beginningMonth, setBeginningMonth] = useState(false);
	const [showCookies, setShowCookies] = useState(true);
	const [chartShow, setChartShow] = useState(false);
	const [count, setCount] = useState(0);
	const [toFind, setToFind] = useState({
		findInitialCapital: false,
		findDuration: false,
		findInterestRate: false,
		findSavingRate: false,
		findSavingInterval: false,
		findCompoundInterest: false,
		findFinalCapital: true,
	});
	const [calculations, setCalculations] = useState({
		initialCapital: "",
		savingRate: "",
		savingInterval: "monthly",
		depositType: "",
		interestRate: "",
		interestPeriod: "years",
		duration: "",
		durationType: "years",
		compoundInterest: "compound",
		savingTime: "",
		deadLine: "years",
		texRate: "26.373",
		annualTexAllowance: "1000",
		dynamics: "",
		dynamicsValue: "",
		inflation: "",
		inflationValue: "",
		text: "",
	});
	const [results, setResults] = useState({
		finalCapital: "",
		totalDeposit: "",
		totalInterest: "",
		finalInterestRate: "",
		finalInitialValue: "",
		finalDuration: "",
		finalSavingRate: "",
	});

	const onChangeHandler = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setCalculations({
			...calculations,
			[name]: value,
		});
	};

	const selectToFind = (type) => {
		if (type === "initialCapital") {
			setToFind({
				findInitialCapital: !toFind.findInitialCapital,
				findDuration: false,
				findInterestRate: false,
				findFinalCapital: false,
				findSavingRate: false,
				findSavingInterval: false,
				findCompoundInterest: false,
			});
			setCalculations({
				...calculations,
				initialCapital: "",
				text: "savingsCalculator.calculatedInitialCapital",
			});
		}
		if (type === "interestRate") {
			setToFind({
				findInitialCapital: false,
				findDuration: false,
				findInterestRate: !toFind.findInterestRate,
				findFinalCapital: false,
				findSavingRate: false,
				findSavingInterval: false,
				findCompoundInterest: false,
			});
			setCalculations({
				...calculations,
				interestRate: "",
				text: "savingsCalculator.calculatedInterestRate",
			});
		}
		if (type === "savingRate") {
			setToFind({
				findInitialCapital: false,
				findDuration: false,
				findInterestRate: false,
				findFinalCapital: false,
				findSavingRate: !toFind.findSavingRate,
				findSavingInterval: false,
				findCompoundInterest: false,
			});
			setCalculations({
				...calculations,
				savingRate: "",
				text: "savingsCalculator.calculatedSavingRate",
			});
		}
		if (type === "duration") {
			setToFind({
				findInitialCapital: false,
				findDuration: !toFind.findDuration,
				findInterestRate: false,
				findFinalCapital: false,
				findSavingRate: false,
				findSavingInterval: false,
				findCompoundInterest: false,
			});
			setCalculations({
				...calculations,
				duration: "",
				text: "savingsCalculator.calculatedDuration",
			});
		}
		if (type === "finalCapital") {
			setToFind({
				findInitialCapital: false,
				findDuration: false,
				findInterestRate: false,
				findFinalCapital: !toFind.findFinalCapital,
				findSavingRate: false,
				findSavingInterval: false,
				findCompoundInterest: false,
			});
			setResults({
				...results,
				finalCapital: "",
				text: "savingsCalculator.calculatedFinalCapital",
			});
		}
	};

	const calculateInitialCapital = () => {
		let initialValueIs = 1
		const finalValueIs = checkFinalValue(initialValueIs)
		

		setResults({
			finalCapital: "",
			totalDeposit: "5000",
			totalInterest: "120",
			totalTex: 0,
			finalInterestRate: "",
			finalInitialValue: "1000",
			finalDuration: "",
			finalSavingRate: "",
		});
	};
	const checkFinalValue = (checkValue)=>{
		const durationInYears = calculations.durationType === "years" ? calculations.duration : calculations.duration / 12;
		const durationInMonths = durationInYears * 12

		const monthlySavingsArray = []
		const monthlyBalanceArray = []
		const monthlyInterestArray = []
		const monthlyTaxArray = []
		const monthlyTaxAllowanceArray = []

		let sI = 0, eI = 0, exemptedForCurrentYear = false

		for (let i = 0; i < durationInMonths; i++) {
			const currentMonthSaving = getCurrentMonthSaving(parseFloat(calculations.savingRate), i, calculations.savingInterval)

			monthlySavingsArray.push(currentMonthSaving)

			const accSaving = getAccSum(monthlySavingsArray)

			const balance = checkValue + accSaving

			const accInterest = getAccSum(monthlyInterestArray)

			let totalBalance = balance
			if (calculations.compoundInterest === 'compound') {
				totalBalance = balance + accInterest
			}
			// console.log('taxPrevious', parseFloat((totalBalance - (monthlyTaxArray[i - 1])).toFixed(3)))
			totalBalance = parseFloat((totalBalance - (monthlyTaxArray[i - 1] || 0)).toFixed(3))
			const interestMonthly = getCurrentMonthInterest(totalBalance, calculations.interestRate, i, calculations.savingInterval)
			monthlyInterestArray.push(interestMonthly)

			if (toggleTex) {

				if (i % 12 == 0) {
					sI = i
					eI = i + 1
					exemptedForCurrentYear = false
				}

				let currInterest = parseFloat(getAccSum(monthlyInterestArray.slice(sI, eI)).toFixed(3))
				// console.log('currInterest', currInterest, sI, eI, i)

				let taxAbleAmount = 0
				if (!exemptedForCurrentYear) {
					if (currInterest > parseFloat(calculations.annualTexAllowance)) {
						taxAbleAmount = currInterest - parseFloat(calculations.annualTexAllowance)
						exemptedForCurrentYear = true
						// console.log('i', i)
						sI = i + 1
					}
					eI++
				} else {
					taxAbleAmount = currInterest
					sI = i + 1
					eI = i + 2

				}
				const currentMonthTax = parseFloat((taxAbleAmount * (parseFloat(calculations.texRate) / 100)).toFixed(3))
				console.log('currentMonthTax', currentMonthTax)
				monthlyTaxArray.push(currentMonthTax)

			}

			monthlyTaxAllowanceArray.push(calculations.annualTexAllowance)
			monthlyBalanceArray.push(balance)


			// if (toggleTex) {
			// 	if(i % 12 ===  0)
			// 	let currInterest = getAccSum(monthlyInterestArray)
			// }

		}



		// console.log('monthlySavings', monthlySavingsArray)
		console.log('monthlyBalanceArray', monthlyBalanceArray)
		console.log('monthlyInterestArray', monthlyInterestArray)
		console.log('monthlyTaxArray', monthlyTaxArray)

		const totalSaving = getAccSum(monthlySavingsArray)
		const totalDeposit = monthlyBalanceArray.length ? monthlyBalanceArray[monthlyBalanceArray.length - 1] : 0

		const totalInterest = parseFloat(getAccSum(monthlyInterestArray).toFixed(3))
		console.log('totalInterest', totalInterest)
		const totalTax = parseFloat(getAccSum(monthlyTaxArray).toFixed(3))
		console.log('totalInterest', totalTax)

		const savingsArray = getAnnualInterest(monthlySavingsArray, durationInMonths)
		const balanceArray = getSavingsArray(monthlyBalanceArray, durationInMonths)
		const interestArray = getAnnualInterest(monthlyInterestArray, durationInMonths)
		const taxAllowanceArray = getSavingsArray(monthlyTaxAllowanceArray, durationInMonths)
		const taxAmountArray = getSavingsArray(monthlyTaxArray, durationInMonths)
		// console.log('savings', savings)

		const inflationFactor = (parseFloat(calculations.inflation) / 100) * (totalDeposit + totalInterest) * durationInYears
		const finalCapitalAfterInflation = calculations.inflation === "" ? 0 : (totalDeposit + totalInterest - inflationFactor).toFixed(3)
		return finalCapitalAfterInflation
	}
	const calculateInterestRate = () => {
		const r =
			calculations.interestRate === ""
				? 0
				: Number(calculations.interestRate) / 100;
		const n = Number(calculations.duration);
		const futureValue =
			Number(calculations.initialCapital) * Math.pow(1 + r, n);
		setResults({
			finalCapital: "",
			totalInterest: "150",
			totalTex: 0,
			finalInterestRate: "",
			finalInitialValue: "",
			finalDuration: "",
			finalSavingRate: "5",
		});
	};
	const calculateSavingRate = () => {
		const r =
			calculations.interestRate === ""
				? 0
				: Number(calculations.interestRate) / 100;
		const n = Number(calculations.duration);
		const futureValue =
			Number(calculations.initialCapital) * Math.pow(1 + r, n);
		setResults({
			finalCapital: "",
			totalInterest: "23234",
			totalTex: 0,
			finalInterestRate: "",
			finalInitialValue: "",
			finalDuration: "",
			finalSavingRate: "1000",
		});
	};
	const calculateDuration = () => {
		const savingInterval = Number(calculations.savingInterval);

		const totalSavingValue =
			Number(calculations.duration) *
			Number(calculations.savingRate) *
			savingInterval;
		const totalDepositValue =
			Number(calculations.initialCapital) + totalSavingValue;
		const r =
			calculations.interestRate === ""
				? 0
				: Number(calculations.interestRate) / 100;
		const n = Number(calculations.duration);
		const futureValue = Number(results.finalCapital);

		const compoundingFrequency =
			calculations.durationType === "months" ? 12 : 1;

		const totalInterest =
			futureValue - Number(calculations.initialCapital) / compoundingFrequency;

		if (!toggleTex) {
			setResults({
				finalCapital: "",
				totalInterest: "150",
				totalTex: "20",
				finalInterestRate: "",
				finalInitialValue: "",
				finalDuration: "7",
				finalSavingRate: "",
			});
		} else {
			const taxRate =
				Number(calculations.texRate) > 0
					? Number(calculations.texRate) / 100
					: 1;

			if (calculations.annualTexAllowance === "") {
				const taxAmount = totalInterest * taxRate;
				const afterTaxTotal = futureValue - taxAmount;
				const afterTexInterest = totalInterest - taxAmount;
				setResults({
					finalCapital: "",
					totalInterest: "150",
					totalTex: "20",
					finalInterestRate: "",
					finalInitialValue: "",
					finalDuration: "7",
					finalSavingRate: "",
				});
			} else {
				const taxAbleAmount =
					totalInterest - Number(calculations.annualTexAllowance);
				const taxAmount = taxAbleAmount * taxRate;
				const afterTaxTotal = futureValue - taxAmount;
				const afterTexInterest = totalInterest - taxAmount;
				setResults({
					finalCapital: "",
					totalInterest: "150",
					totalTex: "20",
					finalInterestRate: "",
					finalInitialValue: "",
					finalDuration: "7",
					finalSavingRate: "",
				});
			}
		}
	};

	const calculateFinalValue = () => {

		const durationInYears = calculations.durationType === "years" ? calculations.duration : calculations.duration / 12;
		const durationInMonths = durationInYears * 12


		// const monthlySavings = {}
		const monthlySavingsArray = []
		const monthlyBalanceArray = []
		const monthlyInterestArray = []
		const monthlyTaxArray = []
		const monthlyTaxAllowanceArray = []

		let sI = 0, eI = 0, exemptedForCurrentYear = false

		for (let i = 0; i < durationInMonths; i++) {
			const currentMonthSaving = getCurrentMonthSaving(parseFloat(calculations.savingRate), i, calculations.savingInterval)

			monthlySavingsArray.push(currentMonthSaving)

			const accSaving = getAccSum(monthlySavingsArray)

			const balance = parseFloat(calculations.initialCapital) + accSaving

			const accInterest = getAccSum(monthlyInterestArray)

			let totalBalance = balance
			if (calculations.compoundInterest === 'compound') {
				totalBalance = balance + accInterest
			}
			// console.log('taxPrevious', parseFloat((totalBalance - (monthlyTaxArray[i - 1])).toFixed(3)))
			totalBalance = parseFloat((totalBalance - (monthlyTaxArray[i - 1] || 0)).toFixed(3))
			const interestMonthly = getCurrentMonthInterest(totalBalance, calculations.interestRate, i, calculations.savingInterval)
			monthlyInterestArray.push(interestMonthly)

			if (toggleTex) {

				if (i % 12 == 0) {
					sI = i
					eI = i + 1
					exemptedForCurrentYear = false
				}

				let currInterest = parseFloat(getAccSum(monthlyInterestArray.slice(sI, eI)).toFixed(3))
				// console.log('currInterest', currInterest, sI, eI, i)

				let taxAbleAmount = 0
				if (!exemptedForCurrentYear) {
					if (currInterest > parseFloat(calculations.annualTexAllowance)) {
						taxAbleAmount = currInterest - parseFloat(calculations.annualTexAllowance)
						exemptedForCurrentYear = true
						// console.log('i', i)
						sI = i + 1
					}
					eI++
				} else {
					taxAbleAmount = currInterest
					sI = i + 1
					eI = i + 2

				}
				const currentMonthTax = parseFloat((taxAbleAmount * (parseFloat(calculations.texRate) / 100)).toFixed(3))
				console.log('currentMonthTax', currentMonthTax)
				monthlyTaxArray.push(currentMonthTax)

			}

			monthlyTaxAllowanceArray.push(calculations.annualTexAllowance)
			monthlyBalanceArray.push(balance)


			// if (toggleTex) {
			// 	if(i % 12 ===  0)
			// 	let currInterest = getAccSum(monthlyInterestArray)
			// }

		}



		// console.log('monthlySavings', monthlySavingsArray)
		console.log('monthlyBalanceArray', monthlyBalanceArray)
		console.log('monthlyInterestArray', monthlyInterestArray)
		console.log('monthlyTaxArray', monthlyTaxArray)

		const totalSaving = getAccSum(monthlySavingsArray)
		const totalDeposit = monthlyBalanceArray.length ? monthlyBalanceArray[monthlyBalanceArray.length - 1] : 0

		const totalInterest = parseFloat(getAccSum(monthlyInterestArray).toFixed(3))
		console.log('totalInterest', totalInterest)
		const totalTax = parseFloat(getAccSum(monthlyTaxArray).toFixed(3))
		console.log('totalInterest', totalTax)

		const savingsArray = getAnnualInterest(monthlySavingsArray, durationInMonths)
		const balanceArray = getSavingsArray(monthlyBalanceArray, durationInMonths)
		const interestArray = getAnnualInterest(monthlyInterestArray, durationInMonths)
		const taxAllowanceArray = getSavingsArray(monthlyTaxAllowanceArray, durationInMonths)
		const taxAmountArray = getSavingsArray(monthlyTaxArray, durationInMonths)
		// console.log('savings', savings)

		const inflationFactor = (parseFloat(calculations.inflation) / 100) * (totalDeposit + totalInterest) * durationInYears
		const finalCapitalAfterInflation = calculations.inflation === "" ? 0 : (totalDeposit + totalInterest - inflationFactor).toFixed(3)

		setResults({
			finalCapital: totalDeposit + totalInterest,
			finalCapitalAfterInflation,
			totalDeposit: totalDeposit,
			totalInterest: totalInterest,
			totalTex: totalTax,
			savingsArray,
			balanceArray,
			interestArray,
			taxAllowanceArray,
			taxAmountArray,

			finalInterestRate: "",
			finalInitialValue: "",
			finalDuration: "",
			finalSavingRate: "",
		});

	}
	const getAccSum = (arr) => {
		return arr.reduce((accumulator, currentValue) => (accumulator) + (currentValue), 0);;
	}
	const getCurrentMonthInterest = (amount, interestRate, month, savingInterval) => {
		let duration = 1
		let mod = 1
		if (savingInterval == 'monthly') {
			duration = (1 / 12)
			mod = 1
		}
		if (savingInterval == 'quarterly') {
			duration = (0.25)
			mod = 3
		}
		if (savingInterval == 'half-yearly') {
			duration = (0.5)
			mod = 6
		}
		if (savingInterval == 'yearly') {
			duration = (1)
			mod = 12
		}

		if (month % mod == 0)
			return parseFloat((amount * (interestRate / 100) * duration).toFixed(3))
		else
			return 0
	}
	const getCurrentMonthSaving = (savingRate, month, savingInterval) => {

		if (month == 0) {
			return savingRate
		}

		const m = month
		if (savingInterval == 'monthly') {
			return savingRate
		}
		else if (savingInterval == 'quarterly') {
			if (m % 3 === 0) {
				return savingRate
			} else {
				return 0
			}
		}
		else if (savingInterval == 'half-yearly') {
			if (m % 6 === 0) {
				return savingRate
			} else {
				return 0
			}
		}
		else if (savingInterval == 'yearly') {
			if (m % 12 === 0) {
				return savingRate
			} else {
				return 0
			}
		} else {

			return 0

		}
	}

	const getAnnualInterest = (arr, months) => {
		if (months <= 12) {
			return arr
		}
		const finalArray = []

		for (let i = 1; i <= arr.length; i++) {
			if (i % 12 === 0) {
				// console.log('array', (arr.slice(i - 12, i)))
				const sum = parseFloat(getAccSum(arr.slice(i - 12, i)).toFixed(3))
				finalArray.push(sum)
			}
		}
		return finalArray
	}
	const getSavingsArray = (arr, months) => {
		if (months <= 12) {
			return arr
		}
		const finalArray = []

		for (let i = 1; i <= arr.length; i++) {
			if (i % 12 === 0) {
				finalArray.push(arr[i - 1])
			}
			// }
		}
		return finalArray

	}
	const getSavingsArrayOld = (arr, interval, months) => {
		if (interval == 'monthly') {
			return arr
		}
		const finalArray = []

		for (let i = 0; i < arr.length; i++) {
			if (interval === 'quarterly') {
				if (i % 3 === 0) {
					finalArray.push(arr[i])
				}
			}
			if (interval === 'half-yearly') {
				if (i % 6 === 0) {
					finalArray.push(arr[i])
				}
			}
			if (interval === 'yearly') {
				if (i % 12 === 0) {
					finalArray.push(arr[i])
				}
			}
		}
		return finalArray

	}


	const calculateFinalValueO = () => {
		// interest rate in %
		const r = calculations.interestRate / 100;

		//  total duration in years
		const durationInYears = calculations.durationType === "years" ? calculations.duration : calculations.duration / 12;


		// total saving intervals
		const T = calculations.savingInterval * durationInYears;



		// to calculate the tax amount and tax
		var totalTax = 0;

		//  total interest
		var finalValue = 0;

		var initialValue = Number(calculations.initialCapital) + Number(calculations.savingRate);



		for (let i = 1; i <= Math.ceil(durationInYears); i++) {

			const newDuration =
				calculations.durationType === "years"
					? 1
					: durationInYears < 1
						? durationInYears
						: i === 1
							? 1
							: durationInYears % 1 === 0
								? 1
								: durationInYears - (i - 1) < 1
									? durationInYears % 1
									: !Number.isInteger(durationInYears - (i - 1))
										? 1
										: 0;

			// let newDuration;

			// if (calculations.durationType === "years") {
			// 	newDuration = 1;
			// } else if (durationInYears < 1) {
			// 	newDuration = durationInYears;
			// } else if (i === 1) {
			// 	newDuration = 1;
			// } else if (durationInYears % 1 === 0) {
			// 	newDuration = 1;
			// } else if (durationInYears - (i - 1) < 1) {
			// 	newDuration = durationInYears % 1;
			// } else if (!Number.isInteger(durationInYears - (i - 1))) {
			// 	newDuration = 1;
			// } else {
			// 	newDuration = 0;
			// }


			// let newDuration;
			// if (calculations.durationType === "years") {
			// 	newDuration = 1
			// } else {
			// 	newDuration = durationInYears < 1
			// 		? durationInYears
			// 		: i === 1
			// 			? 1
			// 			: durationInYears % 1 === 0
			// 				? 1
			// 				: durationInYears - (i - 1) < 1
			// 					? durationInYears % 1
			// 					: !Number.isInteger(durationInYears - (i - 1))
			// 						? 1
			// 						: 0;

			// }

			// compound interest
			const ci = initialValue * Math.pow(1 + r, newDuration) - initialValue;


			// simple interest
			const si = calculations.initialCapital * r * calculations.duration;

			const finalInterest = calculations.compoundInterest === "compound" ? ci : si;

			let deposit = calculations.savingRate * calculations.savingInterval


			if (dynamicValue) {
				if (i <= calculations.dynamicsValue) {
					const dynamicsRate = Number(calculations.dynamics) / 100;
					const dynamicValue = Number(calculations.savingRate) * dynamicsRate;

					initialValue =
						calculations.compoundInterest === "compound"
							? initialValue +
							ci +
							Number(calculations.savingRate) +
							dynamicValue
							: initialValue + Number(calculations.savingRate) + dynamicValue;
				} else {
					initialValue =
						calculations.compoundInterest === "compound"
							? initialValue + ci + Number(calculations.savingRate)
							: initialValue + Number(calculations.savingRate);
				}
			} else {
				initialValue =
					calculations.compoundInterest === "compound"
						? initialValue + ci + Number(calculations.savingRate)
						: initialValue + Number(calculations.savingRate);
			}

			finalValue = finalInterest;
			// to calculate the tax amount and tax
			if (finalInterest > 1000) {
				// to find taxable amount
				const ta = finalInterest - 1000;
				// tax rate
				const tr = calculations.texRate === "" ? 0 : calculations.texRate / 100;
				// find the tax on taxable amount
				const tax = ta * tr;
				totalTax = tax + totalTax;
			}


		}



		// to find the future value
		const fv = Number(calculations.initialCapital) + finalValue + calculations.savingRate * T - totalTax * finalValue;



		// total deposit
		const td = Number(calculations.initialCapital) + Number(calculations.savingRate) * T;



		// total interest received
		const ti = fv - td;



		// set the calculated values in the state
		setResults({
			finalCapital: fv.toFixed(3),
			totalDeposit: td,
			totalInterest: ti.toFixed(3),
			finalInterestRate: "",
			finalInitialValue: "",
			finalDuration: "",
			finalSavingRate: "",
		});
	};

	const onPressCalculate = () => {
		setCount(count + 1);
		setChartShow(true);
		// calculateFinalValue();
		toFind.findInitialCapital
			? calculateInitialCapital()
			: toFind.findSavingRate
				? calculateSavingRate()
				: toFind.findInterestRate
					? calculateInterestRate()
					: toFind.findDuration
						? calculateDuration()
						: calculateFinalValue();
	};
	const handleSelectChange = (event) => {
		const value = event.target.value;
		const name = event.target.name;
		setCalculations({
			...calculations,
			[name]: value,
		});
	};

	return (
		<>
			<section className="banner-sec">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-md-7 col-lg-6 mb-5 mb-md-0">
							<h1 className="text-white fw-500 text-capitalize">
								{t("savingsCalculator.title")}
							</h1>
							<p className="text-white mt-3">{t("savingsCalculator.des")}</p>
						</div>
						<div className="col-md-5">
							<img
								className="d-block mx-auto w-100 mx-md"
								src={BannerImage}
								alt="bannerImage"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="household-sec">
				<div className="container">
					<div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
						<div className="col-md-6 col-lg-6">
							<img
								className="d-block mx-auto w-100 mx-md"
								src={Household}
								alt="img not found"
							/>
						</div>
						<div className="col-md-6 col-lg-5">
							<h2 className="fs-32  text-green">
								{t("savingsCalculator.howToUse")}
							</h2>
							<ul className="list-style mt-3">
								<li className="p-heading">{t("savingsCalculator.li1")}</li>
								<li className="p-heading">{t("savingsCalculator.li2")}</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<section className="budget-reminder bg-light-green py-4">
				<div className="container">
					<div className="row align-items-center justify-content-center py-5">
						<div className="col-12 col-lg-10">
							<div className="row align-items-center justify-content-between">
								<div className="col-md-6 col-lg-5">
									<h2 className="sub-heading text-green">
										<span className="text-dark-green d-block">
											{t("savingsCalculator.title1")}
										</span>
										{/* {t("savingsCalculator.title2")} */}
									</h2>

									<Form.Control
										className="mt-3"
										type="text"
										placeholder="Email-Adresse / Telefonnummer"
										name="initialCapital"
										value={calculations.initialCapital}
										onChange={onChangeHandler}
									/>
									<Button className="mt-4 bg-green px-3 py-2 text-white rounded-pill border-0 fw-bold">
										{t("savingsCalculator.button")}
									</Button>
								</div>
								<div className="col-md-6 col-lg-6 mt-md-0 mt-5">
									<img
										className="d-block mx-auto w-100 mx-md"
										src={Calendar}
										alt="img not found"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="monthly-calculation pt-5 pb-2">
				<div className="container">
					<span className="text-green text-start text-md-center d-block fw-500">
						{t("calculator")}
					</span>
					<h3 className="body-heading text-start text-md-center">
						{t("savingCalculator")}
					</h3>

					<div className="row bg-green py-4 px-2 radius-24 mx-lg-5 mt-4">
						<div className="col-md-6  mb-3 mb-md-0 ">
							<div className="bg-light-green radius-24 h-100 px-4 py-4">
								<h5 className="text-green text-left fw-bold text-capitalize mb-3">
									{t("summary")}
								</h5>
								<Form>
									<Form.Group className="mb-3">
										<div className="col-md-12 d-flex justify-content-between">
											<Form.Label>
												{t("savingsCalculator.initial_capital")}:
											</Form.Label>
											<Form.Check
												onClick={() => selectToFind("initialCapital")}
												className="switch-toggle ms-3"
												type="switch"
												checked={toFind.findInitialCapital}
												id="custom-switch"
											/>
										</div>
										{toFind.findInitialCapital ? (
											<Form.Control
												className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
												type="number"
												placeholder="5.000€"
												name="initialCapital"
												value={calculations.initialCapital}
												onChange={onChangeHandler}
												readOnly
											/>
										) : (
											<Form.Control
												className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
												type="number"
												placeholder="5.000€"
												name="initialCapital"
												value={calculations.initialCapital}
												onChange={onChangeHandler}
											/>
										)}
									</Form.Group>
									<Form.Group className="mb-3">
										<div className="col-md-12 d-flex justify-content-between">
											<Form.Label>
												{t("savingsCalculator.savingsRate")}:
											</Form.Label>
											<Form.Check
												onClick={() => selectToFind("savingRate")}
												className="switch-toggle ms-3"
												type="switch"
												checked={toFind.findSavingRate}
												id="custom-switch"
											/>
										</div>
										<div className="position-relative">
											{toFind.findSavingRate ? (
												<Form.Control
													className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
													type="number"
													placeholder="200€"
													name="savingRate"
													value={calculations.savingRate}
													onChange={onChangeHandler}
													readOnly
												/>
											) : (
												<Form.Control
													className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
													type="number"
													placeholder="200€"
													name="savingRate"
													value={calculations.savingRate}
													onChange={onChangeHandler}
												/>
											)}
										</div>
									</Form.Group>
									<div className="col-md-12 d-flex justify-content-between">
										<Form.Label>
											{t("savingsCalculator.savingInterval")}:
										</Form.Label>
										{/* <Form.Check
                      onClick={() => selectToFind("savingInterval")}
                      className="switch-toggle ms-3"
                      type="switch"
                      checked={toFind.findSavingInterval}
                      id="custom-switch"
                    /> */}
									</div>
									<Form.Select
										name="savingInterval"
										className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
										aria-label="Default select example"
										onChange={handleSelectChange}
									>
										<option value="monthly">{t("savingsCalculator.monthly")}</option>
										<option value="quarterly">{t("savingsCalculator.quarterly")}</option>
										<option value="half-yearly">{t("savingsCalculator.half-yearly")}</option>
										<option value="yearly">{t("savingsCalculator.yearly")}</option>
									</Form.Select>

									<div className="col-md-12 d-flex justify-content-between">
										<Form.Label>
											{t("savingsCalculator.depositType")}:
										</Form.Label>
									</div>
									<Form.Select
										name="depositType"
										className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
										aria-label="Default select example"
										onChange={handleSelectChange}
									>
										<option value="inAdvance">
											{t("savingsCalculator.inAdvance")}
										</option>
										<option value="inArrears">
											{t("savingsCalculator.inArrears")}
										</option>
									</Form.Select>

									<div className="d-flex items-center mb-3">
										<Form.Label> {t("savingsCalculator.dynamics")}:</Form.Label>
										<Form.Check
											onClick={() => setDynamicValue(!dynamicValue)}
											className="switch-toggle ms-3"
											type="switch"
											id="custom-switch"
										/>
									</div>
									{dynamicValue && (
										<>
											<Form.Group className="mb-3">
												<div className="position-relative">
													<Form.Control
														className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
														type="number"
														placeholder="1,0"
														name="dynamics"
														value={calculations.dynamics}
														onChange={onChangeHandler}
													/>
													<span className="eye-icon interest-rate text-green px-2 me-4">
														%Pa
													</span>
												</div>
											</Form.Group>
											<Form.Select
												name="dynamicsValue"
												className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
												aria-label="Default select example"
												onChange={handleSelectChange}
											>
												<option value="12">
													{t("savingsCalculator.yearly")}
												</option>
												<option value="12">
													{t("savingsCalculator.every2")}
												</option>
												<option value="4">
													{t("savingsCalculator.every3")}
												</option>
												<option value="4">
													{t("savingsCalculator.every4")}
												</option>
												<option value="4">
													{t("savingsCalculator.every5")}
												</option>
											</Form.Select>
										</>
									)}



									<div className="d-flex items-center mb-3">
										<Form.Label> {t("savingsCalculator.inflation")}:</Form.Label>
										<Form.Check
											onClick={() => setInflationValue(!inflationValue)}
											className="switch-toggle ms-3"
											type="switch"
											id="custom-switch"
										/>
									</div>
									{inflationValue && (
										<>
											<Form.Group className="mb-3">
												<div className="position-relative">
													<Form.Control
														className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
														type="number"
														placeholder="1,0"
														name="inflation"
														value={calculations.inflation}
														onChange={onChangeHandler}
													/>
													<span className="eye-icon interest-rate text-green px-2 me-4">
														%Pa
													</span>
												</div>
											</Form.Group>
										</>
									)}




									<Form.Group className="mb-3">
										<div className="col-md-12 d-flex justify-content-between">
											<Form.Label>
												{t("savingsCalculator.interest_rate")}:
											</Form.Label>
											<Form.Check
												onClick={() => selectToFind("interestRate")}
												className="switch-toggle ms-3"
												type="switch"
												checked={toFind.findInterestRate}
												id="custom-switch"
											/>
										</div>
										<div className="position-relative">
											{toFind.findInterestRate ? (
												<Form.Control
													className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
													type="number"
													placeholder="1,0"
													name="interestRate"
													value={calculations.interestRate}
													onChange={onChangeHandler}
													readOnly
												/>
											) : (
												<Form.Control
													className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
													type="number"
													placeholder="1,0"
													name="interestRate"
													value={calculations.interestRate}
													onChange={onChangeHandler}
												/>
											)}
											<span className="eye-icon interest-rate text-green px-2 me-4">
												%Pa
											</span>
										</div>
									</Form.Group>

									<div className="col-md-12 d-flex justify-content-between">
										<Form.Label>
											{t("savingsCalculator.interestPeriod")}:
										</Form.Label>
									</div>
									<Form.Select
										name="interestPeriod"
										className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
										aria-label="Default select example"
										onChange={handleSelectChange}
									>
										<option value="12">{t("savingsCalculator.monthly")}</option>
										<option value="4">
											{t("savingsCalculator.quarterly")}
										</option>
										<option value="2">
											{t("savingsCalculator.half-yearly")}
										</option>
										<option value="1">{t("savingsCalculator.yearly")}</option>
									</Form.Select>
									<div className="col-md-12 d-flex justify-content-between">
										<Form.Label>
											{t("savingsCalculator.compoundInterest")}:
										</Form.Label>
									</div>
									<Form.Select
										name="compoundInterest"
										className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
										aria-label="Default select example"
										onChange={handleSelectChange}
									>
										<option value="notCompound">
											{t("noInterestAccumulation")}
										</option>
										<option value="compound">
											{t("yesInterestAccumulation")}
										</option>
									</Form.Select>

									<div className="row">
										<div className="col-md-12 d-flex justify-content-between">
											<Form.Label>{t("Duration")}:</Form.Label>
											<Form.Check
												onClick={() => selectToFind("duration")}
												className="switch-toggle ms-3"
												type="switch"
												checked={toFind.findDuration}
												id="custom-switch"
											/>
										</div>
										<div className="col-md-6">
											<Form.Select
												name="durationType"
												className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
												aria-label="Default select example"
												onChange={handleSelectChange}
											>
												<option value="years">{t("Years")}</option>
												<option value="months">{t("month")}</option>
											</Form.Select>
										</div>
										<div className="col-md-6">
											<Form.Group className="mb-3">
												<div className="position-relative">
													{toFind.findDuration ? (
														<Form.Control
															className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
															type="number"
															placeholder="7"
															name="duration"
															value={calculations.duration}
															onChange={onChangeHandler}
															readOnly
														/>
													) : (
														<Form.Control
															className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
															type="number"
															placeholder="7"
															name="duration"
															value={calculations.duration}
															onChange={onChangeHandler}
														/>
													)}
												</div>
											</Form.Group>
										</div>
									</div>
									<Form.Group className="mb-3">
										<div className="col-md-12 d-flex justify-content-between">
											<Form.Label>
												{t("savingsCalculator.finalCapital")}:
											</Form.Label>
											<Form.Check
												onClick={() => selectToFind("finalCapital")}
												className="switch-toggle ms-3"
												type="switch"
												checked={toFind.findFinalCapital}
												id="custom-switch"
											/>
										</div>
										{toFind.findFinalCapital ? (
											<Form.Control
												className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
												type="number"
												placeholder="€5,000.00"
												name="finalCapital"
												value={results.finalCapital}
												onChange={onChangeHandler}
												readOnly
											/>
										) : (
											<Form.Control
												className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
												type="number"
												placeholder="€5,000.00"
												name="finalCapital"
												value={results.finalCapital}
												onChange={onChangeHandler}
											/>
										)}
									</Form.Group>

									<div className="d-flex items-center mb-3">
										<Form.Label> {t("savingsCalculator.taxRate")}:</Form.Label>

										<Form.Check
											onClick={() => setToggleTex(!toggleTex)}
											className="switch-toggle ms-3"
											type="switch"
											id="custom-switch"
										/>
									</div>
									{toggleTex && (
										<>
											<Form.Group className="mb-3">
												<Form.Control
													className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
													type="number"
													placeholder="26,373%"
													name="texRate"
													value={calculations.texRate}
													onChange={onChangeHandler}
												/>
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label>
													{" "}
													{t("savingsCalculator.annualAllowance")}:
												</Form.Label>

												<Form.Control
													className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
													type="number"
													placeholder="€1,000.00"
													name="annualTexAllowance"
													value={calculations.annualTexAllowance}
													onChange={onChangeHandler}
												/>
											</Form.Group>
										</>
									)}

									<div className="d-flex items-center mb-3">
										<Form.Label>
											{" "}
											{t("savingsCalculator.beginningPDF")}:
										</Form.Label>

										<Form.Check
											onClick={() => setBeginningMonth(!beginningMonth)}
											className="switch-toggle ms-3"
											type="switch"
											id="custom-switch"
										/>
									</div>
									{beginningMonth && (
										<>
											<Form.Select
												className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
												aria-label="Default select example"
											>
												<option value="12">
													{t("savingsCalculator.yearly")}
												</option>
												<option value="12">
													{t("savingsCalculator.every2")}
												</option>
												<option value="4">
													{t("savingsCalculator.every3")}
												</option>
												<option value="4">
													{t("savingsCalculator.every4")}
												</option>
												<option value="4">
													{t("savingsCalculator.every5")}
												</option>
											</Form.Select>
											<Form.Group className="mb-3">
												<Form.Control
													className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
													type="number"
													placeholder="€1,000.00"
													name="initialCapital"
													value={calculations.initialCapital}
													onChange={onChangeHandler}
												/>
											</Form.Group>
										</>
									)}
									<Button
										className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
										onClick={() => onPressCalculate()}
									>
										{t("calculate")}
									</Button>
								</Form>
							</div>
						</div>
						<div className="col-md-6 mb-3 mb-md-0 ">
							<div className="bg-light-green radius-24 py-3 h-100 position-relative">
								<div className="position-absolute end-0 top-0 me-2 mt-2 ">
									<img className="cursor-pointer" src={PdfIcon} alt="icon" />
								</div>
								<h5 className="text-green text-center fw-bold text-capitalize mb-3">
									{t("savingsCalculator.Summary")}:
								</h5>
								{/* <div className="row justify-content-between align-items-center pt-3 pb-4">
									<div className="col-11 col-md-9 rounded-pills w-75 w-md-100 d-flex justify-content-center bg-white radius-24 mx-auto pt-3 pb-2 mb-3">
										<div>
											<h6 className="text-green text-center fw-normal mb-0">
												{calculations?.text !== ""
													? t(calculations?.text)
													: t("savingsCalculator.calculatedFinalCapital")}
											</h6>
											<h2 className="fs-1 fw-bold text-green text-center mb-0">
												{results?.finalCapital !== ""
													? results?.finalCapital
													: results?.finalDuration !== ""
														? results?.finalDuration
														: results?.finalInitialValue !== ""
															? results?.finalInitialValue
															: results?.finalInterestRate !== ""
																? results?.finalInterestRate
																: results?.finalSavingRate}{" "}
												{results.finalInterestRate !== "" ? "%" : "€"}
											</h2>
										</div>
									</div>
									
									<div className="col-6 border-right d-flex justify-content-center text-center">
										<div>
											<p className="text-green fs-5 mb-0">
												{t("savingsCalculator.totalInterest")}:
											</p>
											<h2 className="fw-bold text-green mb-0">
												{results.totalInterest} €
											</h2>
										</div>
									</div>
									
									<div className="col-6 d-flex justify-content-center text-center">
										<div>
											<p className="text-green fs-5 mb-0">
												{t("savingsCalculator.totalDeposits")}:
											</p>
											<h2 className="fw-bold text-green mb-0">
												{results.totalDeposit} €
											</h2>
										</div>
									</div>


									{toggleTex && (
										<div className="col-12 d-flex justify-content-center text-center mt-5">
											<div>
												<p className="text-green fs-5 mb-0">
													{t("savingsCalculator.totalTaxDeductions")}:
												</p>
												<h2 className="fw-bold text-green mb-0">
													{results.totalTex === "" ? 0 : results.totalTex} €
												</h2>
											</div>
										</div>
									)}
									{inflationValue && (
										<div className="col-12 d-flex justify-content-center text-center mt-5">
											<div>
												<p className="text-green fs-5 mb-0">
													{t("savingsCalculator.finalCapitalAfterInflation")}:
												</p>
												<h2 className="fw-bold text-green mb-0">
													{results.finalCapitalAfterInflation} €
												</h2>
											</div>
										</div>
									)}
								</div> */}
								<div className="row mb-4 px-3">
									<div className="col-md-6 col-lg-4 mb-2 px-1">
										<div className=" bg-success py-2 rounded-pill text-center">
											<h6 className="text-light m-0 fw-bold">
												{results?.finalCapital !== ""
													? results?.finalCapital
													: results?.finalDuration !== ""
														? results?.finalDuration
														: results?.finalInitialValue !== ""
															? results?.finalInitialValue
															: results?.finalInterestRate !== ""
																? results?.finalInterestRate
																: results?.finalSavingRate}{""}
												{results.finalInterestRate !== "" ? "%" : "€"}
											</h6>
											<span className="text-light m-0">{t("finalCapital")}</span>
										</div>
									</div>
									<div className="col-md-4">
										<div
											style={{ backgroundColor: "#f16880" }}
											className=" py-2 rounded-pill text-center"
										>
											<h6 className="text-light m-0 fw-bold">
												{results.totalDeposit}€
											</h6>
											<span className="text-light m-0">{t("totalDeposit")}</span>
										</div>
									</div>
									<div className="col-md-4 mv-10">
										<div
											style={{ backgroundColor: "#2d3d30" }}
											className=" py-2 rounded-pill text-center"
										>
											<h6 className="text-light m-0 fw-bold">
												{results.totalInterest}€
											</h6>
											<span className="text-light m-0">{t("interestReceived")}</span>
										</div>
									</div>
									{/* <div className="col-md-6 col-lg-4 px-1 mb-2">
              <div
                style={{ backgroundColor: "#23bca1" }}
                className=" py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €
                  {results?.interestReceived
                    ? (results?.interestReceived - results?.taxAmount).toFixed(
                        2
                      )
                    : 0}
                </h6>
                <span className="text-light m-0">{t("totalGain")}</span>
              </div>
            </div> */}
									{toggleTex && (
										<>
											<div className="col-md-6 col-lg-4 mb-2 px-1">
												<div className="taxAmount bg-danger py-2 rounded-pill text-center">
													<h6 className="text-light m-0 fw-bold">
														{results.totalTex === "" ? 0 : results.totalTex}€
													</h6>
													<span className="text-light m-0">{t("taxAmount")}</span>
												</div>
											</div>
											<div className="col-md-6 col-lg-4 mb-2 px-1">
												<div className=" bg-info py-2 rounded-pill text-center">
													<h6 className=" text-light m-0 fw-bold">
														{calculations?.annualTexAllowance}€
													</h6>
													<span className="text-light m-0">{t("taxAllowance")}</span>
												</div>
											</div>
										</>
									)}
									{inflationValue && (
										<div className="col-md-6 col-lg-4 mb-2 px-1">
											<div
												style={{ backgroundColor: "#50C878" }}
												className="taxAmount  py-2 rounded-pill text-center">
												<h6 className="text-light m-0 fw-bold">
													{results.finalCapitalAfterInflation}€
												</h6>
												<span className="text-light m-0">{t("savingsCalculator.finalCapitalAfterInflation")}</span>
											</div>
										</div>
									)}
								</div>


								<div >
									{!chartShow && (
										<img
											className="d-block mx-auto w-100 mx-md mt-10"
											src={FixedCalculator}
											alt="img"
										/>
									)}
									{chartShow && (
										<SavingCalculatorChart
											onClick={() => setChartShow(false)}
											results={results}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Blogs />
			<CalculationsPopup count={count} setCount={setCount} />
			{/* <FinancialGoal /> */}
			<StayInformed />
			{showCookies && <Cookies showCookies={setShowCookies} />}
		</>
	);
};

export default SavingCalculator;
