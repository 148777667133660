import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import Cross from "../assets/images/close-circle-icon.svg";
import { useTranslation } from "react-i18next";

const FixDepositChart = (props) => {
  const { t } = useTranslation();
  const { onClick, results } = props;

  const yearlyDeposit = results?.yearlyResults?.map((r) => r?.initialCapital);
  const yearlyDepositNew = [];
  for (let i = 1; i <= yearlyDeposit.length; i++) {
    // Check if the index is a multiple of 5 (0-based index)
    if (i % 5 === 0) {
      // Push the element into the result array
      yearlyDepositNew.push(yearlyDeposit[i - 1]);
    }
    if (i === (yearlyDeposit.length)) {
      yearlyDepositNew.push(yearlyDeposit[i - 1]);
    }
  }
  const yearlyInterestAmount = results?.yearlyResults?.map(
    (r) => r?.interestAmount
  );
  const yearlyInterestAmountNew = [];
  for (let i = 1; i <= yearlyInterestAmount.length; i++) {
    // Check if the index is a multiple of 5 (0-based index)
    if (i % 5 === 0) {
      // Push the element into the result array
      yearlyInterestAmountNew.push(yearlyInterestAmount[i - 1]);
    }
    if (i === (yearlyInterestAmount.length)) {
      yearlyInterestAmountNew.push(yearlyInterestAmount[i - 1]);
    }
  }
  const yearlyNetTotal = results?.yearlyResults?.map((r) => r?.netTotal);
  const yearlyNetTotalNew = [];
  for (let i = 1; i <= yearlyNetTotal.length; i++) {
    // Check if the index is a multiple of 5 (0-based index)
    if (i % 5 === 0) {
      // Push the element into the result array
      yearlyNetTotalNew.push(yearlyNetTotal[i - 1]);
    }
    if (i === (yearlyNetTotal.length)) {
      yearlyNetTotalNew.push(yearlyNetTotal[i - 1]);
    }
  }
  const yearlyTaxAllowance = results?.yearlyResults?.map(
    (r) => r?.taxAllowance
  );
  const yearlyTaxAllowanceNew = [];
  for (let i = 1; i <= yearlyTaxAllowance.length; i++) {
    // Check if the index is a multiple of 5 (0-based index)
    if (i % 5 === 0) {
      // Push the element into the result array
      yearlyTaxAllowanceNew.push(yearlyTaxAllowance[i - 1]);
    }
    if (i === (yearlyTaxAllowance.length)) {
      yearlyTaxAllowanceNew.push(yearlyTaxAllowance[i - 1]);
    }
  }
  const yearlyTaxAmount = results?.yearlyResults?.map((r) => r?.taxAmount);

  const yearlyTaxAmountNew = [];
  for (let i = 1; i <= yearlyTaxAmount.length; i++) {
    // Check if the index is a multiple of 5 (0-based index)
    if (i % 5 === 0) {
      // Push the element into the result array
      yearlyTaxAmountNew.push(yearlyTaxAmount[i - 1]);
    }
    if (i === (yearlyTaxAmount.length)) {
      yearlyTaxAmountNew.push(yearlyTaxAmount[i - 1]);
    }
  }

  let totalCharts = [];
  const cLength =
    results?.yearlyResults?.length < 11 ? results?.yearlyResults?.length : 10;
  for (let i = 1; i <= results?.yearlyResults?.length; i++) {
    if (results?.yearlyResults?.length < 11) {

      totalCharts.push(`${i + 1}`);
    } else {
      if (i % 5 === 0) {
        totalCharts.push(`${i}`);

      }
    }
    if (i === results?.yearlyResults?.length) {
      totalCharts.push(`${i}`);
    }
  }

  const barSeries = {
    series: [
      {
        name: `${t("deposits")}`,
        data: yearlyDeposit.length <= 10 ? yearlyDeposit : yearlyDepositNew,
        color: "#f16880",
      },

      {
        name: `${t("taxAllowance")}`,
        data:
          yearlyDeposit.length <= 10 ? yearlyTaxAllowance : yearlyTaxAllowanceNew,
        color: "#03b2cb",
      },
      {
        name: `${t("taxAmount")}`,
        data: yearlyDeposit.length <= 10 ? yearlyTaxAmount : yearlyTaxAmountNew,
        color: "#ff0041",
      },
      {
        name: `${t("finalCapital")}`,
        data: yearlyDeposit.length <= 10 ? yearlyNetTotal : yearlyNetTotalNew,
        color: "#1a8754",
      },
      {
        name: `${t("interestCharges")}`,
        data:
          yearlyDeposit.length <= 10
            ? yearlyInterestAmount
            : yearlyInterestAmountNew,
        color: "#2d3d30",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
            plotOptions: {
              bar: {
                columnWidth: "10px",
              },
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15px",
          borderRadius: 1,
          dataLabels: {
            total: {
              enabled: true,
              formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                if (seriesIndex === barSeries.series.length - 1) {
                  const seriesData = w.globals.series[seriesIndex];
                  const total = seriesData[dataPointIndex];
                  return total; // Format the total value as needed
                }
                return val; // Return the original value for other series data labels
              },
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        type: "number",
        categories: totalCharts,
        scrollbar: {
          enabled: true,
        },
      },
      yaxis: {
        opposite: true,
        labels: {
          formatter: function (value) {
            // Custom formatting logic for y-axis labels
            return value.toFixed(3) + "";
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "left",
        // customLegendItems: [
        //   `${t("deposits")}`,
        //   `${t("taxAllowance")}`,
        //   `${t("taxAmount")}`,
        //   `${t("finalCapital")}`,
        //   `${t("interestCharges")}`,
        // ],
        markers: {
          fillColors: ["#f16880", "#03b2cb", "#198754", "#0dcbf0", "#2d3d30"],
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        offsetY: 0,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#f16880", "#03b2cb", "#198754", "#0dcbf0", "#2d3d30"],
    },
  };
  const hideChart = () => {
    ApexCharts.getChartByID("chart1").toggleSeries(`${t("deposits")}`);
  };

  return (
    <div>
      <div id="chart" className="position-relative">
        <div className="close-icon-chart position-absolute">
          <img onClick={onClick} src={Cross} alt="icon" />
        </div>
        {results.totalDeposit && (
          <div className="row mb-4 px-3">
            <div className="col-md-6 col-lg-4 mb-2 px-1">
              <div className=" bg-success py-2 rounded-pill text-center">
                <h6 className="text-light m-0 fw-bold">
                  €{results?.finalCapital ? results?.finalCapital : 0}
                </h6>
                <span className="text-light m-0">{t("finalCapital")}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div
                style={{ backgroundColor: "#f16880" }}
                className=" py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €{results?.totalDeposit ? results?.totalDeposit : 0}
                </h6>
                <span className="text-light m-0">{t("totalDeposit")}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div
                style={{ backgroundColor: "#2d3d30" }}
                className=" py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €{results?.interestReceived ? results?.interestReceived : 0}
                </h6>
                <span className="text-light m-0">{t("interestReceived")}</span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-1 mb-2">
              <div
                style={{ backgroundColor: "#23bca1" }}
                className=" py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €
                  {results?.interestReceived ? (results?.interestReceived - results?.taxAmount).toFixed(3) : 0}
                </h6>
                <span className="text-light m-0">{t("totalGain")}</span>
              </div>
            </div>
            {results.isTaxIncluded && (
              <>
                <div className="col-md-6 col-lg-4 mb-2 px-1">
                  <div className="taxAmount bg-danger py-2 rounded-pill text-center">
                    <h6 className="text-light m-0 fw-bold">
                      €{results?.taxAmount ? results?.taxAmount : null}
                    </h6>
                    <span className="text-light m-0">{t("taxAmount")}</span>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-2 px-1">
                  <div className=" bg-info py-2 rounded-pill text-center">
                    <h6 className=" text-light m-0 fw-bold">
                      €{results?.taxAllowance ? results?.taxAllowance : null}
                    </h6>
                    <span className="text-light m-0">{t("taxAllowance")}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <ReactApexChart
          options={barSeries.options}
          series={barSeries.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default FixDepositChart;
