import React from "react";
import ReactApexChart from "react-apexcharts";
import Cross from "../assets/images/close-circle-icon.svg";
import { useTranslation } from "react-i18next";

const Chart = (props) => {
  const { t } = useTranslation();
  const { onClick, results } = props;

  const barSeries = {
    series: [
      {
        name: "Deposit",
        color: "#000000",
        data: [
          results.totalDeposit
            ? results.totalDeposit
            : results.totalAchieved
            ? results.totalAchieved
            : 0,
        ],
      },
      {
        name: "Final Capital",
        color: "#FF7474",
        data: [
          results.finalCapital
            ? results.finalCapital
            : results.totalInterest
            ? results.totalInterest
            : 0,
        ],
      },
      {
        name: "Interest",
        color: "#00FF00",
        data: [
          results.interestReceived
            ? results.interestReceived
            : results.totalTex
            ? results.totalTex
            : 0,
        ],
      },
      {
        name: "Tax Amount",
        color: "#00FFFF",
        data: [results.taxAmount ? results.taxAmount : 0],
      },
      {
        name: "Tax Allowance",
        color: "#00FFCC",
        data: [results.taxAllowance ? results.taxAllowance : 0],
      },
    ],

    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "35px",
          endingShape: "rounded",
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "left",
        customLegendItems: ["Kapital", "Zinsen"],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["2023", "2024", "2025", "2026"],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val;
          },
        },
      },
    },
  };
  return (
    <div>
      <div id="chart" className="position-relative">
        <div className="close-icon-chart position-absolute">
          <img onClick={onClick} src={Cross} alt="icon" />
        </div>
        <ReactApexChart
          options={barSeries.options}
          series={barSeries.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default Chart;
