import BannerImage from "../assets/images/calculators-banner.svg";
import { Button } from "react-bootstrap";
import StayInformed from "../components/StayInformed";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GetAdd from "../components/GetAddPopup";
import { useEffect, useState } from "react";
import DisableCalculatorPop from "../components/DisabledCalculatorPop";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

const Calculators = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);

  const navigateTo = ({type,ready}) => {
    if (!ready) {
      setShowModal(true)
    } else {
      navigate(`/${type}`)
    }
    // type === "haushaltsrechner"
    //   ? navigate(`/${type}`)
    //   : type === "festgeldrechner"
    //   ? navigate(`/${type}`)
    //   : type === "sparrechner"
    //   ? navigate(`/${type}`)
    //   : setShowModal(true);
  };
  const btnStyle = {
    backgroundColor: "#c2bdbd",
    // cursor: 'pointer',
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Helmet>
        <title>Finanzrechner |Bester Finanzrechner| Finanzfaible </title>
        <meta
          name="description"
          content="Finanzfailbles Finanzrechner: Vereinfachen Sie komplexe Finanzentscheidungen. Ermitteln Sie mühelos Zahlen für eine sichere finanzielle Zukunft."
        />
        <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
        <meta name="keywords" content="Finanzrechner, Bester Finanzrechner" />
        <link rel="canonical" href="https://finanzfaible.com/rechner"/>
      </Helmet>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500 ">
                {t("calculatorsPage.mainHeading")}
              </h1>
              <p className="text-white mt-3">{t("calculatorsPage.mainDesc")}</p>
              <Button className="btn-100 get-started-btn bg-white text-green px-3 px-lg-5 py-2 py-lg-3 border-0 me-3 fw-bold mb-md-0 mb-3">
                {t("calculatorsPage.startNow")}
              </Button>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>

      <section className=" my-3 py-3 my-md-5 py-md-5">
        <div className="container">
          <span className="text-green text-start  text-md-center d-block fw-500">
            {t("calculatorsPage.calculators")}
          </span>
          <h3 className="body-heading text-start text-md-center">
            {t("calculatorsPage.efficientCalculators")}
          </h3>
          <div className="row mt-4">

            {/* calculator 1  */}

            <div className="col-12 col-md-6 col-xl-4">
              <div className="card-one text-center px-2 px-md-4">
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.interestOne-timeInvestments")}
                </h4>
                <p className="m-0 pb-3">
                  {t("calculatorsPage.interestOne-timeDescription")}
                </p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo({type:"festgeldrechner",ready:true})}
                >
                  {t("calculatorsPage.interestCalculator")}
                </Button>
              </div>
            </div>

            {/* calculator 2  */}
            <div className="col-12 col-md-6 col-xl-4">
              <div className="card-one text-center px-2 px-md-4">
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.monthlyCalculator")}
                </h4>
                <p className="m-0 pb-3">{t("calculatorsPage.householdDes")}</p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo({type:"haushaltsrechner",ready:true})}
                >
                  {t("calculatorsPage.householdCalculator")}
                </Button>
              </div>
            </div>

            {/* calculator 3  */}
            <div className="col-12 col-md-6 col-xl-4 ">
              <div
                // style={btnStyle}
                className="card-one text-center px-2 px-md-4"
              >
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.savingsRegularSavings")}
                </h4>
                <p className="m-0 pb-3">
                  {t("calculatorsPage.savingDescription")}
                </p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo({type:"sparrechner",ready:true})}
                >
                  {t("calculatorsPage.savingsCalculator")}
                </Button>
              </div>
            </div>

            {/* calculator 4  */}
            <div className="col-12 col-md-6 col-xl-4">
              <div
                // style={btnStyle}
                className="card-one text-center px-2 px-md-4"
              >
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.fundETFSavings")}
                </h4>
                <p className="m-0 pb-3">
                  {t("calculatorsPage.fundETFSavingsDescription")}
                </p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo({type:"fondsrechner",ready:true})}
                >
                  {t("calculatorsPage.fundCalculator")}
                </Button>
              </div>
            </div>

            {/* calculator 5  */}
            <div className="col-12 col-md-6 col-xl-4">
              <div
                // style={btnStyle}
                className="card-one text-center px-2 px-md-4"
              >
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.withdrawalCapitalAssets")}
                </h4>
                <p className="m-0 pb-3">
                  {t("calculatorsPage.withdrawalDescription")}
                </p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo({type:"Auszahlungsplan-Rechner",ready:true})}
                >
                  {t("calculatorsPage.withdrawalCalculator")}
                </Button>
              </div>
            </div>

            {/* calculator 6  */}
            <div className="col-12 col-md-6 col-xl-4">
              <div
                style={btnStyle}
                className="card-one text-center px-2 px-md-4"
              >
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.financialCalculator")}
                </h4>
                <p className="m-0 pb-3">
                  {t("calculatorsPage.financialDescription")}
                </p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo("finanzfreiheitsrechner")}
                >
                  {t("calculatorsPage.financialCalculatorBtn")}
                </Button>
              </div>
            </div>

            {/* calculator 7  */}
            <div className="col-12 col-md-6 col-xl-4">
              <div
                style={btnStyle}
                className="card-one text-center px-2 px-md-4"
              >
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.loans(annuity)")}
                </h4>
                <p className="m-0 pb-3">
                  {t("calculatorsPage.loansAnnuityDescription")}
                </p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo("lohnrechner")}
                >
                  {t("calculatorsPage.loanCalculator")}
                </Button>
              </div>
            </div>

            {/* calculator 8  */}
            <div className="col-12 col-md-6 col-xl-4">
              <div
                style={btnStyle}
                className="card-one text-center px-2 px-md-4"
              >
                <h4 className="text-green fw-600 mt-3 mb-4">
                  {t("calculatorsPage.wageSalaryCalculator")}
                </h4>
                <p className="m-0 pb-3">
                  {t("calculatorsPage.wageDescription")}
                </p>
                <Button
                  className="get-started-btn bg-transparent border-0 text-green fw-bold pt-4"
                  onClick={() => navigateTo("salary-Calculator")}
                >
                  {t("calculatorsPage.salaryCalculator")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StayInformed />
      <GetAdd show={show} setShow={setShow} type={type} />
      <DisableCalculatorPop
        setType={setType}
        showModal={showModal}
        setShowModal={setShowModal}
        setShow={setShow}
      />
    </>
  );
};

export default Calculators;
