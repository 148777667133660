import {createSlice} from '@reduxjs/toolkit';

export const categoriesSlice = createSlice({
  name: 'categoriesData',
  initialState: [],
  reducers: {
    categoriesDetails: (state, action) => {
      return action.payload;
    }
  },
});

export const {categoriesDetails} = categoriesSlice.actions;
export const categoriesData = state => state.categoriesData; 

export default categoriesSlice.reducer;
