import React, { useState } from "react";
import BannerImage from "../assets/images/loan-banner.svg";
import Household from "../assets/images/household-img.svg";
import Calendar from "../assets/images/calendar-img.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";

import { Button, Form } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";
import Chart from "../components/Chart";

const LoanCalculator = () => {
  const [isActive, setIsActive] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [dynamicValue, setDynamicValue] = useState(false);
  const [discountValue, setDiscountValue] = useState(false);
  const [closingValue, setClosingValue] = useState(false);
  const [beginningMonth, setBeginningMonth] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [chartShow, setChartShow] = useState(false);
  const [calculations, setCalculations] = useState({
    loanAmount: "",
    interestRate: "",
    duration: "",
  });
  const [results, setResults] = useState({
    rePaymentRate: "",
  });

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "loanAmount") {
      setCalculations({
        ...calculations,
        loanAmount: value,
      });
    }
    if (name === "interestRate") {
      setCalculations({
        ...calculations,
        interestRate: value,
      });
    }
    if (name === "duration") {
      setCalculations({
        ...calculations,
        duration: value,
      });
    }
  };

  const resultCalculator = () => {
    setChartShow(true);
    const r1 = calculations.interestRate / 100;
    const r2 = r1 / 12;
    const p = calculations.loanAmount;
    const n = calculations.duration;
    // const loanPaymentTotal = (p * r2) / (1 - Math.pow(1 + r2, -n));
    const repaymentRates =
      (p * r1 * Math.pow(1 + r1, n)) / Math.pow(1 + r1, n - 1);
    setResults({
      rePaymentRate: repaymentRates.toFixed(3),
    });
  };
  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500 text-capitalize">
                Loan calculator for installment loans as annuity loans
              </h1>
              <p className="text-white mt-3">
                For annuity loans (ie interest and repayment together in
                constant installments), the loan calculator calculates either
                the repayment rate, term, interest rate, remaining debt, loan
                amount or the optionally different final installment. In
                addition, the effective annual interest is calculated and a
                detailed repayment plan (PDF) is issued.
              </p>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={Household}
                alt="img not found"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <h2 className="heading-main text-green">
                How to use our Interest calculator
              </h2>
              <ul className="list-style mt-3">
                <li className="p-heading">
                  Enter the approximate amount in all fields that apply to your
                  household
                </li>
                <li className="p-heading">
                  Try rounding up to the nearest dollar amount, even though this
                  calculator accepts decimals (using either , or .)
                </li>
                <li className="p-heading">
                  You do not need to fill out all fields
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="budget-reminder bg-light-green py-4">
        <div className="container">
          <div className="row align-items-center justify-content-center py-5">
            <div className="col-12 col-lg-10">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-6 col-lg-5">
                  <h2 className="sub-heading text-green">
                    <span className="text-dark-green d-block">Get monthly</span>
                    budget reminder
                  </h2>

                  <Form.Control
                    className="mt-3"
                    type="text"
                    placeholder="email address/phone number"
                  />
                  <Button className="mt-4 bg-green px-3 py-2 text-white rounded-pill border-0 fw-bold">
                    Get Reminder
                  </Button>
                </div>
                <div className="col-md-6 col-lg-6 mt-md-0 mt-5">
                  <img
                    className="d-block mx-auto w-100 mx-md"
                    src={Calendar}
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="monthly-calculation pt-5 pb-2">
        <div className="container">
          <span className="text-green text-start text-md-center d-block fw-500">
            Calculator
          </span>
          <h3 className="body-heading text-start text-md-center">
            Interest calculator for one-time investments
          </h3>

          <div className="row bg-green py-4 px-2 radius-24 mx-lg-5 mt-4">
            <div className="col-md-6  mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 h-100 px-4 py-4">
                <h5 className="text-green text-left fw-bold text-capitalize mb-3">
                  Summary
                </h5>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Loan Amount:</Form.Label>
                    <Form.Control
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                      type="number"
                      name="loanAmount"
                      placeholder="€0"
                      value={calculations.loanAmount}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>

                  <div className="d-flex items-center mb-3">
                    <Form.Label> Handling fee:</Form.Label>

                    <Form.Check
                      onClick={() => setDynamicValue(!dynamicValue)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {dynamicValue && (
                    <>
                      <Form.Select
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                        aria-label="Default select example"
                      >
                        <option>Select Year</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                      <div className="d-block calculation-radio-btn mb-3">
                        <Form.Check
                          label="include in rate"
                          name="group1"
                          type="radio"
                          id="1"
                        />
                        <Form.Check
                          label="to be paid separately"
                          name="group1"
                          type="radio"
                          id="2"
                        />
                      </div>
                    </>
                  )}
                  <div className="d-flex items-center mb-3">
                    <Form.Label> Discount:</Form.Label>

                    <Form.Check
                      onClick={() => setDiscountValue(!discountValue)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {discountValue && (
                    <>
                      <Form.Group className="mb-3">
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="1,000"
                          />
                        </div>
                      </Form.Group>
                    </>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Interest rate:</Form.Label>
                    <div className="position-relative mb-2">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        name="interestRate"
                        placeholder="€0"
                        value={calculations.interestRate}
                        onChange={onChangeHandler}
                      />
                    </div>
                    <Form.Select
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                      aria-label="Default select example"
                    >
                      <option>Select Year</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Repayment rate:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        name=""
                        placeholder="€0"
                        value={results.rePaymentRate}
                        onChange={onChangeHandler}
                      />
                    </div>
                  </Form.Group>

                  <Form.Label>Rate interval:</Form.Label>
                  <Form.Select
                    className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                    aria-label="Default select example"
                  >
                    <option>Select Year</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                  <Form.Label>Payment type:</Form.Label>
                  <Form.Select
                    className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                    aria-label="Default select example"
                  >
                    <option>Select Year</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>

                  <div className="d-flex items-center mb-3">
                    <Form.Label> Different closing rate:</Form.Label>

                    <Form.Check
                      onClick={() => setClosingValue(!closingValue)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {closingValue && (
                    <>
                      <Form.Group className="mb-3">
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="1,000"
                          />
                        </div>
                      </Form.Group>
                    </>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Duration of installment payments:</Form.Label>
                    <div className="position-relative mb-2">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        name="duration"
                        placeholder="€0"
                        value={calculations.duration}
                        onChange={onChangeHandler}
                      />
                    </div>
                    <Form.Select
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                      aria-label="Default select example"
                    >
                      <option>Select Year</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Form.Group>

                  <div className="d-flex items-center mb-3">
                    <Form.Label> Grace period:</Form.Label>

                    <Form.Check
                      onClick={() => setBeginningMonth(!beginningMonth)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {beginningMonth && (
                    <>
                      <Form.Group className="mb-3">
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="1,000"
                          />
                        </div>
                      </Form.Group>
                      <Form.Select
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                        aria-label="Default select example"
                      >
                        <option>Select Year</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                      <div className="d-block calculation-radio-btn mb-3">
                        <Form.Label>
                          {" "}
                          Interest payment for grace period:
                        </Form.Label>

                        <Form.Check
                          label="include in rate"
                          name="group2"
                          type="radio"
                          id="1"
                        />
                        <Form.Check
                          label="to be paid separately"
                          name="group2"
                          type="radio"
                          id="2"
                        />
                      </div>
                    </>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>residual debt:</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                    </div>
                  </Form.Group>

                  <div className="d-flex items-center mb-3">
                    <Form.Label> Tax rate:</Form.Label>

                    <Form.Check
                      onClick={() => setToggle(!toggle)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {toggle && (
                    <>
                      <Form.Select
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                        aria-label="Default select example"
                      >
                        <option>Select Year</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                      <Form.Group className="mb-3">
                        <Form.Label> Annual tax allowance:</Form.Label>

                        <Form.Control
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                          type="number"
                          placeholder="€1,000.00"
                        />
                      </Form.Group>
                      <div className="d-block calculation-radio-btn mb-3">
                        <Form.Label>
                          Interest payment for grace period:
                        </Form.Label>

                        <Form.Check
                          label="Calendar year subtotals"
                          name="group3"
                          type="radio"
                          id="1"
                        />
                      </div>
                    </>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Calculation accuracy:</Form.Label>
                    <Form.Select
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                      aria-label="Default select example"
                    >
                      <option>Select Year</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Form.Group>

                  <Button
                    className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                    onClick={() => resultCalculator()}
                  >
                    Calculate
                  </Button>
                </Form>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 py-3 h-100 position-relative">
                <div className="position-absolute end-0 top-0 me-2 mt-2 ">
                  <img className="cursor-pointer" src={PdfIcon} alt="icon" />
                </div>
                <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                  Summary
                </h5>
                <div className="row justify-content-between align-items-center pt-3 pb-4">
                  <ResultCard
                    title="The required regular rate is:"
                    result={results.rePaymentRate}
                  />
                  <ResultCard
                    title="Total interest and fees:"
                    result={results.rePaymentRate}
                  />
                  <ResultCard
                    title="Total effort:"
                    result={results.rePaymentRate}
                  />
                  <ResultCard
                    title="Effective annual interest rate:"
                    result={results.rePaymentRate}
                  />
                </div>
                {chartShow && (
                  <Chart
                    onClick={() => setChartShow(false)}
                    results={results}
                  />
                )}
                {/* <h5 className="text-green text-center px-3 fw-bold text-capitalize mb-3">
                  About this calculation
                </h5>
                <div>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    This is the mobile view of the calculator. The default view
                    (desktop view) may also contain a history table. This is
                    also included in the PDF document. A permanent link created
                    in mobile view can also be opened on a PC in standard view
                    and vice versa.
                  </p>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    The interest calculator uses the input data to determine
                    either the required initial capital, the interest rate, the
                    term or the resulting final capital including interest.
                    Based on the output table, the interest calculator also
                    shows the course of the credit development and gives the
                    capital invested at the beginning of the year, the interest
                    credited at the end of the year, the previous sum of the
                    interest credited and the total capital at the end of the
                    respective year. The interest calculator allows you to
                    calculate with or without compound interest.
                  </p>
                  <p className="text-green text-start px-3 text-capitalize fw-normal fs-6">
                    If you calculate without compound interest, the capital
                    invested corresponds to the initial capital each year, since
                    the interest is not reinvested. If compound interest is
                    taken into account, on the other hand, the interest credited
                    is added to the invested capital every year and from then on
                    also bears interest. In the case of maturities that are not
                    a whole number of years, mixed interest is calculated if
                    linear interest is calculated over the course of the year.
                    This means that compound interest is initially used for the
                    whole year, but the last fraction of the year is compounded
                    with simple interest, as is normal bank practice for savings
                    deposits. In addition, a tax rate with an annual tax
                    exemption can be specified in the interest calculator to
                    take the new withholding tax into account.
                  </p>
                  <ul>
                    <li className="text-green fs-6 fw-medium">
                      quick start Guide
                    </li>
                    <li className="text-green fs-6 fw-medium">
                      quick start Guide
                    </li>
                    <li className="text-green fs-6 fw-medium">
                      {" "}
                      quick start Guide
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="money-saving bg-light-green my-5 py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-6">
              <span className="text-green text-start  text-md-center d-block fw-500">
                Saving
              </span>
              <h3 className="body-heading text-start text-md-center">
                Simple Ways to Save Money
              </h3>
              <ul className="list-style mt-4">
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue,
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar .
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue, eu mollis sapien condimentum sit
                  amet.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Blogs />
      {/* <FinancialGoal /> */}
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default LoanCalculator;
const ResultCard = ({ title, result }) => {
  return (
    <div className="col-11 col-md-9 rounded-pills w-75 w-md-100 d-flex justify-content-center bg-white radius-24 mx-auto pt-3 pb-2 mb-3">
      <div className="d-flex align-items-center justify-content-between  w-100 ">
        <h6 className="text-green  text-capitalize fw-normal mb-0 mr-2">
          {title}
        </h6>
        <h2 className=" text-black fs-6 text-center  d-flex align-items-center mt-2 ">
          {result} €
        </h2>
      </div>
    </div>
  );
};
