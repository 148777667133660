import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PostRequest } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { userData } from "../redux/favouriteDataSlice";

const StayInformed = () => {
  const { t } = useTranslation();
  const userLang = useSelector(userData);
  const [email, setEmail] = useState("");

  const subscribeHandler = () => {
    const lang = userLang.lang === "" ? "de" : userLang.lang
    const data = {
      email: email,
      type:"newsletter",
      lang: lang,
    };

    email === ""
      ? toast.error("please add Email")
      : PostRequest(data, "api/join/newsletter").then((res) => {
          toast.success(res.data.message);
          setEmail("");
        });
  };

  return (
    <section className="news-letter bg-light-green">
      <div className="container">
        <div className="row align-items-center justify-content-center py-5">
          <div className="col-lg-10">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <h2 className=" text-green">
                  <span className="text-dark-green d-md-block">
                    {t("free_newsletter-")}
                  </span>
                  {t("stay_informed!")}
                </h2>
              </div>
              <div className="col-md-8 col-lg-6 d-flex align-items-center budget-reminder">
                <div className="row w-md-100">
                  <div className="col-md-8 mt-md-0 mt-4">
                    <Form.Control
                      type="email"
                      placeholder={t("Email_ddress")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4">
                    <Button
                      className="btn-100 mt-3 mt-md-0 bg-green px-5 text-white border-0 fw-bold news-letter-btn"
                      onClick={() => subscribeHandler()}
                    >
                      {t("Subscribe")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </section>
  );
};

export default StayInformed;
