import React, { useState } from "react";
import FixedCalculator from "../assets/images/fixed-calculator.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import FixDepositChart from "./FixChart";
import FixDepositChart2 from "./FixCart2";

const FixedDepositCalculator = () => {
  const { t } = useTranslation();
  const [initialCapital, setInitialCapital] = useState("");
  const [calculateIn, setCalculateIn] = useState(`${t("month")}`);
  const [results, setResults] = useState({
    finalCapital: 0,
    totalDeposit: 0,
    interestReceived: 0,
  });
  const [toggle, setToggle] = useState(false);
  const [duration, setDuration] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [chartShow, setChartShow] = useState(false);
  const [chartShow2, setChartShow2] = useState(false);
  const [texRate, setTexRate] = useState("26.375");
  const [taxAllowance, setTaxAllowance] = useState("1000");
  const [interestPayment, setInterestPayment] = useState(
    `${t("noInterestAccumulation")}`
  );
  const [capital, setCapital] = useState("");
  const [iPaymentReceived, setIPaymentReceived] = useState("");

  const replaceValue = (value) => {
    const normalizedValue = value.replace(/,/g, ".");
    return normalizedValue;
  };

  const calculateMaturityAmount = () => {
    if (duration === "" || interestRate === "" || initialCapital === "") {
      return;
    }

    setChartShow(true);
    let yearlyResults = [];
    const r = Number(replaceValue(interestRate)) / 100;
    const n =
      calculateIn === `${t("Years")}`
        ? Number(replaceValue(duration))
        : Number(replaceValue(duration)) / 12;
    if (n <= 1) {
      setChartShow2(true);
    } else {
      setChartShow2(false);
    }
    var startingAmount = Number(replaceValue(initialCapital));
    var taxDeducted = 0;
    var finalInterestAmount = 0;
    for (let i = 1; i <= Math.ceil(n); i++) {
      const newDuration =
        calculateIn === `${t("Years")}`
          ? 1
          : n < 1
          ? n
          : i === 1
          ? 1
          : n % 1 === 0
          ? 1
          : n - (i - 1) < 1
          ? n % 1
          : !Number.isInteger(n - (i - 1))
          ? 1
          : 0;

      const totalAmount =
        interestPayment === `${t("yesInterestAccumulation")}`
          ? startingAmount * Math.pow(1 + r, newDuration)
          : Number(replaceValue(initialCapital)) +
            Number(replaceValue(initialCapital)) * r * newDuration;

      const interestAmount =
        interestPayment === `${t("yesInterestAccumulation")}`
          ? totalAmount - startingAmount
          : totalAmount - Number(replaceValue(initialCapital));
      finalInterestAmount = finalInterestAmount + interestAmount;

      const taxAbleAmount =
        taxAllowance === ""
          ? interestAmount
          : interestAmount > Number(replaceValue(taxAllowance))
          ? interestAmount - Number(replaceValue(taxAllowance))
          : 0;

      const taxAmount =
        taxAbleAmount *
        (texRate === "" ? 1 : Number(replaceValue(texRate)) / 100);

      const net = totalAmount.toFixed(3) - taxAmount.toFixed(3);
      const netTotal = toggle ? net.toFixed(3) : totalAmount.toFixed(3);
      startingAmount = parseFloat(netTotal).toFixed(3);
      taxDeducted = taxDeducted + Number(taxAmount);

      const yr = {
        netTotal: netTotal,
        interestAmount: interestAmount.toFixed(3),
        taxAmount: taxAmount.toFixed(3),
        taxAllowance: taxAllowance,
        isTaxIncluded: toggle,
        initialCapital: initialCapital,
      };

      yearlyResults.push(yr); // Save the result
    }
    const totalAmount =
      interestPayment === `${t("yesInterestAccumulation")}`
        ? Number(replaceValue(initialCapital)) * Math.pow(1 + r, n)
        : Number(replaceValue(initialCapital)) + Number(initialCapital) * r * n;
    const interestAmount = totalAmount - Number(replaceValue(initialCapital));

    // const taxAbleAmount =
    //   taxAllowance === ""
    //     ? interestAmount
    //     : interestAmount > Number(replaceValue(taxAllowance))
    //     ? interestAmount - Number(replaceValue(taxAllowance))
    //     : 0;
    // const taxAmount = taxAbleAmount * (texRate === "" ? 1 : Number(replaceValue(texRate)) / 100);
    const net = finalInterestAmount - taxDeducted;
    const netTotal = toggle
      ? Number(replaceValue(initialCapital)) + net
      : totalAmount;
    setCapital(netTotal.toFixed(3));
    setIPaymentReceived(interestAmount.toFixed(3));
    const allResults = {
      finalCapital: netTotal.toFixed(3),
      totalDeposit: initialCapital,
      interestReceived: finalInterestAmount.toFixed(3),
      taxAmount: toggle ? taxDeducted.toFixed(3) : 0,
      taxAllowance: toggle ? taxAllowance : 0,
      isTaxIncluded: toggle,
      yearlyResults: yearlyResults,
    };
    setResults(allResults);
  };
  const onchangeNumber = (e) => {
    const rawValue = e.target.value;
    const value = rawValue.replace(/[^0-9.,]/g, "");
    let name = e.target.name;
    if (name === "initialCapital") {
      if (e.target.valueAsNumber <= 0) {
        setInitialCapital(0);
      } else {
        setInitialCapital(value);
      }
    }
    if (name === "duration") {
      if (e.target.valueAsNumber <= 0) {
        setDuration(0);
      } else {
        setDuration(value);
      }
    }
    if (name === "interestRate") {
      if (e.target.valueAsNumber <= 0) {
        setInterestRate(0);
      } else {
        setInterestRate(value);
      }
    }
    if (name === "taxRate") {
      if (e.target.valueAsNumber <= 0) {
        setTexRate(0);
      } else {
        setTexRate(value);
      }
    }
    if (name === "texAllowance") {
      if (e.target.value < 0) {
        setTaxAllowance(0);
      } else {
        setTaxAllowance(value);
      }
    }
  };
  const toggleTax = () => {
    setToggle(!toggle);
  };

  return (
    <section className="fixed-calculation my-5">
      <div className="row align-items-center border-top-bottom">
        <div className="col-md-6 bg-green px-5 px-md-2 px-lg-5 fixed-form">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <h6 className="body-heading text-white mb-4">
                {t("fixed_deposit_calculator")}
              </h6>
              <Form>
                <div className="row align-items-end ">
                  <div className="col-12 col-xl-6 mb-2 mb-lg-3 mb-md-5">
                    <Form.Group md="4">
                      <Form.Label className="text-white fw-500 text-uppercase">
                        {t("initial_capital")}
                      </Form.Label>
                      {/* <Form.Control required type="text" value={initialCapital} onChange={(e)=>setInitialCapital(e)} /> */}
                      <input
                        name="initialCapital"
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="0"
                        value={initialCapital}
                        onChange={onchangeNumber}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-12 col-xl-6 mb-2 mb-lg-3 mb-md-5">
                    <Form.Group md="4">
                      <Form.Label className="text-white fw-500 text-uppercase">
                        {t("interest_rate")} (%)
                      </Form.Label>
                      {/* <Form.Control required type="text" /> */}
                      <input
                        name="interestRate"
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="0"
                        value={interestRate}
                        onChange={onchangeNumber}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-12 col-xl-6 mb-2 mb-lg-3 mb-md-5">
                    <Form.Group md="4">
                      <Form.Label className="text-white fw-500 text-uppercase">
                        {t("Duration")}
                      </Form.Label>
                      {/* <Form.Control required type="text" /> */}
                      <input
                        name="duration"
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="0"
                        value={duration}
                        onChange={onchangeNumber}
                      ></input>
                    </Form.Group>
                  </div>
                  <div className="col-12 col-xl-6 mb-2 mb-lg-3 mb-md-5">
                    <Dropdown className="paymentType fixDepositDrop">
                      <Dropdown.Toggle id="dropdown-basic">
                        <div className="interestPayment">{calculateIn}</div>
                        <div className="d-lg-none d-block ">
                          {/* {"Select Language"} */}
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setCalculateIn(`${t("month")}`)}
                        >
                          {t("month")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setCalculateIn(`${t("Years")}`)}
                        >
                          {t("Years")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  {/* <div className="col-md-6 col-xl-1 mb-2 mb-lg-3 mb-md-5">
                    <Form.Group className="mb-2">
                      <Form.Check
                        className="text-white fs-32"
                        required
                        label={"Month"}
                        // style={{marginBottom:"5px"}}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 col-xl-5 mb-2 mb-lg-3 mb-md-5">
                    <Form.Group className="mb-2">
                      <Form.Check
                        className="text-white fs-24"
                        required
                        label={"Year"}
                      />
                    </Form.Group>
                  </div> */}
                  <div className="d-flex items-center mb-3">
                    <Form.Label className="text-white fw-500 text-uppercase">
                      {t("interest.taxRate")}:
                    </Form.Label>

                    <Form.Check
                      onClick={() => toggleTax()}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {toggle && (
                    <>
                      <div className="col-12 col-xl-6 mb-2 mb-lg-3 mb-md-5">
                        <Form.Group md="4">
                          <Form.Label className="text-white fw-500 text-uppercase">
                            {t("interest.taxRate")}
                          </Form.Label>
                          <input
                            name="texRate"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="0"
                            value={texRate}
                            onChange={onchangeNumber}
                          ></input>
                        </Form.Group>
                      </div>
                      <div className="col-12 col-xl-6 mb-2 mb-lg-3 mb-md-5">
                        <Form.Group md="4">
                          <Form.Label className="text-white fw-500 text-uppercase">
                            {t("interest.annualAllowance")}
                          </Form.Label>
                          {/* <Form.Control required type="text" /> */}
                          <input
                            name="texAllowance"
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="0"
                            value={taxAllowance}
                            onChange={onchangeNumber}
                            contentEditable
                          ></input>
                        </Form.Group>
                      </div>
                    </>
                  )}
                  <div className="col-12 col-xl-5 mb-2 mb-lg-3 mb-md-5">
                    <Form.Group>
                      <Form.Label className="text-white fw-100 text-uppercase ">
                        {t("interest_payment")}
                      </Form.Label>
                      {/* <Form.Control required type="text" /> */}
                      <Dropdown className=" paymentType">
                        <Dropdown.Toggle id="dropdown-basic" className="ml-0">
                          {/* <img className="me-1" src={LanguageIcon} alt="icon" /> */}
                          <div className="me-1 interestPayment ">
                            {interestPayment}
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="ml-0">
                          <Dropdown.Item
                            href="#"
                            onClick={() =>
                              setInterestPayment(
                                `${t("noInterestAccumulation")}`
                              )
                            }
                            className="ml-0"
                          >
                            {t("noInterestAccumulation")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            onClick={() =>
                              setInterestPayment(
                                `${t("yesInterestAccumulation")}`
                              )
                            }
                          >
                            {t("yesInterestAccumulation")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </div>
                  <div className="col-12 col-xl-6 col-xxl-4 mb-2 mb-lg-3 mb-md-5">
                    {/* <Form.Group className="mb-2">
                      <Form.Check
                        className="text-white fs-32"
                        required
                        label={t("Take_taxes_into_account")}
                      />
                    </Form.Group> */}
                  </div>
                  <div className="col-12 col-xxl-3 mb-2 mb-lg-3 mb-md-5">
                    <Button
                      className="get-started-btn bg-white rounded-pill text-green border-0 py-2 fw-600 w-100"
                      onClick={() => {
                        calculateMaturityAmount();
                      }}
                    >
                      {t("calculate")}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6 bg-white px-5 mt-5 d-md-block d-block">
          {!chartShow && (
            <img
              className="d-block mx-auto w-100 mx-md"
              src={FixedCalculator}
              alt="img"
            />
          )}
          {chartShow &&
            (chartShow2 ? (
              <FixDepositChart2
                onClick={() => setChartShow(false)}
                results={results}
              />
            ) : (
              <FixDepositChart
                onClick={() => setChartShow(false)}
                results={results}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default FixedDepositCalculator;
