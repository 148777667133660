import React, { useEffect, useState } from "react";
import BannerCalendar from "../assets/images/banner-calendar.svg";
import cardImage2 from "../assets/images/account-and-card.svg";
import { Button } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import StayInformed from "../components/StayInformed";
import Cookies from "../components/Cookies";
import { useTranslation } from "react-i18next";
import { GetRequest } from "../utils/apiCall";
import i18next from "i18next";
import { Helmet } from "react-helmet";

const Formularcenter = () => {
  const selectedLanguage = i18next.language;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCookies, setShowCookies] = useState(true);

  const getDownloadData = () => {
    setLoading(true);
    GetRequest("api/download-forms").then((res) => {
      setDownloadData(res.data.data.downloadForms);
    });
  };
  const downloadForm = (url) => {
    window.open(url, "_blank");
  };

  const handleClick = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    getDownloadData();
  }, []);

  return (
    <>
     <Helmet>
    <link rel="canonical" href="https://finanzfaible.com/formularcenter"/>
    <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
    </Helmet>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500 text-capitalize">
                {t("forms.title")}
              </h1>
              <p className="text-white mt-3">{t("forms.subTitle")}</p>
              <p className="text-white mt-3">{t("forms.subTitle2")}</p>
              <div className="d-flex mt-4">
                <Button className="btn-100 rounded-pill get-started-btn bg-white text-green px-5 py-2 border-0 me-3 fw-bold">
                  {t("forms.browseNow")}
                </Button>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerCalendar}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="helpfull-form py-5">
        <div className="container">
          <div className="row">
            {downloadData?.map((form) => {
              return (
                <div className="col-md-6 mb-4 text-center">
                  <div className="card-one">
                    <div className="card-img-custom">
                      <img src={cardImage2} alt="icon" />
                    </div>
                    <h4 className="text-green fw-600 mt-3 mb-2">
                      {form["name_" + selectedLanguage]
                        ? form["name_" + selectedLanguage]
                        : form?.name_de}
                    </h4>
                    <p className="m-0 px-2 mx-md-5 pb-3 border-0">
                      {form["description_" + selectedLanguage]
                        ? form["description_" + selectedLanguage]
                        : form?.description_de}
                    </p>
                    <Button
                      className="get-started-btn bg-transparent border-0 text-green fw-bold pt-3 pb-0"
                      onClick={() => downloadForm(form.file ? form.file : "")}
                    >
                      {t("forms.ViewForm")}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Blogs />

      {/* <FinancialGoal /> */}

      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default Formularcenter;
