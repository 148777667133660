import React from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import "react-phone-number-input/style.css";
import { PostRequest, RegisterPostRequest } from "../utils/apiCall";
import e from "cors";
import { Toaster, toast } from "react-hot-toast";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const SignupModal = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    dob: "",
    address: "",
    country: "",
  });

  const signUpHandler = () => {
    setLoading(true);
    let data = new FormData();
    data.append("first_name", userData.firstName);
    data.append("last_name", userData.lastName);
    data.append("date_of_birth", "13-02-1997");
    data.append("address", userData.address);
    data.append("password", userData.password);
    data.append("password_confirmation", userData.confirmPassword);
    data.append("email", userData.email);
    data.append("country", userData.country);
    data.append("phone_number", userData.phoneNumber);

    userData.firstName === ""
      ? toast.error("please add First Name")
      : userData.lastName === ""
      ? toast.error("please add Last Name")
      : userData.email === ""
      ? toast.error("please add Email")
      : userData.phoneNumber === ""
      ? toast.error("please add Phone Number")
      : userData.address === ""
      ? toast.error("please add Address")
      : userData.country === ""
      ? toast.error("please add Country")
      : userData.password === ""
      ? toast.error("please add Password")
      : userData.password !== userData.confirmPassword
      ? toast.error("Password is not confirmed")
      : RegisterPostRequest(data, "api/register").then((res) => {
          console.log("user registration data :", res);
          if (res.status) {
            if (res.data.success) {
              toast.success(res.data.message);
            props.setEmail(res?.data?.data?.email);
            props?.setOtpModal(true)

            props.onHide();
            } else {
              toast.error(res?.data?.error?.email[0]);
              toast.error(res?.data?.error?.phone_number[0]);
            }
          } else {
            toast.error(`User Registered error`);
          }
        });
  };

  const signinHandler = () => {
    props.showSignIn(true);
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="login-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{t("signUpModal.title")}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("signUpModal.title2")}!</p>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-4">
                  <Form.Label>{t("signUpModal.firstName")}*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("signUpModal.enterFirstName")}
                    value={userData.firstName}
                    onChange={(e) =>
                      setUserData({ ...userData, firstName: e.target.value })
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-4">
                  <Form.Label>{t("signUpModal.lastName")}*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("signUpModal.enterLastName")}
                    value={userData.lastName}
                    onChange={(e) =>
                      setUserData({ ...userData, lastName: e.target.value })
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>Email*</Form.Label>
              <div>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
              </div>
            </Form.Group>
            <div className="mb-3">
              <Form.Label>{t("phoneNumber")}*</Form.Label>
              <PhoneInput
                placeholder={t("signUpModal.enterPhone")}
                defaultCountry="US"
                value={userData.phoneNumber}
                onChange={(e) => setUserData({ ...userData, phoneNumber: e })}
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-4">
                  <Form.Label>Address*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    value={userData.address}
                    onChange={(e) =>
                      setUserData({ ...userData, address: e.target.value })
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-4">
                  <Form.Label>Country*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Country"
                    value={userData.country}
                    onChange={(e) =>
                      setUserData({ ...userData, country: e.target.value })
                    }
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-4">
                  <Form.Label>{t("signUpModal.Password")}*</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t("signUpModal.enterPassword")}
                    value={userData.password}
                    onChange={(e) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-4">
                  <Form.Label>{t("signUpModal.confirmPassword")}*</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t("signUpModal.confirmPassword")}
                    value={userData.confirmPassword}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
            </div>

            {/* <div className="mb-3 mt-4">
              <Form.Check
                className="checkbox"
                type="checkbox"
                id="1"
                label="I am not a  robot."
              />
            </div> */}

            <Button
              className="button-login mt-3"
              onClick={() => signUpHandler()}
            >
              {t("signUpModal.signUp")}
            </Button>
          </Form>
          <span className="fw-medium text-black text-center d-block">
            {t("signUpModal.haveAccount")}
            <Link
              className="fw-semibold text-black"
              to="#"
              onClick={() => signinHandler()}
            >
              {t("login")}?
            </Link>
          </span>
        </Modal.Body>
        <Toaster />
      </Modal>
    </>
  );
};

export default SignupModal;
