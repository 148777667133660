import React, { useEffect, useState } from "react";
import BannerImage from "../assets/images/fund-banner.svg";
import Household from "../assets/images/household-img.svg";
import Calendar from "../assets/images/calendar-img.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";

import { Button, Form } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";
import FixedCalculator from "../assets/images/fixed-calculator.svg";
import { useTranslation } from "react-i18next";
import BudgetReminder from "../components/BudgetReminder";
import FundChart from "../components/FundChart";
import { getAccSumByYear, getCurrentMonthSaving, getValuesByYear, roundToTwo } from "../utils/helper";
import { USE_INCOME } from "../modal/enums";

const FundCalculator = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [dynamicValue, setDynamicValue] = useState(false);
  const [includeHandlingFee, setIncludeHandlingFee] = useState(false);
  const [handlingFeeType, setHandlingFeeType] = useState('separate');//separate/deposit
  const [includeCustodyFee, setIncludeCustodyFee] = useState(false);
  const [custodyFeeType, setCustodyFeeType] = useState('separate');//separate/deposit
  const [beginningMonth, setBeginningMonth] = useState(false);
  const [chartShow, setChartShow] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [toFind, setToFind] = useState("finalValue");
  const [inflationValue, setInflationValue] = useState(false)
  const [calculations, setCalculations] = useState({
    oneAmount: "10000",
    savingRate: "100",
    savingInterval: "yearly",
    dynamics: "0.00",
    annualCGrowth: "6.00",
    useIncome: "no",
    annualReturn: "2.0",
    duration: "4",
    finalValue: "",
    profit: "",
    issueFee: "2.5",
    relatedTo: "",
    adminFee: "0.5",
    handlingFee: "0",
    handlingFeeWith: "",
    annualCFee: "0.1",
    annualCFeeMin: "12",
    annualCFeeMax: "1000",
    annualCFeeType: "",
    taxRate: "26.375",
    taxAllowance: "1000.00",
    partialExempt: "",
    BaseIRate: "",
    inflation: "",
  });
  const [results, setResults] = useState({
    totalIssueFee: 0,
    totalAdminFee: 0,
    totalFee: 0.00,
    totalIncreaseValue: 0,
    totalDeposits: 0,
    annualCGrowthResult: 0,
    durationResult: 0,
    finalValue: 0,
    profitValue: 0,
    totalTaxes: 0,
    afterTaxValue: 0,
    afterInflationValue: 0
  });

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setCalculations({
      ...calculations,
      [name]: value,
    });
  };

  const calculateFinalValue = () => {

    const durationInYears = calculations.duration || 0;
    const durationInMonths = durationInYears * 12


    const savingsArray = []
    const increaseValueArray = []
    const issueFeeArray = []
    const adminFeeArray = []
    const custodyFeeArray = []
    const totalFeeArray = []
    const totalDepositArray = []
    const profitArray = []
    const incomeArray = []
    const taxArray = []
    const startValueArray = []
    const endValueArray = []

    const initialDeposit = parseFloat(calculations.oneAmount)
    const initialIssueFee = initialDeposit * (parseFloat(calculations.issueFee) / 100)
    const initialDepositAfterFee = initialDeposit - initialIssueFee + (includeHandlingFee ? handlingFeeType === 'deposit' ? (-1 * parseFloat(calculations.handlingFee)) : 0 : 0)

    const monthlyIncreaseRate = getMonthlyIncreaseRate(calculations.useIncome !== USE_INCOME.NO ?
      parseFloat(calculations.annualCGrowth) + parseFloat(calculations.annualReturn) : parseFloat(calculations.annualCGrowth))

    console.log('monthlyIncreaseRate', monthlyIncreaseRate)

    for (let i = 0; i < durationInMonths; i++) {
      const valueAtStart = i === 0 ? initialDepositAfterFee : endValueArray[i - 1]
      startValueArray.push(valueAtStart)
      const currentMonthSaving = getCurrentMonthSaving(parseFloat(calculations.savingRate), i, calculations.savingInterval, calculations.dynamics)
      savingsArray.push(currentMonthSaving)
      const currentMonthIssue = currentMonthSaving * (parseFloat(calculations.issueFee) / 100)
      issueFeeArray.push(currentMonthIssue)
      // console.log('valueAtStart', valueAtStart, i)
      const total = valueAtStart + currentMonthSaving - currentMonthIssue
      const monthlyIncrease = roundToTwo(monthlyIncreaseRate * total)
      increaseValueArray.push(monthlyIncrease)

      const currentMonthAdminFee = roundToTwo(((parseFloat(calculations.adminFee) / 100) * total) / 12)
      adminFeeArray.push(currentMonthAdminFee)

      const currentMonthTotalFee = currentMonthIssue + currentMonthAdminFee
      totalFeeArray.push(currentMonthTotalFee)


      const accSaving = getAccSum(savingsArray)
      const totalDeposit = roundToTwo(initialDeposit + accSaving)
      totalDepositArray.push(totalDeposit)

      let income = 0
      if (calculations.useIncome === USE_INCOME.DISTRIBUTING) {
        income = getIncome(i, totalDeposit, increaseValueArray, incomeArray, issueFeeArray, initialIssueFee)
        // income = getIncomeNew(i, startValueArray, savingsArray)
        incomeArray.push(income)
        // const endValueAfterIncome = endValue - income
      }

      let endValue = roundToTwo(total + monthlyIncrease - currentMonthAdminFee - income)
      if (includeCustodyFee) {
        if (custodyFeeType == 'separate') {
          const currentCustodyFee = getCustodyFee(endValue, i)
          custodyFeeArray.push(currentCustodyFee)
        }
        if (custodyFeeType == 'deposit') {
          const currentCustodyFee = getCustodyFee(endValue, i)
          custodyFeeArray.push(currentCustodyFee)
          endValue = endValue - currentCustodyFee
        }
      }

      endValueArray.push(endValue)


      let taxAmount = 0
      if (toggle) {
        const taxableAmount = endValue - totalDeposit - parseFloat(calculations.taxAllowance)
        taxAmount = getTaxAmount(taxableAmount)
        taxArray.push(taxAmount)
      }

      const currentMonthProfit = roundToTwo(endValue - totalDeposit - taxAmount)
      profitArray.push(currentMonthProfit)
    }


    const finalValue = endValueArray.length === 0 ? 0.00 : roundToTwo(endValueArray[endValueArray.length - 1])
    const finalProfit = profitArray.length === 0 ? 0.00 : roundToTwo(profitArray[profitArray.length - 1])
    const totalDeposit = totalDepositArray.length === 0 ? 0.00 : roundToTwo(totalDepositArray[totalDepositArray.length - 1])
    const totalTax = taxArray.length === 0 ? 0.00 : roundToTwo(taxArray[taxArray.length - 1])


    const totalFee = roundToTwo(getAccSum(totalFeeArray)) +
      (includeHandlingFee ? roundToTwo(parseFloat(calculations.handlingFee)) : 0) +
      (includeCustodyFee ? roundToTwo(getAccSum(custodyFeeArray)) : 0)


    const finalValueAfterTax = roundToTwo(finalValue - totalTax)

    const inflationFactor = (parseFloat(calculations.inflation) / 100) * (finalValue) * durationInYears
    const finalCapitalAfterInflation = calculations.inflation === "" ? 0 : (finalValue - inflationFactor).toFixed(3)


    console.log("savingsArray: ", savingsArray);
    console.log("issueFeeArray: ", issueFeeArray);
    console.log("increaseValueArray: ", getAccSumByYear(increaseValueArray));
    // console.log("increaseValueArray: ", (increaseValueArray));
    // console.log("adminFeeArray: ", adminFeeArray); 
    // console.log("endValueArray: ", endValueArray);
    // console.log("profitArray: ", profitArray);
    // console.log("totalDepositArray: ", totalDepositArray);
    console.log("incomeArray: ", incomeArray);
    // console.log("custodyFeeArray: ", custodyFeeArray);

    setResults({
      finalValue: finalValue,
      profitValue: finalProfit,
      totalDeposits: totalDeposit,
      totalIssueFee: roundToTwo(getAccSum(issueFeeArray)) + initialIssueFee,
      totalAdminFee: roundToTwo(getAccSum(adminFeeArray)),
      totalFee: totalFee,
      totalIncreaseValue: roundToTwo(getAccSum(increaseValueArray)),

      afterTaxValue: finalValueAfterTax,
      afterInflationValue: finalCapitalAfterInflation,
      totalTaxes: totalTax,

      inflation: finalCapitalAfterInflation,

      increaseValueArray: getAccSumByYear(increaseValueArray),
      issueFeeArray: getAccSumByYear(issueFeeArray).map((item, i) => i == 0 ? item + initialIssueFee : item),
      adminFeeArray: getAccSumByYear(adminFeeArray),
      totalFeeArray: getAccSumByYear(totalFeeArray),
      finalValueArray: getAccSumByYear(endValueArray),
      profitArray: getValuesByYear(profitArray),
      totalDepositArray: getAccSumByYear(totalDepositArray),
      taxArray: getValuesByYear(taxArray),
      incomeArray: getValuesByYear(incomeArray),
    })

  };


  const getCustodyFee = (endValue, month) => {
    if ((month + 1) % 12 === 0) {
      let custodyFee = 0
      let custodyFeeMin = parseFloat(calculations.annualCFeeMin)
      let custodyFeeMax = parseFloat(calculations.annualCFeeMax)
      const cFee = endValue * (parseFloat(calculations.annualCFee) / 100)
      if (cFee < custodyFeeMin) {
        custodyFee = custodyFeeMin
      } else if (cFee > custodyFeeMax) {
        custodyFee = custodyFeeMax
      } else {
        custodyFee = cFee
      }
      return roundToTwo(custodyFee)
    } else {
      return 0
    }

  }
  const getIncomeNew = (month, startValueArray, savingsArray) => {
    // let income = 0;
    // if (month == 0) return 0
    // if (month + 1 === 12) {
    //   const totalAmount = deposit - initialIssueFee - getAccSum(issueFeeArray)
    //   income = totalAmount * (parseFloat(calculations.annualReturn) / 100)
    //   return roundToTwo(income)
    // }

    // if ((month + 1) % 12 === 0) {
    //   const totalAmount = deposit + getAccSum(increaseValueArray.slice(0, increaseValueArray.length - 12))
    //     - getAccSum(incomeArray.slice(0, incomeArray.length - 12))
    //     - getAccSum(issueFeeArray.slice(month - 11, month + 1))
    //     - initialIssueFee

    //   console.log('total', totalAmount, getAccSum(issueFeeArray.slice(month - 11, month + 1)))

    //   income = totalAmount * (parseFloat(calculations.annualReturn) / 100)
    // }
    // return roundToTwo(income)
  }
  const getIncome = (month, deposit, increaseValueArray, incomeArray, issueFeeArray, initialIssueFee) => {
    let income = 0;
    if (month == 0) return 0
    if (month + 1 === 12) {
      const totalAmount = deposit - initialIssueFee - getAccSum(issueFeeArray)
      income = totalAmount * (parseFloat(calculations.annualReturn) / 100)
      return roundToTwo(income)
    }

    if ((month + 1) % 12 === 0) {
      const totalAmount = deposit + getAccSum(increaseValueArray.slice(0, increaseValueArray.length - 12))
        - getAccSum(incomeArray.slice(0, incomeArray.length - 12))
        - getAccSum(issueFeeArray.slice(month - 11, month + 1))
        - initialIssueFee

      console.log('total', totalAmount, getAccSum(issueFeeArray.slice(month - 11, month + 1)))

      income = totalAmount * (parseFloat(calculations.annualReturn) / 100)
    }
    return roundToTwo(income)
  }

  const getMonthlyIncreaseRate = (annualRate) => {
    const monthlySavingRate = Math.pow((1 + annualRate / 100), (1 / 12)) - 1
    return monthlySavingRate
  }
  const getAccSum = (arr) => {
    return arr.reduce((accumulator, currentValue) => (accumulator) + (currentValue), 0);;
  }
  const getTaxAmount = (amount) => {
    if (amount > 0) {
      return amount * (parseFloat(calculations.taxRate) / 100)
    }
    return 0
  }
  const onPressCalculate = () => {
    calculateFinalValue()
    setChartShow(true);
  };
  // const handleFindValue = (checkboxValue) => {
  //   setToFind((prevValue) => {
  //     // Toggle the checkbox state
  //     return prevValue === checkboxValue ? null : checkboxValue;
  //   });
  // };
  // const Checkbox = ({ value }) => (
  //   <Form.Check
  //     value={value}
  //     checked={toFind === value}
  //     onChange={() => handleFindValue(value)}
  //     className="switch-toggle ms-3"
  //     type="switch"
  //     id="custom-switch"
  //   />
  // );


  return (
    <>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500 text-capitalize">
                {t("fundCalculator.title")}
              </h1>
              <p className="text-white mt-3">
                {t("fundCalculator.description")}
              </p>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={Household}
                alt="img not found"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <h2 className="heading-main text-green">
                {t("fundCalculator.howToUse")}
              </h2>
              <ul className="list-style mt-3">
                <li className="p-heading">{t("fundCalculator.l1")}</li>
                <li className="p-heading">{t("fundCalculator.l2")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="monthly-calculation pt-5 pb-2">
        <div className="container">
          <span className="text-green text-start text-md-center d-block fw-500">
            {t("calculator")}
          </span>
          <h3 className="body-heading text-start text-md-center">
            {t("fundCalculator.title")}
          </h3>

          <div className="row bg-green py-4 px-2 radius-24 mx-lg-5 mt-4">
            <div className="col-md-6  mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 h-100 px-4 py-4">
                <h5 className="text-green text-left fw-bold text-capitalize mb-3">
                  {t("summary")}
                </h5>
                <Form>
                  <Form.Group className="mb-3">
                    {/* <Form.Label>{t("fundCalculator.oneOff")}</Form.Label> */}
                    <div className="d-flex items-center justify-content-between">
                      <Form.Label> {t("fundCalculator.oneOff")}</Form.Label>

                      {/* <Checkbox value="oneOff" /> */}
                    </div>
                    <Form.Control
                      name="oneAmount"
                      value={
                        toFind === "oneOff"
                          ? results.oneAmountResult
                          : calculations.oneAmount
                      }
                      onChange={(e) => onChangeHandler(e)}
                      // disabled={toFind === "oneOff" ? true : false}
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                      type="number"
                      placeholder="€5,000.00"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    {/* <Form.Label>{t("fundCalculator.savingsRate")}</Form.Label> */}
                    <div className="d-flex items-center justify-content-between">
                      <Form.Label>
                        {" "}
                        {t("fundCalculator.savingsRate")}
                      </Form.Label>

                      {/* <Checkbox value="savingRate" /> */}
                    </div>
                    <div className="position-relative">
                      <Form.Control
                        name="savingRate"
                        value={
                          toFind === "savingRate"
                            ? results.savingsResult
                            : calculations.savingRate
                        }
                        onChange={(e) => onChangeHandler(e)}
                        // disabled={toFind === "savingRate" ? true : false}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                    </div>
                  </Form.Group>

                  <Form.Label>{t("fundCalculator.savingInterval")}</Form.Label>
                  <Form.Select
                    name="savingInterval"
                    onChange={(e) => onChangeHandler(e)}
                    defaultValue="yearly"
                    className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                    aria-label="Default select example"
                  >
                    <option value="monthly">{t("fundCalculator.monthly")}</option>
                    <option value="quarterly">{t("fundCalculator.quarterly")}</option>
                    <option value="half-yearly">{t("fundCalculator.half-yearly")}</option>
                    <option value="yearly">{t("fundCalculator.yearly")}</option>
                  </Form.Select>




                  <Form.Group className="mb-3">
                    <div className="d-flex items-center justify-content-between">
                      <Form.Label> {t("fundCalculator.dynamics")}</Form.Label>
                    </div>
                    <div className="position-relative">
                      <Form.Control
                        name="dynamics"
                        value={calculations.dynamics}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="2,00"
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>





                  <div className="d-flex items-center mb-3">
                    <Form.Label> {t("savingsCalculator.inflation")}:</Form.Label>
                    <Form.Check
                      onClick={() => setInflationValue(!inflationValue)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {inflationValue && (
                    <>
                      <Form.Group className="mb-3">
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="1,0"
                            name="inflation"
                            value={calculations.inflation}
                            onChange={onChangeHandler}
                          />
                          <span className="eye-icon interest-rate text-green px-2 me-4">
                            %Pa
                          </span>
                        </div>
                      </Form.Group>
                    </>
                  )}


                  <Form.Group className="mb-3">
                    {/* <Form.Label>{t("fundCalculator.annualCourse")}</Form.Label> */}
                    <div className="d-flex items-center justify-content-between">
                      <Form.Label>
                        {" "}
                        {t("fundCalculator.annualCourse")}
                      </Form.Label>

                      {/* <Checkbox value="annualCourse" /> */}
                    </div>
                    <div className="position-relative">
                      <Form.Control
                        name="annualCGrowth"
                        value={
                          toFind === "annualCourse"
                            ? results.savingsResult
                            : calculations.annualCGrowth
                        }
                        onChange={(e) => onChangeHandler(e)}
                        disabled={toFind === "savingRate" ? true : false}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>



                  <Form.Label>{t("fundCalculator.incomeUse")}</Form.Label>
                  <Form.Select
                    name="useIncome"
                    onChange={(e) => onChangeHandler(e)}
                    defaultValue="no"
                    className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                    aria-label="Default select example"
                  >
                    <option value={USE_INCOME.NO}>
                      {t("fundCalculator.no")}
                    </option>
                    {/* <option value={USE_INCOME.DISTRIBUTING}>
                      {t("fundCalculator.distributing")}
                    </option> */}
                    <option value={USE_INCOME.ACCUMULATING}>
                      {t("fundCalculator.accumulating")}
                    </option>
                  </Form.Select>
                  {calculations.useIncome !== USE_INCOME.NO &&
                    <>
                      <Form.Label>{t("fundCalculator.annualReturn")}</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          name="annualReturn"
                          onChange={(e) => onChangeHandler(e)}
                          value={calculations.annualReturn}
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                          type="number"
                          placeholder="2,0"
                        />
                        <span className="eye-icon interest-rate text-green px-2 me-4">
                          %Pa
                        </span>
                      </div>
                    </>
                  }

                  <Form.Group className="mb-3">
                    <div className="d-flex items-center justify-content-between">
                      <Form.Label> {t("fundCalculator.duration")}</Form.Label>
                    </div>
                    <div className="position-relative">
                      <Form.Control
                        name="duration"
                        value={calculations.duration}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="10"
                      />
                    </div>
                  </Form.Group>



                  <Form.Group className="mb-3">
                    <Form.Label>{t("fundCalculator.issueFee")}</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="issueFee"
                        value={calculations.issueFee}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>

                  <Form.Label>{t("fundCalculator.relatedTo")}</Form.Label>
                  <Form.Select
                    className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-3"
                    aria-label="Default select example"
                  >
                    <option value="0">{t("fundCalculator.netAmount")}</option>
                    <option value="1">{t("fundCalculator.grossAmount")}</option>
                  </Form.Select>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      {t("fundCalculator.administrationFee")}
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        name="adminFee"
                        value={calculations.adminFee}
                        onChange={(e) => onChangeHandler(e)}
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="1,000"
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>


                  {/* Handling fee */}
                  <>
                    <div className="d-flex items-center mb-3">
                      <Form.Label> {t("fundCalculator.handlingFee")}</Form.Label>
                      <Form.Check
                        onClick={() => setIncludeHandlingFee(!includeHandlingFee)}
                        className="switch-toggle ms-3"
                        value={calculations.handlingFee}
                        type="switch"
                        id="custom-switch"
                      />
                    </div>
                    {includeHandlingFee && (
                      <>
                        <Form.Control
                          name="handlingFee"
                          value={calculations.handlingFee}
                          onChange={(e) => onChangeHandler(e)}
                          className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0 mb-2"
                          type="number"
                          placeholder="1,000"
                        />
                        <div className="d-block calculation-radio-btn mb-3">
                          <Form.Check
                            label={t("fundCalculator.settleWithDeposits")}
                            name="deposit"
                            onChange={(e) => setHandlingFeeType('deposit')}
                            type="radio"
                            checked={handlingFeeType === 'deposit'}
                            id="1"
                          />
                          <Form.Check
                            label={t("fundCalculator.toPaidSeparately")}
                            name="separate"
                            onChange={(e) => setHandlingFeeType('separate')}
                            type="radio"
                            checked={handlingFeeType === 'separate'}
                            id="2"
                          />
                        </div>
                      </>
                    )}
                  </>


                  {/* Custody fee */}
                  <>
                    <div className="d-flex items-center mb-3">
                      <Form.Label>
                        {" "}
                        {t("fundCalculator.annualCustody")}
                      </Form.Label>

                      <Form.Check
                        onClick={() => setIncludeCustodyFee(!includeCustodyFee)}
                        className="switch-toggle ms-3"
                        type="switch"
                        id="custom-switch"
                      />
                    </div>
                    {includeCustodyFee && (
                      <>
                        <Form.Group className="mb-3">
                          <div className="position-relative">
                            <Form.Control
                              className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                              type="number"
                              name="annualCFee"
                              value={calculations.annualCFee}
                              onChange={(e) => onChangeHandler(e)}
                              placeholder="1.00"
                            />
                            <span className="eye-icon interest-rate text-green px-2 me-4">
                              %Pa
                            </span>
                          </div>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {" "}
                            {t("fundCalculator.minimumFee")}
                          </Form.Label>

                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="€12"
                            name="annualCFeeMin"
                            value={calculations.annualCFeeMin}
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {" "}
                            {t("fundCalculator.maximumFee")}
                          </Form.Label>

                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="€1000"
                            name="annualCFeeMax"
                            value={calculations.annualCFeeMax}
                            onChange={(e) => onChangeHandler(e)}
                          />
                        </Form.Group>
                        <div className="d-block calculation-radio-btn mb-3">
                          <Form.Check
                            label={t("fundCalculator.settleWithDeposits")}
                            name="deposit"
                            onChange={(e) => setCustodyFeeType('deposit')}
                            type="radio"
                            checked={custodyFeeType === 'deposit'}
                            id="1"
                          />
                          <Form.Check
                            label={t("fundCalculator.toPaidSeparately")}
                            name="separate"
                            onChange={(e) => setCustodyFeeType('separate')}
                            type="radio"
                            checked={custodyFeeType === 'separate'}
                            id="2"
                          />
                        </div>

                      </>
                    )}
                  </>





                  <div className="d-flex items-center mb-3">
                    <Form.Label> {t("fundCalculator.taxRate")}</Form.Label>
                    <Form.Check
                      onClick={() => setToggle(!toggle)}
                      className="switch-toggle ms-3"
                      type="switch"
                      id="custom-switch"
                    />
                  </div>
                  {toggle && (
                    <>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {" "}
                          {t("fundCalculator.annualTax")}
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            value={calculations.taxRate}
                            onChange={(e) => onChangeHandler(e)}
                            placeholder="26,373"
                          />
                          <span className="eye-icon interest-rate text-green px-2 me-4">
                            %Pa
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {" "}
                          {t("fundCalculator.annualAllowance")}
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            value={calculations.taxAllowance}
                            onChange={(e) => onChangeHandler(e)}
                            placeholder="1,000.00"
                          />
                          <span className="eye-icon interest-rate text-green px-2 me-4">
                            %Pa
                          </span>
                        </div>
                      </Form.Group>
                      {/* <Form.Group className="mb-3">
                        <Form.Label>
                          {" "}
                          {t("fundCalculator.partialExemption")}
                        </Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="1,000.00"
                          />
                          <span className="eye-icon interest-rate text-green px-2 me-4">
                            %Pa
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label> {t("fundCalculator.baseRate")}</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                            type="number"
                            placeholder="1,000.00"
                          />
                          <span className="eye-icon interest-rate text-green px-2 me-4">
                            %Pa
                          </span>
                        </div>
                      </Form.Group> */}
                    </>
                  )}

                  <Button
                    className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                    onClick={() => onPressCalculate()}
                  >
                    {t("calculate")}
                  </Button>
                </Form>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 py-3 h-100 position-relative">
                <div className="position-absolute end-0 top-0 me-2 mt-2 ">
                  <img className="cursor-pointer" src={PdfIcon} alt="icon" />
                </div>
                <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                  {t("summary")}
                </h5>
                <div className="row align-items-center pt-3 pb-4">
                  <div className="col-md-4 mt-2">
                    <div
                      style={{ backgroundColor: "#f16880" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.totalDeposits}€
                      </h6>
                      <span className="text-light m-0">{t("fundCalculator.totalDeposits")}</span>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <div
                      style={{ backgroundColor: "#198754" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.finalValue}€
                      </h6>
                      <span className="text-light m-0">{t("fundCalculator.finalValue")}</span>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <div
                      style={{ backgroundColor: "#2d3d30" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.profitValue}€
                      </h6>
                      <span className="text-light m-0">{t("fundCalculator.profit")}</span>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <div
                      style={{ backgroundColor: "#63b9a1" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.totalIncreaseValue}€
                      </h6>
                      <span className="text-light m-0">{t("fundCalculator.totalIncrease")}</span>
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <div
                      style={{ backgroundColor: "#0dcbf0" }}
                      className=" py-2 rounded-pill text-center"
                    >
                      <h6 className="text-light m-0 fw-bold">
                        {results.totalFee}€
                      </h6>
                      <span className="text-light m-0">{t("fundCalculator.totalFees")}</span>
                    </div>
                  </div>
                  {toggle && <>
                    <div className="col-md-4 mt-2">
                      <div
                        style={{ backgroundColor: "#FF0000" }}
                        className=" py-2 rounded-pill text-center"
                      >
                        <h6 className="text-light m-0 fw-bold">
                          {results.totalTaxes}€
                        </h6>
                        <span className="text-light m-0">{t("fundCalculator.totalTaxes")}</span>
                      </div>
                    </div>
                    <div className="col-md-4 mt-2">
                      <div
                        style={{ backgroundColor: "#198754" }}
                        className=" py-2 rounded-pill text-center"
                      >
                        <h6 className="text-light m-0 fw-bold">
                          {results.afterTaxValue}€
                        </h6>
                        <span className="text-light m-0">{t("fundCalculator.afterTax")}</span>
                      </div>
                    </div>
                  </>}
                  {
                    inflationValue && <>
                      <div className="col-md-4 mt-2">
                        <div
                          style={{ backgroundColor: "#2d3d30" }}
                          className=" py-2 rounded-pill text-center"
                        >
                          <h6 className="text-light m-0 fw-bold">
                            {results.afterInflationValue}€
                          </h6>
                          <span className="text-light m-0">{t("savingsCalculator.finalCapitalAfterInflation")}</span>
                        </div>
                      </div>
                    </>
                  }
                </div>
                {!chartShow && (
                  <img
                    className="d-block mx-auto w-100 mx-md mt-10"
                    src={FixedCalculator}
                    alt="img"
                  />
                )}
                {chartShow && (
                  <FundChart
                    onClick={() => setChartShow(false)}
                    results={results}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <BudgetReminder />
      <Blogs />
      {/* <FinancialGoal /> */}
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default FundCalculator;
