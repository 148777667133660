import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Cross from "../assets/images/close-circle-icon.svg";
import { useTranslation } from "react-i18next";

const FixDepositChart2 = (props) => {
  const { t } = useTranslation();
  const { onClick, results } = props;

  const yearlyDeposit = results?.yearlyResults?.map((r) => r?.initialCapital);
  const yearlyInterestAmount = results?.yearlyResults?.map(
    (r) => r?.interestAmount
  );
  const yearlyNetTotal = results?.yearlyResults?.map((r) => r?.netTotal);
  const yearlyTaxAllowance = results?.yearlyResults?.map(
    (r) => r?.taxAllowance
  );
  const yearlyTaxAmount = results?.yearlyResults?.map((r) => r?.taxAmount);
  let totalCharts = [];

  for (let i = 0; i < results.yearlyResults.length; i++) {
    totalCharts.push(`${i + 1}`);
  }

  const barSeries = {
    series: [
      {
        name: "Series 1",
        data: [
          results.totalDeposit,
          results.interestReceived,
          results.finalCapital,
          results.taxAllowance,
          results.taxAmount,
        ],
      },
    ],
    options: {
      colors: [function ({ value, seriesIndex, w }) {
        if (barSeries.series[0].data[0] == value) {
          return '#f16880'
        }
        else if (barSeries.series[0].data[1] == value) {
          return '#63b9a1'
        }
        else if (barSeries.series[0].data[2] == value) {
          return '#198754'
        }
        else if (barSeries.series[0].data[3] == value) {
          return '#0dcbf0'
        }
        else if (barSeries.series[0].data[4] == value) {
          return '#dc3645'
        } else {
          return '#fff'
        }
      }],
      annotations: {
        points: [
          {
            x: "Bananas",
            seriesIndex: 0,
            label: {
              borderColor: "#775DD0",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#775DD0",
              },
              text: "Bananas are good",
            },
          },
        ],
      },
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          columnWidth: "30%",
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "left",
        customLegendItems: [
          `${t("deposits")}`,
          `${t("interestCharges")}`,
          `${t("finalCapital")}`,
          `${t("taxAllowance")}`,
          `${t("taxAmount")}`,
        ],
        markers: {
          fillColors: ["#f16880", "#63b9a1", "#198754", "#0dcbf0", "#dc3645"],
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        offsetY: 0,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },

      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          `${t("deposits")}`,
          `${t("interestCharges")}`,
          `${t("finalCapital")}`,
          `${t("taxAllowance")}`,
          `${t("taxAmount")}`,
        ],
        tickPlacement: "on",
      },
      yaxis: {
        opposite: true,
        labels: {
          formatter: function (value) {
            // Custom formatting logic for y-axis labels
            return value + " ";
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
    },
  };

  return (
    <div>
      <div id="chart" className="position-relative">
        <div className="close-icon-chart position-absolute">
          <img onClick={onClick} src={Cross} alt="icon" />
        </div>
        {results.totalDeposit && (
          <div className="row mb-4 px-3">
            <div className="col-md-6 col-lg-4 px-1 mb-2">
              <div
                style={{ backgroundColor: "#1a8754" }}
                className=" bg-success py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €{results?.finalCapital ? results?.finalCapital : 0}
                </h6>
                <span className="text-light m-0">{t("finalCapital")}</span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-1 mb-2">
              <div
                style={{ backgroundColor: "#f16880" }}
                className=" py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €{results?.totalDeposit ? results?.totalDeposit : 0}
                </h6>
                <span className="text-light m-0">{t("totalDeposit")}</span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-1 mb-2">
              <div
                style={{ backgroundColor: "#63b9a1" }}
                className=" py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €{results?.interestReceived ? results?.interestReceived : 0}
                </h6>
                <span className="text-light m-0">{t("interestReceived")}</span>
              </div>
            </div>
            {results.taxAmount != 0 && (
              <>

            <div className="col-md-6 col-lg-4 px-1 mb-2">
              <div
                style={{ backgroundColor: "#63b9a1" }}
                className=" py-2 rounded-pill text-center"
              >
                <h6 className="text-light m-0 fw-bold">
                  €
                  {results?.interestReceived
                    ? (results?.interestReceived -
                      results?.taxAmount).toFixed(3)
                    : 0}
                </h6>
                <span className="text-light m-0">{t("totalGain")}</span>
              </div>
            </div>
                <div className="col-md-6 col-lg-4 px-1 mb-2">
                  <div className="taxAmount bg-danger py-2 rounded-pill text-center">
                    <h6 className="text-light m-0 fw-bold">
                      €{results?.taxAmount ? results?.taxAmount : null}
                    </h6>
                    <span className="text-light m-0">{t("taxAmount")}</span>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 px-1 mb-2">
                  <div className=" bg-info py-2 rounded-pill text-center">
                    <h6 className=" text-light m-0 fw-bold">
                      €{results?.taxAllowance ? results?.taxAllowance : null}
                    </h6>
                    <span className="text-light m-0">{t("taxAllowance")}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <ReactApexChart
          options={barSeries.options}
          series={barSeries.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default FixDepositChart2;
