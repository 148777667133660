import React, { useEffect, useState } from "react";
import TabIcon1 from "../assets/images/tab-icon1.svg";
import TabIcon2 from "../assets/images/tab-icon2.svg";
import TabIcon3 from "../assets/images/tab-icon3.svg";
import TabIcon4 from "../assets/images/tab-icon4.svg";
import TabIcon5 from "../assets/images/tab-icon5.svg";

import BannerImage from "../assets/images/banner-img.png";
import Household from "../assets/images/household-img.svg";
import IncomArrow from "../assets/images/incom-arrow.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";
import ExpenceArrow from "../assets/images/expense-arrow.svg";
import { Accordion, Button, Form, ProgressBar } from "react-bootstrap";
import Blogs from "../components/Blogs";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";
import { useTranslation } from "react-i18next";
import Cross from "../assets/images/close-circle-icon.svg";
import { Toaster, toast } from "react-hot-toast";
import ChildSupport from "../components/ChildSupport";
import MiniJobs from "../components/MiniJobs";
import BudgetReminder from "../components/BudgetReminder";
import { InputFields } from "../components/InputFields";
import AdviceModal from "../components/AdviceModal";
import GetAdd from "../components/GetAddPopup";
import DisableCalculatorPop from "../components/DisabledCalculatorPop";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { userData } from "../redux/favouriteDataSlice";
import CalculationsPopup from "../components/CalculationsPop";

const HouseholdCalculator = () => {
  const { t } = useTranslation();
  const userLang = useSelector(userData);

  const [count, setCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showOtherSource, setShowOtherSource] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [showChildSupport, setShowChildSupport] = useState(false);
  const [fieldSets, setFieldSets] = useState([]);
  const [inputTransValues, setInputTransValues] = useState([]);
  const [inputEduValues, setInputEduValues] = useState([]);
  const [inputPersonValues, setInputPersonValues] = useState([]);
  const [inputSavingValues, setInputSavingValues] = useState([]);
  const [inputSubscriptions, setInputSubscriptions] = useState([]);
  const [showMiniJobs, setShowMiniJobs] = useState(false);
  const [fixCostTips, setFixCostTips] = useState(false);
  const [fixCostTips1, setFixCostTips1] = useState(false);
  const [transCostTips, setTransCostTips] = useState(false);
  const [savingTips, setSavingTips] = useState(false);
  const [savingTips1, setSavingTips1] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(null);
  const [eduExp, setEduExp] = useState(false);
  const [tranExp, setTranExp] = useState(false);
  const [perExp, setPerExp] = useState(false);
  const [yearlyOptRate, setYearlyOptRate] = useState(0);
  const [calculations, setCalculations] = useState({
    householdIncome: "",
    otherSourceIncome: "",
    childSupportIncome: "250",
    miniJobIncome: "520",
    livingCost: {
      rent: "",
      realState: "",
      internet: "",
      electricity: "",
      living: "",
      privatePayments: "",
      creditCard: "",
    },
    transportationExpense: {
      cPayment: "",
      gas: "",
      cInsurance: "",
      publicT: "",
    },
    educationExpense: {
      study: "",
      studentLoan: "",
    },
    personalExpense: {
      livingCost: "",
      disabilityInsurance: "",
      otherInsurance: "",
      clothes: "",
      subscription: "",
      freeTime: "",
      insurance: "",
      payment: "",
      housePet: "",
    },
    savings: {
      emergencyFund: "",
      investing: "",
      privateFund: "",
    },
  });
  const [results, setResults] = useState({
    totalIncome: 0,
    totalCost: 0,
    remainingBalance: 0,
    totalLivingCost: 0,
    totalEducational_e: 0,
    totalPersonal_e: 0,
    totalTransport_e: 0,
    totalSavings: 0,
    percentLivingCost: 0,
    percentEducational_e: 0,
    percentPersonal_e: 0,
    percentTransport_e: 0,
    percentSavings: 0,
  });

  const onChangeHandler = (e, type) => {
    const rawValue = e.target.value;
    const value = rawValue.replace(/[^0-9.,]/g, "");
    const name = e.target.name;
    if (name === "householdIncome") {
      setCalculations({
        ...calculations,
        householdIncome: value,
      });
    }
    if (name === "otherSourceIncome") {
      setCalculations({
        ...calculations,
        otherSourceIncome: value,
      });
    }

    if (type === "fixCost") {
      setCalculations({
        ...calculations,
        livingCost: {
          ...calculations.livingCost,
          [name]: value,
        },
      });
    }
    if (type === "transport") {
      setCalculations({
        ...calculations,
        transportationExpense: {
          ...calculations.transportationExpense,
          [name]: value,
        },
      });
    }
    if (type === "education") {
      setCalculations({
        ...calculations,
        educationExpense: {
          ...calculations.educationExpense,
          [name]: value,
        },
      });
    }
    if (type === "personalCost") {
      setCalculations({
        ...calculations,
        personalExpense: {
          ...calculations.personalExpense,
          [name]: value,
        },
      });
    }
    if (type === "savings") {
      setCalculations({
        ...calculations,
        savings: {
          ...calculations.savings,
          [name]: value,
        },
      });
    }
  };
  const replaceValue = (value) => {
    let orgValue = value;
    const formattedValue = value.replace(".", "-").replace(",", ".");
    const fValue = formattedValue.replace("-", "");
    orgValue = fValue;

    return orgValue;
  };

  const resultCalculator = () => {
    let sum = 0;

    for (let i = 0; i < fieldSets.length; i++) {
      let s = sum + Number(replaceValue(fieldSets[i].value));
      sum = s;
    }
    const livingTotal =
      Number(replaceValue(calculations.livingCost.rent)) +
      Number(replaceValue(calculations.livingCost.realState)) +
      Number(replaceValue(calculations.livingCost.internet)) +
      Number(replaceValue(calculations.livingCost.creditCard)) +
      Number(replaceValue(calculations.livingCost.privatePayments)) +
      Number(replaceValue(calculations.livingCost.electricity)) +
      Number(replaceValue(calculations.livingCost.living)) +
      sum;
    let sumt = 0;

    for (let i = 0; i < inputTransValues.length; i++) {
      let s = sumt + Number;
      sumt = s;
    }

    const transportationExpenseTotal =
      Number(replaceValue(calculations.transportationExpense.cInsurance)) +
      Number(replaceValue(calculations.transportationExpense.cPayment)) +
      Number(replaceValue(calculations.transportationExpense.gas)) +
      Number(replaceValue(calculations.transportationExpense.publicT)) +
      sumt;
    let sume = 0;

    for (let i = 0; i < inputEduValues.length; i++) {
      let s = sume + Number(replaceValue(inputEduValues[i].value));
      sume = s;
    }

    const educationExpenseTotal =
      Number(replaceValue(calculations.educationExpense.study)) +
      Number(replaceValue(calculations.educationExpense.studentLoan)) +
      sume;
    let sump = 0;

    for (let i = 0; i < inputPersonValues.length; i++) {
      let s = sump + Number(replaceValue(inputPersonValues[i].value));
      sump = s;
    }
    let sumSub = 0;

    for (let i = 0; i < inputSubscriptions.length; i++) {
      let s = sumSub + Number(replaceValue(inputSubscriptions[i].value));
      sumSub = s;
    }

    const personalExpenseTotal =
      Number(replaceValue(calculations.personalExpense.livingCost)) +
      Number(replaceValue(calculations.personalExpense.insurance)) +
      Number(replaceValue(calculations.personalExpense.disabilityInsurance)) +
      Number(replaceValue(calculations.personalExpense.otherInsurance)) +
      Number(replaceValue(calculations.personalExpense.clothes)) +
      sumSub +
      Number(replaceValue(calculations.personalExpense.freeTime)) +
      Number(replaceValue(calculations.personalExpense.payment)) +
      Number(replaceValue(calculations.personalExpense.housePet)) +
      sump;
    let sums = 0;

    for (let i = 0; i < inputSavingValues.length; i++) {
      let s = sums + Number(replaceValue(inputSavingValues[i].value));
      sums = s;
    }

    const savingsTotal =
      Number(replaceValue(calculations.savings.emergencyFund)) +
      Number(replaceValue(calculations.savings.investing)) +
      Number(replaceValue(calculations.savings.privateFund)) +
      sums;

    const totalIncome =
      showOtherSource && showChildSupport && !showMiniJobs
        ? Number(replaceValue(calculations.householdIncome)) +
          Number(replaceValue(calculations.otherSourceIncome)) +
          Number(replaceValue(calculations.childSupportIncome))
        : showOtherSource && showMiniJobs && !showChildSupport
        ? Number(replaceValue(calculations.householdIncome)) +
          Number(replaceValue(calculations.otherSourceIncome)) +
          Number(replaceValue(calculations.miniJobIncome))
        : showOtherSource && showMiniJobs && showChildSupport
        ? Number(replaceValue(calculations.householdIncome)) +
          Number(replaceValue(calculations.otherSourceIncome)) +
          Number(replaceValue(calculations.miniJobIncome)) +
          Number(replaceValue(calculations.childSupportIncome))
        : Number(replaceValue(calculations.householdIncome)) +
          Number(replaceValue(calculations.otherSourceIncome));

    const newValue = Number(replaceValue(calculations.householdIncome));

    const totalLivingPercent =
      totalIncome <= 0 ? 0 : (livingTotal / totalIncome) * 100;
    if (totalLivingPercent > 50) {
      setFixCostTips(true);
      setFixCostTips1(false);
    } else {
      if (totalLivingPercent > 0) {
        setFixCostTips1(true);
      } else {
        setFixCostTips1(false);
      }
      setFixCostTips(false);
    }
    const totalEducational_ePercent =
      totalIncome <= 0 ? 0 : (educationExpenseTotal / totalIncome) * 100;
    const totalTransportation_ePercent =
      totalIncome <= 0 ? 0 : (transportationExpenseTotal / totalIncome) * 100;
    const totalPersonal_ePercent =
      totalIncome <= 0 ? 0 : (personalExpenseTotal / totalIncome) * 100;
    const totalTrans =
      totalTransportation_ePercent +
      totalPersonal_ePercent +
      totalEducational_ePercent;
    if (totalTrans > 0) {
      setTransCostTips(true);
      if (totalTrans > 30) {
        setTransCostTips(false);
      }
    } else {
      setTransCostTips(false);
    }
    if (totalEducational_ePercent > 10) {
      setEduExp(true);
    } else {
      setEduExp(false);
    }
    if (totalTransportation_ePercent > 10) {
      setTranExp(true);
    } else {
      setTranExp(false);
    }
    if (totalPersonal_ePercent > 10) {
      setPerExp(true);
    } else {
      setPerExp(false);
    }
    const totalSavingsPercent =
      totalIncome <= 0 ? 0 : (savingsTotal / totalIncome) * 100;
    if (totalSavingsPercent > 0 && totalSavingsPercent < 20) {
      const yearlyOptimizationRate = totalIncome * 0.2 * 12;
      setYearlyOptRate(yearlyOptimizationRate);
      setSavingTips(true);
      setSavingTips1(false);
    } else {
      if (totalSavingsPercent > 20) {
        setSavingTips1(true);
      }
      setSavingTips(false);
    }

    setResults({
      totalLivingCost: Number(livingTotal).toFixed(3),
      totalEducational_e: Number(educationExpenseTotal).toFixed(3),
      totalPersonal_e: Number(personalExpenseTotal).toFixed(3),
      totalSavings: Number(savingsTotal).toFixed(3),
      totalTransport_e: Number(transportationExpenseTotal).toFixed(3),
      percentLivingCost: Number(totalLivingPercent).toFixed(3),
      percentEducational_e: Number(totalEducational_ePercent).toFixed(3),
      percentPersonal_e: Number(totalPersonal_ePercent).toFixed(3),
      percentSavings: Number(totalSavingsPercent).toFixed(3),
      percentTransport_e: Number(totalTransportation_ePercent).toFixed(3),
      totalIncome: Number(totalIncome).toFixed(3),
      totalCost: Number(
        livingTotal +
          transportationExpenseTotal +
          educationExpenseTotal +
          personalExpenseTotal +
          savingsTotal
      ).toFixed(3),
      remainingBalance: Number(
        totalIncome -
          (livingTotal +
            transportationExpenseTotal +
            educationExpenseTotal +
            personalExpenseTotal +
            savingsTotal)
      ).toFixed(3),
    });
  };
  const handleClick = () => {
    setIsActive(!isActive);
  };

  const otherSourcePress = (type) => {
    if (type === "otherSource") {
      setShowOtherSource(!showOtherSource);
    }
    if (type === "childSupport") {
      setShowChildSupport(!showChildSupport);
    }
    if (type === "miniJobs") {
      setShowMiniJobs(!showMiniJobs);
    }
  };
  const miniIncome = (type) => {
    if (type === "plus") {
      const addIncome =
        calculations.miniJobIncome === ""
          ? Number(replaceValue(calculations.miniJobIncome))
          : Number(replaceValue(calculations.miniJobIncome)) + 520;
      setCalculations({
        ...calculations,
        miniJobIncome: `${addIncome}`,
      });
    }
    if (type === "minus") {
      const minusIncome =
        calculations.miniJobIncome > 0
          ? calculations.miniJobIncome === ""
            ? Number(replaceValue(calculations.miniJobIncome))
            : Number(replaceValue(calculations.miniJobIncome)) - 520
          : 0;
      setCalculations({
        ...calculations,
        miniJobIncome: `${minusIncome}`,
      });
    }
  };
  const childSupportIncome = (type) => {
    if (type === "plus") {
      const addIncome =
        (calculations.childSupportIncome === ""
          ? Number(replaceValue(calculations.childSupportIncome))
          : Number(replaceValue(calculations.childSupportIncome))) + 250;
      setCalculations({
        ...calculations,
        childSupportIncome: `${addIncome}`,
      });
    }
    if (type === "minus") {
      const minusIncome =
        calculations.childSupportIncome > 0
          ? calculations.childSupportIncome === ""
            ? Number(replaceValue(calculations.childSupportIncome))
            : Number(replaceValue(calculations.childSupportIncome)) - 250
          : 0;
      setCalculations({
        ...calculations,
        childSupportIncome: `${minusIncome}`,
      });
    }
  };
  const getTips = ({ type, slug }) => {
    if (type === "finanzrechner") {
      const data = { slug: `${slug}` };
      localStorage.setItem("myData", JSON.stringify(data));
      window.open(`/${type}/${slug}`,'_blank');
    } else {
      type === "tip" ? setShowModal(true) : setModalShow(true);
    }
  };
  useEffect(() => {
    resultCalculator();
  }, [
    calculations,
    showOtherSource,
    showMiniJobs,
    showChildSupport,
    inputTransValues,
    inputEduValues,
    inputPersonValues,
    inputSavingValues,
    inputSubscriptions,
    fieldSets,
  ]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Helmet>
        <title>Haushaltsrechner | Finanzfaible </title>
        <meta name="google-site-verification" content="d0Rzwmv6Ai-G46NMSo6XRqsA0uduyieR7XDLD_60HsU" />
        <meta
          name="description"
          content="Mit dem Haushaltsrechner von Finanzfailble können Sie Ihre Finanzen ganz einfach verwalten. Erreichen Sie finanzielle Stabilität für die Zukunft Ihrer Familie."
        />
        <meta name="keywords" content="Haushaltsrechner," />

        <link rel="canonical" href="https://finanzfaible.com/haushaltsrechner"/>
      </Helmet>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500 text-capitalize">
                {t("houseHold.title")}
              </h1>
              <p className="text-white mt-3">{t("houseHold.subTitle")}</p>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={Household}
                alt="img not found"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <h2 className="fs-32  text-green">{t("houseHold.howToUseHH")}</h2>
              <ul className="list-style mt-3">
                <li className="p-heading">{t("houseHold.howTo1")}</li>
                <li className="p-heading">{t("houseHold.howTo2")}</li>
                <li className="p-heading">{t("houseHold.howTo3")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <BudgetReminder />
      <section className="monthly-calculation pt-5 pb-2">
        <div className="container">
          <span className="text-green text-start text-md-center d-block fw-500">
            {t("houseHold.calculator")}
          </span>
          <h3 className="body-heading text-start text-md-center">
            {t("houseHold.cHeading")}
          </h3>

          <div className="row bg-green py-4 px-2 radius-24 mx-lg-5 mt-4">
            <div className="col-12 mb-4">
              <div className="py-4 bg-light-green radius-24">
                <div className="row  justify-content-center ">
                  <div className="col-11 col-md-8 col-lg-4 ">
                    <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                      {t("yourNetIncome")}
                    </h5>
                    <p className="mb-1">{t("householdIncome")}</p>

                    <h4 className="px-4 py-1 bg-white d-block income-box fw-bold">
                      <input
                        name="householdIncome"
                        value={calculations.householdIncome}
                        style={{ border: "none" }}
                        onChange={onChangeHandler}
                        className="text-center w-100"
                        placeholder="0"
                      />
                    </h4>

                    <p className="mb-1 mt-3">{t("OtherIncome")}</p>
                    <h4 className="px-4 py-1 bg-white d-block income-box fw-bold">
                      <input
                        name="otherSourceIncome"
                        value={calculations.otherSourceIncome}
                        style={{ border: "none" }}
                        onChange={onChangeHandler}
                        className="text-center w-100"
                        placeholder="0"
                      />
                    </h4>
                    {!showOtherSource && (
                      <div className="px-4 py-1 text-center fw-bold">
                        <Button
                          className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                          onClick={() => otherSourcePress("otherSource")}
                        >
                          {t("houseHold.otherSources")}
                        </Button>
                      </div>
                    )}
                    {showOtherSource && (
                      <>
                        <div className="close-icon-chart  d-flex justify-content-end">
                          <img
                            onClick={() => otherSourcePress("otherSource")}
                            src={Cross}
                            alt="icon"
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <Button
                            className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                            onClick={() => otherSourcePress("childSupport")}
                          >
                            {t("houseHold.childSupport")}
                          </Button>
                          <Button
                            className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                            onClick={() => otherSourcePress("miniJobs")}
                          >
                            {t("houseHold.miniJob")}
                          </Button>
                        </div>
                        {showChildSupport && (
                          <>
                            <p className="mb-1 mt-3">
                              {t("houseHold.childSupportTitle")}
                            </p>
                            <ChildSupport
                              childSupportIncome={childSupportIncome}
                              setCalculations={setCalculations}
                              calculations={calculations}
                            />
                          </>
                        )}
                        {showMiniJobs && (
                          <>
                            <p className="mb-1 mt-3">
                              {t("houseHold.miniJobTitle")}
                            </p>
                            <MiniJobs
                              miniIncome={miniIncome}
                              setCalculations={setCalculations}
                              calculations={calculations}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6  mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 h-100">
                <Accordion defaultActiveKey="0" className="pb-5">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon1}
                        alt="img not found"
                      />
                      {t("houseHold.fixCost")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.rent")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="rent"
                                onChange={(e) => onChangeHandler(e, "fixCost")}
                                value={calculations.livingCost.rent}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.creditCard")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="creditCard"
                                onChange={(e) => onChangeHandler(e, "fixCost")}
                                value={calculations.livingCost.creditCard}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.privatePayments")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="privatePayments"
                                onChange={(e) => onChangeHandler(e, "fixCost")}
                                value={calculations.livingCost.privatePayments}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.realState")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="realState"
                                onChange={(e) => onChangeHandler(e, "fixCost")}
                                value={calculations.livingCost.realState}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.internet")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="internet"
                                onChange={(e) => onChangeHandler(e, "fixCost")}
                                value={calculations.livingCost.internet}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.electricity")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="electricity"
                                value={calculations.livingCost.electricity}
                                onChange={(e) => onChangeHandler(e, "fixCost")}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.living")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="living"
                                value={calculations.livingCost.living}
                                onChange={(e) => onChangeHandler(e, "fixCost")}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div>
                          <InputFields
                            fieldSets={fieldSets}
                            setFieldSets={setFieldSets}
                            inputTitle={t("houseHold.addCosts")}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500 text-light-red fw-bold">
                            {t("total")}
                          </h6>
                          <div>
                            <h4 className="text-light-red fw-bold">
                              {results.totalLivingCost} €
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon2}
                        alt="img not found"
                      />
                      {t("houseHold.transportCost")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.cPayment")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="cPayment"
                                value={
                                  calculations.transportationExpense.cPayment
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, "transport")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.gas")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="gas"
                                value={calculations.transportationExpense.gas}
                                onChange={(e) =>
                                  onChangeHandler(e, "transport")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.cInsurance")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="cInsurance"
                                value={
                                  calculations.transportationExpense.cInsurance
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, "transport")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.publicT")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="publicT"
                                value={
                                  calculations.transportationExpense.publicT
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, "transport")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div>
                          <InputFields
                            fieldSets={inputTransValues}
                            setFieldSets={setInputTransValues}
                            inputTitle={t("houseHold.addCosts")}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500 text-light-red fw-bold">
                            {t("total")}
                          </h6>
                          <div>
                            <h4 className="text-light-red fw-bold">
                              {results.totalTransport_e} €
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon3}
                        alt="img not found"
                      />
                      {t("houseHold.education")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.study")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="study"
                                value={calculations.educationExpense.study}
                                onChange={(e) =>
                                  onChangeHandler(e, "education")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.studentLoan")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="studentLoan"
                                value={
                                  calculations.educationExpense.studentLoan
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, "education")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <InputFields
                          fieldSets={inputEduValues}
                          setFieldSets={setInputEduValues}
                          inputTitle={t("houseHold.addCosts")}
                        />
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500 text-light-red fw-bold">
                            {t("total")}
                          </h6>
                          <div>
                            <h4 className="text-light-red fw-bold">
                              {results.totalEducational_e} €
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon4}
                        alt="img not found"
                      />
                      {t("houseHold.personalCost")}
                    </Accordion.Header>

                    <Accordion.Body>
                      <div>
                        {/* <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.livingCost")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="livingCost"
                                value={calculations.personalExpense.livingCost}
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div> */}
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.disabilityInsurance")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="disabilityInsurance"
                                value={
                                  calculations.personalExpense
                                    .disabilityInsurance
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.insurance")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="insurance"
                                value={calculations.personalExpense.insurance}
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.otherInsurance")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="otherInsurance"
                                value={
                                  calculations.personalExpense.otherInsurance
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.clothes")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="clothes"
                                value={calculations.personalExpense.clothes}
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.subscription")}
                          </h6>
                          {/* <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="subscription"
                                value={
                                  calculations.personalExpense.subscription
                                }
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div> */}
                        </div>
                        <div>
                          <InputFields
                            fieldSets={inputSubscriptions}
                            setFieldSets={setInputSubscriptions}
                            inputTitle={t("houseHold.addCosts")}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.freeTime")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="freeTime"
                                value={calculations.personalExpense.freeTime}
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.payment")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="payment"
                                value={calculations.personalExpense.payment}
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.housePet")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="housePet"
                                value={calculations.personalExpense.housePet}
                                onChange={(e) =>
                                  onChangeHandler(e, "personalCost")
                                }
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div>
                          <InputFields
                            fieldSets={inputPersonValues}
                            setFieldSets={setInputPersonValues}
                            inputTitle={t("houseHold.addCosts")}
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500 text-light-red fw-bold">
                            {t("total")}
                          </h6>
                          <div>
                            <h4 className="text-light-red fw-bold">
                              {results.totalPersonal_e} €
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon5}
                        alt="img not found"
                      />
                      {t("houseHold.savings")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.emergencyFund")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="emergencyFund"
                                value={calculations.savings.emergencyFund}
                                onChange={(e) => onChangeHandler(e, "savings")}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.investing")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="investing"
                                value={calculations.savings.investing}
                                onChange={(e) => onChangeHandler(e, "savings")}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500">
                            {t("houseHold.privateFund")}
                          </h6>
                          <div>
                            <h4 className="px-4 py-1 bg-white d-block incom-box fw-bold">
                              <input
                                name="privateFund"
                                value={calculations.savings.privateFund}
                                onChange={(e) => onChangeHandler(e, "savings")}
                                style={{ border: "none" }}
                                className="text-center"
                                placeholder="0"
                              />
                            </h4>
                          </div>
                        </div>
                        <div>
                          <InputFields
                            fieldSets={inputSavingValues}
                            setFieldSets={setInputSavingValues}
                            inputTitle={t("houseHold.addCosts")}
                          />
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h6 className="text-start fw-500 text-light-red fw-bold">
                            {t("total")}
                          </h6>
                          <div>
                            <h4 className="text-light-red fw-bold">
                              {results.totalSavings} €
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 py-4 position-relative">
                <div className="position-absolute end-0 top-0 me-2 mt-2 ">
                  <img className="cursor-pointer" src={PdfIcon} alt="icon" />
                </div>
                <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                  {t("summary")}
                </h5>
                <div className="row justify-content-between align-items-center pt-3 pb-4">
                  <div className="col-6 border-right d-flex justify-content-center text-center">
                    <div>
                      <p className="text-green fs-5 mb-0">
                        {t("totalIncome")} <img src={IncomArrow} alt="arrow" />
                      </p>
                      <h2 className="fw-bold text-green mb-0">
                        {results.totalIncome} €
                      </h2>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-center text-center">
                    <div>
                      <p className="text-light-red fs-5 mb-0">
                        {t("totalCost")} <img src={ExpenceArrow} alt="arrow" />
                      </p>
                      <h2 className="fw-bold text-light-red mb-0">
                        {results.totalCost} €
                      </h2>
                    </div>
                  </div>
                  <div className="rounded-pills w-75 w-md-100 col-11 col-md-9 d-flex justify-content-center bg-white radius-24 mx-auto pt-3 pb-2 mt-5">
                    <div>
                      <h6 className="text-green text-center  fw-normal mb-0">
                        {t("remainingBalance")}
                      </h6>
                      <h2 className="fs-1 fw-bold text-black text-center mb-0">
                        {results.remainingBalance} €
                      </h2>
                    </div>
                  </div>
                </div>
                <h5 className="text-green text-center fw-bold  mb-1">
                  {t("monthlyHousehold.budgetBreakdown")}
                </h5>
                <p className="text-green text-center  fw-normal fs-6">
                  {t("monthlyHousehold.incomeCategories")}
                </p>
                <div className="progress-bar-box">
                  <div className="progress-bar px-2 px-lg-4 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="p-heading  text-black mb-1">
                        {t("houseHold.fixCost")}
                      </p>
                      <div>
                        <p className="p-heading  text-black mb-1">
                          {results.percentLivingCost
                            ? results.percentLivingCost
                            : 0}
                          %
                        </p>
                      </div>
                    </div>
                    <ProgressBar
                      now={
                        results.percentLivingCost
                          ? results.percentLivingCost
                          : 0
                      }
                    />
                  </div>
                  <div className="progress-bar px-2 px-lg-4 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="p-heading  text-black mb-1">
                        {t("houseHold.transportCost")}
                      </p>
                      <div>
                        <p className="p-heading  text-black mb-1">
                          {results.percentTransport_e
                            ? results.percentTransport_e
                            : 0}
                          %
                        </p>
                      </div>
                    </div>
                    <ProgressBar
                      now={
                        results.percentTransport_e
                          ? results.percentTransport_e
                          : 0
                      }
                    />
                  </div>
                  <div className="progress-bar px-2 px-lg-4 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="p-heading text-black mb-1">
                        {t("houseHold.education")}
                      </p>
                      <div>
                        <p className="p-heading  text-black mb-1">
                          {results.percentEducational_e
                            ? results.percentEducational_e
                            : 0}
                          %
                        </p>
                      </div>
                    </div>
                    <ProgressBar
                      now={
                        results.percentEducational_e
                          ? results.percentEducational_e
                          : 0
                      }
                    />
                  </div>
                  <div className="progress-bar px-2 px-lg-4 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="p-heading text-black mb-1">
                        {t("houseHold.personalCost")}
                      </p>
                      <div>
                        <p className="p-heading text-black mb-1">
                          {results.percentPersonal_e
                            ? results.percentPersonal_e
                            : 0}
                          %
                        </p>
                      </div>
                    </div>
                    <ProgressBar
                      now={
                        results.percentPersonal_e
                          ? results.percentPersonal_e
                          : 0
                      }
                    />
                  </div>
                  <div className="progress-bar px-2 px-lg-4 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="p-heading text-black mb-1">
                        {t("houseHold.savings")}
                      </p>
                      <div>
                        <p className="p-heading text-black mb-1">
                          {results.percentSavings ? results.percentSavings : 0}%
                        </p>
                      </div>
                    </div>
                    <ProgressBar
                      now={results.percentSavings ? results.percentSavings : 0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="money-saving bg-light-green my-5 py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-6">
              <span className="text-green text-start  text-md-center d-block fw-500">
                {t("savings")}
              </span>

              <h3 className=" text-start text-md-center">
                {t("houseHold.savingTipHeading")}
              </h3>
              <Accordion defaultActiveKey="0" className="pb-5 ">
                {fixCostTips1 && (
                  <>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <img
                          width={"30px"}
                          className="me-3"
                          src={TabIcon1}
                          alt="img not found"
                        />
                        {t("houseHold.fixCost")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <ul>
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.fixCostL50")}
                                <span
                                  className="text-green fs-5 cursor-pointer"
                                  onClick={() =>
                                    getTips({
                                      type: "finanzrechner",
                                      slug: "konto-und-karten",
                                    })
                                  }
                                >
                                  {t("houseHold.fixCostL501")}
                                </span>
                                {t("houseHold.fixCostL502")}
                              </li>
                            </>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
                {fixCostTips && (
                  <>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <img
                          width={"30px"}
                          className="me-3"
                          src={TabIcon1}
                          alt="img not found"
                        />
                        {t("houseHold.fixCost")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <strong className=" mb-3 d-block ps-3 ">
                            {t("houseHold.tip1")}
                          </strong>
                          <ul>
                            {calculations.livingCost.rent !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.rentTipH")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.rentTip")}
                                </p>
                              </>
                            )}
                            {calculations.livingCost.realState !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.realEstateTipH")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.realEstateTip")}
                                </p>
                              </>
                            )}
                            {calculations.livingCost.creditCard !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.creditCardTipH")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.creditCardTip")}
                                  <span
                                    className="text-green fs-5 cursor-pointer"
                                    onClick={() =>
                                      getTips({
                                        type: "finanzrechner",
                                        slug: "konto-und-karten",
                                      })
                                    }
                                  >
                                    {t("houseHold.creditCardTip1")}
                                  </span>
                                  {t("houseHold.creditCardTip2")}
                                </p>
                              </>
                            )}
                            {calculations.livingCost.privatePayments !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.privateLoansTipH")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.privateLoansTip")}
                                  <span
                                    className="text-danger fs-5 cursor-pointer"
                                    onClick={() =>
                                      getTips({
                                        type: "finanzrechner",
                                        slug: "kredit-&-finanzierung",
                                      })
                                    }
                                  >
                                    {t("houseHold.privateLoansTip1")}
                                  </span>
                                  {t("houseHold.privateLoansTip2")}
                                </p>
                              </>
                            )}
                            {calculations.livingCost.electricity !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.electricityTipH")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.electricityTip")}
                                  <span
                                    className="text-green fs-5 cursor-pointer"
                                    onClick={() => getTips("tip")}
                                  >
                                    {t("houseHold.electricityTip1")}
                                  </span>
                                  {t("houseHold.electricityTip2")}
                                </p>
                              </>
                            )}
                            {calculations.livingCost.living !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.foodTipH")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.foodTip")}
                                </p>
                              </>
                            )}
                            <li>{t("houseHold.optimizeTip")}</li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
                {transCostTips && (
                  <>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <img
                          width={"30px"}
                          className="me-3"
                          src={TabIcon2}
                          alt="img not found"
                        />
                        {t("houseHold.personalCost")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <ul>
                            <li className="p-heading fw-bold">
                              {t("houseHold.transportationExpenseL50")}
                              <span
                                  className="text-green fs-5 cursor-pointer"
                                  onClick={() =>
                                    getTips({
                                      type: "finanzrechner",
                                      slug: "blogs",
                                    })
                                  }
                                >
                              {t("houseHold.transportationExpenseL501")}
                              </span>
                              {t("houseHold.transportationExpenseL502")}
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
                {tranExp && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon2}
                        alt="img not found"
                      />
                      {t("houseHold.transportCost")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <strong className=" mb-3 d-block ps-3 ">
                          {t("houseHold.tip2")}
                        </strong>

                        <ul>
                          {calculations.transportationExpense.cPayment !==
                            "" && (
                            <div>
                              <li className="p-heading fw-bold">
                                {t("houseHold.carPaymentTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.carPaymentTip")}
                                <span
                                  className="text-green fs-5 cursor-pointer"
                                  onClick={() => getTips("tip")}
                                >
                                  {t("houseHold.carPaymentTip1")}
                                </span>
                                {t("houseHold.carPaymentTip2")}
                              </p>
                            </div>
                          )}
                          {calculations.transportationExpense.gas !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.gasFueTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.gasFueTip")}
                              </p>
                            </>
                          )}
                          {calculations.transportationExpense.cInsurance !==
                            "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.carInsuranceTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.carInsuranceTip")}
                                <span
                                  className="text-green fs-5 cursor-pointer"
                                  onClick={() => getTips("tip")}
                                >
                                  {t("houseHold.carInsuranceTip1")}
                                </span>
                                {t("houseHold.carInsuranceTip2")}
                              </p>
                            </>
                          )}
                          {calculations.transportationExpense.publicT !==
                            "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.publicTransportTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.publicTransportTip")}
                              </p>
                            </>
                          )}
                          <li>{t("houseHold.optimizeTransTip")}</li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {eduExp && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon3}
                        alt="img not found"
                      />
                      {t("houseHold.education")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <strong className=" mb-3 d-block ps-3 ">
                          {t("houseHold.tip2")}
                        </strong>

                        <ul>
                          {calculations.educationExpense.study !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.studyCostsTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.studyCostsTip")}
                              </p>
                            </>
                          )}
                          {calculations.educationExpense.studentLoan !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.studentLoansTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.studentLoansTip")}
                                <span
                                  className="text-green fs-5 cursor-pointer"
                                  onClick={() => getTips("tip")}
                                >
                                  {t("houseHold.studentLoansTip1")}
                                </span>
                                {t("houseHold.studentLoansTip2")}
                              </p>
                            </>
                          )}
                          <li>{t("houseHold.optimizeTransTip")}</li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {perExp && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <img
                        width={"30px"}
                        className="me-3"
                        src={TabIcon4}
                        alt="img not found"
                      />
                      {t("houseHold.personalCost")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <strong className=" mb-3 d-block ps-3 ">
                          {t("houseHold.tip2")}
                        </strong>

                        <ul>
                          {calculations.personalExpense.disabilityInsurance !==
                            "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.disabilityInsuranceTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.disabilityInsuranceTip")}
                                <span
                                  className="text-green fs-5 cursor-pointer"
                                  onClick={() => getTips("tip")}
                                >
                                  {t("houseHold.disabilityInsuranceTip1")}
                                </span>
                                {t("houseHold.disabilityInsuranceTip2")}
                              </p>
                            </>
                          )}
                          {calculations.personalExpense.otherInsurance !==
                            "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.otherInsurancesTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.otherInsurancesTip")}
                              </p>
                            </>
                          )}
                          {calculations.personalExpense.clothes !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.ClothesTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.ClothesTip")}
                              </p>
                            </>
                          )}
                          {calculations.personalExpense.subscription !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.SubscriptionsTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.SubscriptionsTip")}
                              </p>
                            </>
                          )}
                          {calculations.personalExpense.freeTime !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.freeTimeTipH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.freeTimeTip")}
                              </p>
                            </>
                          )}
                          {calculations.personalExpense.payment !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.fitnessPaymentH")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.fitnessPayment")}
                              </p>
                            </>
                          )}
                          {calculations.personalExpense.housePet !== "" && (
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.housePet")}
                              </li>
                              <p className="p-heading">
                                {t("houseHold.housePetsTip")}
                              </p>
                            </>
                          )}
                          <li>{t("houseHold.optimizeTransTip")}</li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {savingTips1 && (
                  <>
                    {/* <Accordion defaultActiveKey="4" className="pb-5"> */}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <img
                          width={"30px"}
                          className="me-3"
                          src={TabIcon5}
                          alt="img not found"
                        />
                        {t("houseHold.savings")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <ul>
                            <>
                              <li className="p-heading fw-bold">
                                {t("houseHold.savingsL50")}
                                <span
                                  className="text-green fs-5 cursor-pointer"
                                  onClick={() =>
                                    getTips({
                                      type: "finanzrechner",
                                      slug: "sparen-&-investieren",
                                    })
                                  }
                                >
                                {t("houseHold.savingsL501")}
                                </span>
                                {t("houseHold.savingsL502")}
                              </li>
                            </>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* </Accordion> */}
                  </>
                )}
                {savingTips && (
                  <>
                    {/* <Accordion defaultActiveKey="4" className="pb-5"> */}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <img
                          width={"30px"}
                          className="me-3"
                          src={TabIcon5}
                          alt="img not found"
                        />
                        {t("houseHold.savings")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <strong className=" mb-3 d-block ps-3 ">
                            {t("houseHold.tip3")}
                          </strong>
                          <ul>
                            {calculations.savings.emergencyFund !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.emergencyFundTipHeading")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.emergencyFundTip")}
                                  <span
                                    className="text-green fs-5 cursor-pointer"
                                    onClick={() => getTips("tip")}
                                  >
                                    {t("houseHold.emergencyFundTip1")}
                                  </span>
                                  {t("houseHold.emergencyFundTip2")}
                                </p>
                              </>
                            )}
                            {calculations.savings.investing !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.investingTipHeading")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.investingTip")}
                                  <span
                                    className="text-green fs-5 cursor-pointer"
                                    onClick={() => getTips("tip")}
                                  >
                                    {t("houseHold.investingTip1")}
                                  </span>
                                  {t("houseHold.investingTip2")}
                                </p>
                              </>
                            )}
                            {calculations.savings.privateFund !== "" && (
                              <>
                                <li className="p-heading fw-bold">
                                  {t("houseHold.pensionFundTipHeading")}
                                </li>
                                <p className="p-heading">
                                  {t("houseHold.pensionFundTip")}
                                  <span
                                    className="text-green fs-5 cursor-pointer"
                                    onClick={() => getTips("tip")}
                                  >
                                    {t("houseHold.pensionFundTip1")}
                                  </span>
                                  {t("houseHold.pensionFundTip2")}
                                </p>
                              </>
                            )}
                            <li>
                              {t("houseHold.optimizeSavingTip")} {yearlyOptRate}{" "}
                              {t("houseHold.optimizeSavingTip1")}
                            </li>
                          </ul>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* </Accordion> */}
                  </>
                )}
              </Accordion>

              <h3 className=" text-start text-md-center mt-10">
                {t("houseHold.weOffer")}
              </h3>
              <ul className="list-style mt-4">
                <li className="p-heading">
                  {t("houseHold.option1")}{" "}
                  <span
                    className="text-green fs-5 cursor-pointer"
                    onClick={() => getTips()}
                  >
                    {t("houseHold.option11")}
                  </span>
                </li>
                <li className="p-heading">
                  {t("houseHold.option2")}
                  <span
                    className="text-green fs-5 cursor-pointer"
                    onClick={() => getTips()}
                  >
                    {t("houseHold.option21")}
                  </span>
                  {t("houseHold.option22")}
                  <span
                    className="text-green fs-5 cursor-pointer"
                    onClick={() => getTips()}
                  >
                    {t("houseHold.option23")}
                  </span>
                </li>
              </ul>
              <Button
                className="mt-4 bg-green px-3 py-2 text-white rounded-pill border-0 fw-bold"
                onClick={() => getTips()}
              >
                {t("houseHold.getTips")}
              </Button>
            </div>
            <AdviceModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              calculations={calculations}
              results={results}
              fieldSets={fieldSets}
              inputEduValues={inputEduValues}
              inputPersonValues={inputPersonValues}
              inputSavingValues={inputSavingValues}
              inputSubscriptions={inputSubscriptions}
              inputTransValues={inputTransValues}
            />
          </div>
        </div>
      </section>

      <BudgetReminder />
      <Blogs />
      {/* <FinancialGoal /> */}
      <StayInformed />

      <GetAdd show={show} setShow={setShow} type={type} />
      <CalculationsPopup count={count} setCount={setCount} />
      <DisableCalculatorPop
        setType={setType}
        showModal={showModal}
        setShowModal={setShowModal}
        setShow={setShow}
        houseTip={true}
      />
      {showCookies && <Cookies showCookies={setShowCookies} />}
      <Toaster />
    </>
  );
};

export default HouseholdCalculator;
