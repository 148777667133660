import React, { useEffect, useState } from "react";
import BannerImage from "../assets/images/interest-calculator-banner.svg";
import Household from "../assets/images/household-img.svg";
import Calendar from "../assets/images/calendar-img.svg";
import PdfIcon from "../assets/images/pdf-icon.svg";

import { Button, Form } from "react-bootstrap";
import Blogs from "../components/Blogs";
import FinancialGoal from "../components/FinancialGoal";
import Cookies from "../components/Cookies";
import StayInformed from "../components/StayInformed";
import Chart from "../components/Chart";
import { useTranslation } from "react-i18next";
import BudgetReminder from "../components/BudgetReminder";
import FinancialChart from "../components/FinancialChart";

const FinancialFreedomCalculator = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showCookies, setShowCookies] = useState(true);
  const [chartShow, setChartShow] = useState(false);
  const [calculations, setCalculations] = useState({
    monthlyNetAmount: "",
    expectedReturn: "",
    accountInflation: false,
    interestRate: "26.375",
    currentAge: "",
    retirementAge: "",
    capitalConsumption: false,
    yearsToFinancialFreedom: "",
    existingCapital: "",
  });
  const [results, setResults] = useState({
    additionalCapital: 0,
    monthlySaving: 0,
    finalCapital: 0,
    duration: 0,
  });

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setCalculations({
      ...calculations,
      [name]: value,
    });
  };

  const calculateMaturityAmount = () => {
    const expectedReturnRate = Number(calculations.expectedReturn)/100
    const retirementYears = Number(calculations.retirementAge) - Number(calculations.currentAge);
    const monthlyReturnRate = Math.pow(1 + expectedReturnRate, 1 / 12) - 1;
    const yearsToFinancialFreedom =20;

    const requiredCapital =
      (Number(calculations.monthlyNetAmount) * 12) * (1 - Math.pow(1 + monthlyReturnRate, -yearsToFinancialFreedom)) / monthlyReturnRate;

    const futureValueExistingCapital = Number(calculations.existingCapital) * Math.pow(1 + expectedReturnRate, retirementYears);

    const gap = requiredCapital - futureValueExistingCapital;

    const monthlyContributions = gap / ((1 - Math.pow(1 + monthlyReturnRate, -retirementYears)) / monthlyReturnRate);

    const withholdingTaxRate = Number(calculations.interestRate)/100
    const monthlyContributionAfterTax = monthlyContributions / (1 - withholdingTaxRate);

    setResults({
      additionalCapital: requiredCapital.toFixed(3),
      monthlySaving: monthlyContributionAfterTax.toFixed(3),
      finalCapital: gap.toFixed(3),
      duration: retirementYears,
    });
  }
  const onPressCalculate = () => {
    calculateMaturityAmount();
    setChartShow(true);
  };

  return (
    <>
      <section className="banner-sec">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7 col-lg-6 mb-5 mb-md-0">
              <h1 className="text-white fw-500 text-capitalize">
                {t("financial.title")}
              </h1>
              <p className="text-white mt-3">{t("financial.subTitle")}</p>
            </div>
            <div className="col-md-5">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={BannerImage}
                alt="bannerImage"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="household-sec">
        <div className="container">
          <div className="row align-items-center py-5 justify-content-between flex-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-6">
              <img
                className="d-block mx-auto w-100 mx-md"
                src={Household}
                alt="img not found"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <h2 className="fs-32  text-green">
                {t("financial.howToUseHH")}
              </h2>
              <ul className="list-style mt-3">
                <li className="p-heading">{t("financial.howTo1")}</li>
                <li className="p-heading">{t("financial.howTo2")}</li>
                <li className="p-heading">{t("financial.howTo3")}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <BudgetReminder />

      <section className="monthly-calculation pt-5 pb-2">
        <div className="container">
          <span className="text-green text-start text-md-center d-block fw-500">
            {t("calculator")}
          </span>
          <h3 className="body-heading text-start text-md-center">
            {t("financial.title")}
          </h3>

          <div className="row bg-green py-4 px-2 radius-24 mx-lg-5 mt-4">
            <div className="col-md-6  mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 h-100 px-4 py-4 ">
                {/* <h5 className="text-green text-left fw-bold text-capitalize mb-3">
                  Summary
                </h5> */}

                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("financial.monthlyNetAmount")}</Form.Label>
                    <Form.Control
                      className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                      type="number"
                      placeholder="€5,000.00"
                      name="monthlyNetAmount"
                      value={calculations.monthlyNetAmount}
                      onChange={onChangeHandler}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("financial.expectedReturn")}</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="10"
                        name="expectedReturn"
                        value={calculations.expectedReturn}
                        onChange={onChangeHandler}
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>
                  <div className="d-flex justify-content-between align-items-center ">
                    <Form.Label>{t("financial.accountInflation")}</Form.Label>
                    <div className=" calculation-radio-btn ">
                      <Form.Check
                        inline
                        // label="Yes, interest accumulation"
                        name="accountInflation"
                        type="checkbox"
                        id="1"
                        value={calculations.accountInflation}
                        onClick={onChangeHandler}
                      />
                    </div>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("financial.interestRate")}</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="10"
                        name="interestRate"
                        value={calculations.interestRate}
                        onChange={onChangeHandler}
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        %Pa
                      </span>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("financial.currentAge")}</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="10"
                        name="currentAge"
                        value={calculations.currentAge}
                        onChange={onChangeHandler}
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        {t("years")}
                      </span>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("financial.retirementAge")}</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="10"
                        name="retirementAge"
                        value={calculations.retirementAge}
                        onChange={onChangeHandler}
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        {t("years")}
                      </span>
                    </div>
                  </Form.Group>
                  <div className="d-flex justify-content-between align-items-center ">
                    <Form.Label>{t("financial.capitalConsumption")}</Form.Label>
                    <div className=" calculation-radio-btn ">
                      <Form.Check
                        inline
                        // label="Yes, interest accumulation"
                        name="capitalConsumption"
                        type="checkbox"
                        id="1"
                        value={calculations.capitalConsumption}
                        onClick={onChangeHandler}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <Form.Label>
                      {" "}
                      {t("financial.yearsToFinancialFreedom")}
                    </Form.Label>
                    <span className="">
                      {calculations.yearsToFinancialFreedom} {t("Years")}
                    </span>
                  </div>

                  <Form.Group className="mb-3">
                    <Form.Label>{t("financial.existingCapital")}</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        className="incom-box bg-white px-2 py-2 w-100 fw-medium border-0"
                        type="number"
                        placeholder="10"
                        name="existingCapital"
                        value={calculations.existingCapital}
                        onChange={onChangeHandler}
                      />
                      <span className="eye-icon interest-rate text-green px-2 me-4">
                        €
                      </span>
                    </div>
                  </Form.Group>

                  <Button
                    className="py-lg bg-green text-white border-sm fw-bold border-0 px-5"
                    onClick={() => onPressCalculate()}
                  >
                    {t("financial.calculate")}
                  </Button>
                </Form>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-md-0 ">
              <div className="bg-light-green radius-24 py-3 position-relative">
                <div className="position-absolute end-0 top-0 me-2 mt-2 ">
                  <img className="cursor-pointer" src={PdfIcon} alt="icon" />
                </div>
                <h5 className="text-green text-center fw-bold text-capitalize mb-3">
                  {t("summary")}
                </h5>
                <div className="row justify-content-between align-items-center pt-3 pb-4">
                  <div className="col-11 col-md-9 rounded-pills w-75 w-md-100 d-flex justify-content-center bg-white radius-24 mx-auto pt-3 pb-2 mb-3">
                    <div>
                      <h6 className="text-green text-center text-capitalize fw-normal mb-0">
                        {t("financial.additionalRequired")}
                      </h6>
                      <h2 className="fs-1 fw-bold text-black text-center mb-0">
                        {results.additionalCapital} €
                      </h2>
                    </div>
                  </div>
                  <div className="col-6 border-right d-flex justify-content-center text-center">
                    <div>
                      <p className="text-green fs-5 mb-0">
                        {t("financial.monthlySavingRate")}
                      </p>
                      <h2 className="fw-bold text-green mb-0">
                        {results.monthlySaving} €
                      </h2>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-center text-center">
                    <div>
                      <p className="text-green fs-5 mb-0">
                        {t("financial.totalTaxDeductions")}
                      </p>
                      <h2 className="fw-bold text-green mb-0">
                        {results.duration} €
                      </h2>
                    </div>
                  </div>
                </div>

                {chartShow && (
                  <>
                    <div>
                      <p className="fw-bold text-green mb-0 px-3 pb-2">
                        {t("financial.p1")} €{results.monthlySaving},
                        {t("financial.p2")} €{results.finalCapital}
                        {t("financial.p3")} {results.duration}{" "}
                        {t("financial.p4")}
                        {calculations.expectedReturn}%,{t("financial.p5")} €
                        {calculations.monthlyNetAmount} .
                      </p>
                    </div>
                    <FinancialChart
                      onClick={() => setChartShow(false)}
                      results={results}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="money-saving bg-light-green my-5 py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 col-lg-6">
              <span className="text-green text-start  text-md-center d-block fw-500">
                {t("saving")}
              </span>
              <h3 className="body-heading text-start text-md-center">
                {t("simpleWay")}
              </h3>
              <ul className="list-style mt-4">
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue,
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar .
                </li>
                <li className="p-heading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus pulvinar felis sapien, vel aliquam quam consectetur a.
                  Donec porttitor nulla augue, eu mollis sapien condimentum sit
                  amet.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <Blogs />
      {/* <FinancialGoal /> */}
      <StayInformed />
      {showCookies && <Cookies showCookies={setShowCookies} />}
    </>
  );
};

export default FinancialFreedomCalculator;
